import { StateStoreTypeEnum } from './../../shared/state-store/state-store-type-enum';
import { Injectable } from '@angular/core';
import { Observable, throwError as observableThrowError, of } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { GlobalService } from '../global.service';
import { catchError, tap } from 'rxjs/operators';
import { HttpService } from '../http.service';
import { StateStore } from '../../dtos/state-store';

@Injectable({
  providedIn: 'root'
})
export class StateStoreService {

  constructor(
    private _http: HttpClient,
    private httpService: HttpService,
    private globalService: GlobalService) { }

  getAllStateStoresForMe(stateStoreTypeId: number): Observable<StateStore[]> {
    if (stateStoreTypeId !== StateStoreTypeEnum.PackageProgressGrid) {
      return of([]);
    }
    const url = this.globalService.getApiUrl() + '/state-stores/package-progress';
    return this._http.get<StateStore[]>(url, this.httpService.getHttpOptions()).pipe(
      catchError(this.handleError));
  }

  addStateStore(dataRecord: any): Observable<StateStore> {
    const url = this.globalService.getApiUrl() + '/state-stores/';
    return this._http.post<StateStore>(url, JSON.stringify(dataRecord), this.httpService.getHttpOptions());
  }

  updateStateStore(id: string, itm: any): Observable<StateStore> {
    const url = this.globalService.getApiUrl() + '/state-stores/' + id;
    return this._http.patch<StateStore>(url, JSON.stringify(itm), this.httpService.getHttpOptions());
  }


  updateLandStateStore(itm: any, type: string): Observable<StateStore> {
    const url = this.globalService.getApiUrl() + '/state-stores/' + type;
    return this._http.patch<StateStore>(url, JSON.stringify(itm), this.httpService.getHttpOptions());
  }

  deleteStateStore(id: string) {
    const url = this.globalService.getApiUrl() + '/state-stores/' + id;
    return this._http.delete(url, this.httpService.getHttpOptions());
  }



  getStateStoresForLand(): Observable<StateStore> {
    const url = this.globalService.getApiUrl() + '/state-stores/land-database';

    return this._http.get<StateStore>(url, this.httpService.getHttpOptions()).pipe(
      catchError(this.handleError));
  }

  private handleError(err: HttpErrorResponse) {
    console.log(JSON.stringify(err));
    return observableThrowError(err);
  }
}
