import { OnHoldLog } from './../../dtos/on-hold-log';
import { Injectable } from '@angular/core';
import { GlobalService } from '../global.service';
import { HttpService } from '../http.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { catchError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OnHoldLogService {

  onHoldLogUrl = environment.apiUrl + '/on-hold-logs';

  constructor(
    private http: HttpClient,
    private httpService: HttpService,
    private globalService: GlobalService) { }

  getOnHoldLogs() {
    return this.http.get<OnHoldLog[]>(this.onHoldLogUrl, this.httpService.getHttpOptions()).pipe(
      catchError(this.globalService.handleError));
  }

}
