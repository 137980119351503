<div class="modal-header">
  <h3 class="modal-title">{{titleDescription}} - logins</h3>
  <button type="button" class="close" aria-label="Close" (click)="cancel()" tabindex="-1">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <dx-data-grid [height]="gridHeight" [dataSource]="dataSource" [allowColumnReordering]="false"
    [allowColumnResizing]="true" [repaintChangesOnly]="true" [showBorders]="true" [rowAlternationEnabled]="true"
    [wordWrapEnabled]="true" [hoverStateEnabled]="true" (onExporting)="gridService.onExporting($event, 'Users')"
    (onInitNewRow)='onInitNewRow($event)' (onEditorPreparing)="onEditorPreparing($event)">

    <dxo-search-panel [visible]="true" location="after" [width]="200"></dxo-search-panel>
    <dxo-export [enabled]="true"></dxo-export>
    <dxo-scrolling mode="standard"></dxo-scrolling>
    <dxo-paging [enabled]="false"></dxo-paging>

    <dxo-editing mode="row" [allowUpdating]="true" [allowAdding]="true" [allowDeleting]="true" [useIcons]="true"
      startEditAction="click" refreshMode="full">
    </dxo-editing>

    <dxi-column dataField="id" dataType="number" [visible]='false'></dxi-column>

    <dxi-column dataField="userId" dataType="number" [visible]='false'></dxi-column>

    <dxi-column dataField="userTypeId" [width]="150" sortOrder="asc" [setCellValue]="setUserTypeCellValue">
      <dxo-lookup [dataSource]="userTypes" valueExpr="id" displayExpr="name">
      </dxo-lookup>
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>

    <dxi-column dataField="email" [minWidth]="250" sortOrder="asc" [setCellValue]="setEmailCellValue"
      editCellTemplate="editEmailTemplate">
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>

    <dxi-column dataField="firstName"></dxi-column>

    <dxi-column dataField="lastName">
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>

    <dxi-column caption="Permissions">
      <dxi-column dataField="mapViewPermission" caption="Map-View" dataType="boolean" [width]="80"></dxi-column>

      <dxi-column dataField="packagesPermission" caption="Packages" dataType="boolean" [width]="80"></dxi-column>

      <dxi-column dataField="progressPermission" caption="Progress" dataType="boolean" [width]="80"></dxi-column>

      <dxi-column dataField="documentsPermission" caption="Documents" dataType="boolean" [width]="90"></dxi-column>
    </dxi-column>

    <dxi-column dataField="isActive" caption="Active" dataType="boolean" [width]="65"></dxi-column>


    <div *dxTemplate="let cellInfo of 'editEmailTemplate'">
      <dx-drop-down-box *ngIf="cellInfo.data.userTypeId === userTypeEnum.Office" [dropDownOptions]="dropDownOptions"
        [dataSource]="officeUsers" [(value)]="cellInfo.value" displayExpr="email" valueExpr="email"
        contentTemplate="contentTemplate">
        <div *dxTemplate="let e of 'contentTemplate'">
          <dx-data-grid [dataSource]="officeUsers" keyExpr="email" [remoteOperations]="false" height="358px"
            [showBorders]="true" [wordWrapEnabled]="true" [selectedRowKeys]="[cellInfo.value]"
            [hoverStateEnabled]="true" [autoNavigateToFocusedRow]="true"
            (onSelectionChanged)="onUserSelectionChanged(cellInfo, e.component, $event)">

            <dxo-search-panel [visible]="true" location="before" width="250px"></dxo-search-panel>
            <dxo-scrolling mode="standard"></dxo-scrolling>
            <dxo-paging [enabled]="false"></dxo-paging>
            <dxo-selection mode="single"></dxo-selection>

            <dxi-column dataField="id" [visible]='false'></dxi-column>

            <dxi-column dataField="email" dataType="string"></dxi-column>

            <dxi-column dataField="fullName" dataType="string"> </dxi-column>
          </dx-data-grid>
        </div>
      </dx-drop-down-box>

      <dx-text-box *ngIf="cellInfo.data.userTypeId === userTypeEnum.SalesBroker" [(value)]="cellInfo.value"
        [showClearButton]="false" [valueChangeEvent]="'keyup'" (onValueChanged)="onEmailValueChanged($event, cellInfo)"></dx-text-box>
    </div>
  </dx-data-grid>
</div>
