<div class="modal-header">
  <h3 class="modal-title">Job Search</h3>
  <button type="button" class="close" aria-label="Close" (click)="cancel()" tabindex="-1">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div class="card">
    <div *ngIf="currentCompany.userTypeId !== userType.Client && currentCompany.userTypeId !== userType.Associate"
      class="card-header">

      <aside class="aside2 marginRight25">
        <div class="row mb-0">
          <dx-text-box [(value)]="searchString" [width]="200" valueChangeEvent="keyup"
            (onValueChanged)="runFilter($event)" [showClearButton]="true" class="filterText">
          </dx-text-box>
        </div>
      </aside>
    </div>

    <div class="card-body">

      <!-- Loading Spinner -->
      <js-loading-spinner *ngIf="loading" [usePopUp]="false"></js-loading-spinner>

      <dx-data-grid #dataGrid *ngIf="!loading" [dataSource]="jobData" [remoteOperations]="false" [height]="gridHeight"
        [showBorders]="true" [selection]="{ mode: 'single' }" [focusedRowEnabled]="true" [showRowLines]="true"
        [focusedRowKey]="selectedJobNumber" [selectedRowKeys]="selectedJobNumber" [hoverStateEnabled]="true"
        [wordWrapEnabled]="true" [rowAlternationEnabled]="false" (onSelectionChanged)="onSelectionChanged($event)"
        (onExporting)="gridService.onExporting($event, 'Jobs')">

        <dxo-paging [enabled]="false"></dxo-paging>
        <dxo-scrolling mode="standard"></dxo-scrolling>

        <dxi-column dataField="jobId" [visible]='false'></dxi-column>

        <dxi-column dataField="jobNumber" [width]="130" alignment="center" sortOrder="asc"></dxi-column>

        <dxi-column *ngIf="currentCompany.userTypeId !== userType.Associate && !isRestrictedForm"
          dataField="contractName"></dxi-column>

        <dxi-column dataField="jobAddressString" caption='Site Address'></dxi-column>

        <dxi-column *ngIf="!isRestrictedForm" dataField="currentActivityDesc" caption="Activity" [width]="125"
          [calculateSortValue]="calculateActivitySort">
        </dxi-column>
      </dx-data-grid>

    </div>
  </div>
</div>
