import { APP_ID, APP_NAME, B2C_AUTHORITY_DEV, B2C_AUTHORITY_DOMAIN, GoogleMapsKey, MAJOR_VERSION, PATCH_VERSION } from '../config/global-config';

export const environment = {
  production: false,
  applicationTitle: APP_NAME,
  clientName: APP_NAME,
  fullVersion: MAJOR_VERSION + PATCH_VERSION,
  majorVersion: MAJOR_VERSION,
  appId: APP_ID,

  applicationUrl: 'https://dev-packages.truthengine.com.au/',
  apiUrl: 'https://dev-api.truthengine.com.au/api/v1',

  configAppUrl: 'https://dev-config.truthengine.com.au/',
  addendaAppUrl: 'https://dev-core.truthengine.com.au/',
  estConfigAppUrl: 'https://dev-estimating.truthengine.com.au/',
  adminAppUrl: 'https://dev-admin.truthengine.com.au/',
  trackingAppUrl: 'https://dev-tracking.truthengine.com.au/',
  ordersAppUrl: 'https://dev-costing.truthengine.com.au/',

  authority: B2C_AUTHORITY_DEV,
  authoryDomain: B2C_AUTHORITY_DOMAIN,
  consentScopes: ['https://truthengine.onmicrosoft.com/felixWebApiDev/Access'],
  clientID: '0dec5c34-2c10-4b91-bdbd-a3dfb09bb8d5',

  googleMapsKey: GoogleMapsKey,
};
