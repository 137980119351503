import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../../services/global.service';
import { VariablesService } from '../../services/variables.service';

@Component({
  selector: 'js-maintenance-menu',
  templateUrl: './maintenance-menu.component.html',
  styleUrls: ['./maintenance-menu.component.scss']
})
export class MaintenanceMenuComponent implements OnInit {

  counter = 0;
  selectedIndex = 0;

  constructor(
    public variablesService: VariablesService
  ) { }

  ngOnInit() {
    const i = 1;

    if (this.variablesService.maintenanceSections.length) {
      this.variablesService.selectedMaintenanceSection = this.variablesService.maintenanceSections[0];
    }
    // if (this.globalService.packagesAdmin) {
    //   this.sections = ['Brokers', 'Developers', 'Estates', 'Suburbs', 'Logs', 'Control'];
    // }

    // if (this.globalService.landAdmin) {
    //   this.sections.push['Land Fields'];
    // }
  }

  tabSelected(e) {
    this.counter++;
    this.variablesService.selectedMaintenanceSection = e.itemData;
  }
}
