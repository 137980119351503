import { GlobalService } from '../../services/global.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import { Subscription } from 'rxjs';
import { BrokerService } from '../../services/felixApi/broker.service';
import { Broker } from '../../dtos/broker';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserTypeEnum } from '../../dtos/user-type.enum';
import { LogoComponent } from '../logo/logo.component';
import { GridService } from '../../services/grid.service';
import { BrokerUsersComponent } from '../broker-users/broker-users.component';

@Component({
  selector: 'js-brokers',
  templateUrl: './brokers.component.html',
  styleUrls: ['./brokers.component.scss']
})
export class BrokersComponent implements OnInit, OnDestroy {

  subscriptions: Subscription[] = [];
  // loadingData = true;
  gridHeight: number;
  brokers: Broker[];
  dataSource: DataSource;
  showSold = false;

  constructor(
    private brokerService: BrokerService,
    private globalService: GlobalService,
    protected gridService: GridService,
    private modalService: NgbModal) {
    this.maintainUsers = this.maintainUsers.bind(this);
    this.maintainLogo = this.maintainLogo.bind(this);
    this.onInitNewRow = this.onInitNewRow.bind(this);
    this.setCommissionCost = this.setCommissionCost.bind(this);
    this.setCommissionMarkUp = this.setCommissionMarkUp.bind(this);
    this.setCommissionGST = this.setCommissionGST.bind(this);
    this.setCommissionIncGST = this.setCommissionIncGST.bind(this);
  }

  ngOnInit() {
    this.gridHeight = window.innerHeight - 120;
    this.setUpDataSource();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  setUpDataSource() {
    this.dataSource = new DataSource({
      key: 'id',
      load: async () => {
        return new Promise((resolve, reject) =>
          this.brokerService.getBrokers(false).subscribe({
            next: (res) => {
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      },
      insert: async (values) => {
        return new Promise((resolve, reject) =>
          this.brokerService.addBroker(values).subscribe({
            next: (res) => {
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      },
      update: async (key, values) => {
        return new Promise((resolve, reject) =>
          this.brokerService.updateBroker(encodeURIComponent(key), values).subscribe({
            next: (res) => {
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      },
      remove: async (key) => {
        return new Promise((resolve, reject) =>
          this.brokerService.deleteBroker(encodeURIComponent(key)).subscribe({
            next: () => {
              return resolve();
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      }
    });
  }

  maintainUsers(e) {
    const modalRef = this.modalService.open(BrokerUsersComponent, { windowClass: 'modal-1200' });
    modalRef.componentInstance.userTypeId = UserTypeEnum.SalesBroker;
    modalRef.componentInstance.brokerId = e.row.data.id;
    modalRef.componentInstance.titleDescription = e.row.data.name;

    modalRef.result.then(() => { }, () => { });
  }

  maintainLogo(e) {
    const modalRef = this.modalService.open(LogoComponent, { windowClass: 'modal-1000' });
    modalRef.componentInstance.userTypeId = UserTypeEnum.SalesBroker;
    modalRef.componentInstance.brokerId = e.row.data.id;
    modalRef.componentInstance.titleDescription = e.row.data.name;

    modalRef.result.then(() => { }, () => { });
  }

  onInitNewRow(e) {
    e.data.isActive = true;
    e.data.commissionGST = this.globalService.globalGSTRate;
  }

  setCommissionCost(rowData: Broker, value, originalData) {
    rowData.commissionCost = value;

    const markup = rowData.commissionMarkUp === undefined ? originalData.commissionMarkUp : rowData.commissionMarkUp;
    const gstPercent = rowData.commissionGST === undefined ? originalData.commissionGST : rowData.commissionGST;

    if (markup && gstPercent && value) {
      rowData.commissionIncGST = Math.round((value * (1 + markup / 100) * (1 + gstPercent / 100)) * 100) / 100;
    } else {
      rowData.commissionIncGST = 0;
    }
  }

  setCommissionMarkUp(rowData: Broker, value, originalData) {
    rowData.commissionMarkUp = value;

    const commissionCost = rowData.commissionCost === undefined ? originalData.commissionCost : rowData.commissionCost;
    const gstPercent = rowData.commissionGST === undefined ? originalData.commissionGST : rowData.commissionGST;

    if (value && gstPercent && value) {
      rowData.commissionIncGST = Math.round((commissionCost * (1 + rowData.commissionMarkUp / 100) * (1 + gstPercent / 100)) * 100) / 100;
    } else {
      rowData.commissionIncGST = 0;
    }
  }

  setCommissionGST(rowData: Broker, value, originalData) {
    rowData.commissionGST = value;

    const commissionCost = rowData.commissionCost === undefined ? originalData.commissionCost : rowData.commissionCost;
    const markup = rowData.commissionMarkUp === undefined ? originalData.commissionMarkUp : rowData.commissionMarkUp;

    if (commissionCost && markup && value) {
      rowData.commissionIncGST = Math.round((commissionCost * (1 + markup / 100) * (1 + rowData.commissionGST / 100)) * 100) / 100;
    } else {
      rowData.commissionIncGST = 0;
    }
  }

  setCommissionIncGST(rowData: Broker, value, originalData) {
    rowData.commissionIncGST = value;

    const markup = rowData.commissionMarkUp === undefined ? originalData.commissionMarkUp : rowData.commissionMarkUp;
    const gstPercent = rowData.commissionGST === undefined ? originalData.commissionGST : rowData.commissionGST;

    if (markup && gstPercent && value) {
      rowData.commissionCost = Math.round((value / (1 + gstPercent / 100) / (1 + markup / 100)) * 100) / 100;
    } else {
      rowData.commissionCost = 0;
    }
  }
}
