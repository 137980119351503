<div class="modal-header">
  <h3 class="modal-title">Request Hold</h3>
  <button type="button" class="close" aria-label="Close" (click)="cancel()" tabindex="-1">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div>Job: {{brokerJob.job.jobNumber}} - {{brokerJob.job.jobAddressString}} {{brokerJob.job.jobAddress.suburbTown}}</div>
  <div>Broker: {{brokerJob.broker.name}}</div>
  <br>
</div>

<div class="modal-footer">
  <dx-button text="Request Hold" type="default" (onClick)="requestHold()"></dx-button>
</div>

<!-- loading spinner - DevExtreme -->
<dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [(visible)]="showLoadingSpinner" [showIndicator]="true"
  [showPane]="true" [shading]="true" [hideOnOutsideClick]="false">
</dx-load-panel>
