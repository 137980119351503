<div class="page">
  <js-loading-spinner *ngIf="loadingData" [usePopUp]="false"></js-loading-spinner>

  <dx-data-grid *ngIf="!loadingData" #allTaskGrid id="allTaskGrid" class="m-auto" [height]="gridHeight"
    [dataSource]="dataSource" columnResizingMode="widget" [columns]='gridColumns' allowColumnReordering="true"
    allowColumnResizing="true" columnAutoWidth="true" columnRenderingMode="virtual" remoteOperations="false"
    showRowLines="true" showBorders="true" wordWrapEnabled="true" filterSyncEnabled="true"
    (onOptionChanged)="gridOptionChanged($event)" (onToolbarPreparing)="onToolbarPreparing($event, 'toolbarTemplate')"
    (onExporting)="gridService.onExporting($event, 'Progress-Report')">

    <dxo-scrolling mode="standard"></dxo-scrolling>
    <dxo-export [enabled]="true"></dxo-export>
    <dxo-search-panel [visible]="true" location="before" width="150px"></dxo-search-panel>
    <dxo-sorting mode='multiple'></dxo-sorting>
    <dxo-column-chooser [enabled]="packagesAdmin" [search]="{enabled: true}" [height]="500"
      [width]="400"></dxo-column-chooser>

    <dxo-paging [enabled]="false"></dxo-paging>
    <dxo-filter-panel [visible]="true"></dxo-filter-panel>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-header-filter [visible]="true"></dxo-header-filter>

    <dxo-state-storing [enabled]="true" type="localStorage" storageKey="package-progress" [savingTimeout]="200">
    </dxo-state-storing>


    <!-- Templates -->
    <div *dxTemplate="let data of 'toolbarTemplate'">
      <span *ngIf="packagesWrite">
        <dx-select-box id="selectBroker" [dataSource]="brokers" displayExpr="name" valueExpr="id"
          placeholder="Choose Broker" [width]="250" [(value)]='brokerId' (onValueChanged)='brokerChanged($event)'>
        </dx-select-box>
      </span>

      <span *ngIf="layouts && layouts.length > 1">
        <dx-select-box id="selectLayout" [dataSource]="layouts" displayExpr="description" valueExpr="id" [width]="250"
          [value]='layoutId' (onValueChanged)='layoutChanged($event.value)'>
        </dx-select-box>
      </span>
    </div>

    <dxo-summary>
      <dxi-total-item column="jobNumber" summaryType="count" displayFormat="{0} jobs">
      </dxi-total-item>
    </dxo-summary>
  </dx-data-grid>
</div>
