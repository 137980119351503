import { CompanyActivityService } from '../../services/felixApi/company-activity.service';
import { forkJoin, Observable, of, throwError as observableThrowError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { GlobalService } from '../global.service';
import { Company } from '../../dtos/company';
import { CompanyConfiguration } from '../../dtos/company-configuration';
import { HttpService } from '../http.service';
import { AuthApiService } from './auth-api.service';
import { CompanyActivity } from '../../dtos/company-activity';
import { Holiday } from '../../dtos/holiday';
import { UserService } from './user.service';

@Injectable()
export class CompanyService {

  authCompanies: Company[];
  companyConfigsCompany: string;
  companyConfigs: CompanyConfiguration[];
  companyRolesCompany: string;
  activities: CompanyActivity[] = [];
  currentCompanyForActivitiesId: string;
  holidays: Holiday[] = [];
  holidaysCompany: number;

  constructor(
    private http: HttpClient,
    private httpService: HttpService,
    private authApi: AuthApiService,
    private companyActivityService: CompanyActivityService,
    private userService: UserService,
    private globalService: GlobalService) { }

  setCurrentCompanies(comps: Company[]) {
    this.authCompanies = comps;
  }

  getCurrentCompanies() {
    return this.authCompanies;
  }

  getCompanies() {
    return this.http.get<Company[]>(this.globalService.getApiUrl() + '/companies', this.httpService.getHttpOptions()).pipe(
      catchError(this.handleError));
  }

  getCompaniesByUserEmail(email: string) {
    return this.http.get<Company[]>(this.globalService.getApiUrl() + '/email-addresses/' + email + '/authorised-companies',
      this.httpService.getHttpOptions());
  }

  getCompany(id) {
    return this.http.get<Company>(this.globalService.getApiUrl() + '/companies/' + id, this.httpService.getHttpOptions()).pipe(
      catchError(this.handleError));
  }

  getCompanyConfigs(userId: number = null) {
    if (this.companyConfigsCompany === this.globalService.getCurrentCompanyId()
      && this.companyConfigs && this.companyConfigs.length) {
      return of(this.companyConfigs);
    } else {
      return this.http.get<CompanyConfiguration[]>(this.globalService.getApiUrl() + '/company-configurations',
        this.httpService.getHttpOptions(userId)).pipe(
          tap(res => {
            this.companyConfigs = res; this.companyConfigsCompany = this.globalService.getCurrentCompanyId();
          }), catchError(this.handleError));
    }
  }

  getCompanyConfigurations(): Observable<CompanyConfiguration[]> {
    return forkJoin(
      [
        this.getCompanyConfigs(),
        this.authApi.getAreaPermissions(),
        this.companyActivityService.getCompanyActivities(),
        this.getCurrentGST(),
        this.userService.getPermissionsForBrokerUser()
      ]
    )
      .pipe(map(
        ([configs]) => {
          return configs;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }

  getCompanyActivities(activeOnly: boolean = true, useCache: boolean = true) {
    if (useCache && this.activities && this.activities.length && this.currentCompanyForActivitiesId === this.globalService.getCurrentCompanyId()) {
      return of(this.activities);
    } else {
      const url = this.globalService.getApiUrl() + '/activities?activeOnly=' + activeOnly;
      return this.http.get<CompanyActivity[]>(url, this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.activities = res;
          this.currentCompanyForActivitiesId = this.globalService.getCurrentCompanyId();
        }),
        catchError(this.handleError));
    }
  }

  getCurrentGST(): Observable<number> {
    if (this.globalService.globalGSTRate) {
      return of(this.globalService.globalGSTRate);
    } else {
      return this.http.get<number>(this.globalService.getApiUrl()
        + '/gst/current', this.httpService.getHttpOptions()).pipe(
          tap(res => {
            this.globalService.globalGSTRate = res;
          }),
          catchError(this.handleError));
    }
  }

  getHolidays(): Observable<Holiday[]> {
    if (this.holidaysCompany === this.globalService.getCurrentCompany().id
      && this.holidays && this.holidays.length) {
      return of(this.holidays);
    } else {
      const url = this.globalService.getApiUrl() + '/holidays/';
      return this.http.get<Holiday[]>(url, this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.holidays = res;
          this.holidaysCompany = this.globalService.getCurrentCompany().id;
        }),
        catchError(this.handleError));
    }
  }

  // calc days excluding holidays
  addDaysExHolidays(startDate: Date, days): Date {
    if (!days || days === 0) {
      return startDate;
    }

    const newDate = new Date(startDate.valueOf());
    let dayCount = 0;

    while (dayCount < days) {
      newDate.setDate(newDate.getDate() + 1);

      const dateString = newDate.getFullYear() + '-'
        + ('0' + (newDate.getMonth() + 1)).toString().slice(-2) + '-'
        + ('0' + newDate.getDate()).slice(-2);

      if (newDate.getDay() !== 6 && newDate.getDay() !== 0 && !this.holidays.find(i => i.date.toString().substr(0, 10) === dateString)) {
        dayCount++;
      }
    }

    return newDate;
  }


  private handleError(err: HttpErrorResponse) {
    console.log(JSON.stringify(err));
    return observableThrowError(err);
  }
}
