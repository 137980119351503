import { Injectable } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { UtilsService } from './utils.service';
const ExcelJS = require('exceljs');
import { saveAs } from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class GridService {

  constructor(private utils: UtilsService) { }

  /* 'e' is the event receieved on dx grid (onToolbarPreparing) event */

  addRefreshBtnToToolbar(e, numItemsInToolbar = 3, refreshPosition = 2) {
    const toolbarItems = e.toolbarOptions.items;

    toolbarItems.push({
      location: 'after',
      template: 'refreshTemplate'
    });

    this.utils.moveArrayItemToNewIndex(toolbarItems, numItemsInToolbar - 1, refreshPosition - 1);
  }

  addTemplateTotoolbar(e, templateName: string, after = true) {
    const toolbarItems = e.toolbarOptions.items;

    toolbarItems.push({
      location: after ? 'after' : 'before',
      template: templateName
    });
  }

  focusOnCell(dataGrid: DxDataGridComponent, rowIndex: number, columnIndex: number) {
    dataGrid.instance.editCell(rowIndex, columnIndex);
    const element = dataGrid.instance.getCellElement(rowIndex, columnIndex);
    dataGrid.instance.focus(element);
  }

  onExporting(e, reportName: string = 'GridReport') {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(reportName,
      { pageSetup: { paperSize: 9, orientation: 'landscape' } });

    exportDataGrid({
      worksheet: worksheet,
      component: e.component,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), reportName + '.xlsx');
      });
    });
  }

}
