<div class="page">
  <dx-data-grid #packageGrid id="poGrid" [dataSource]="dataSource" [allowColumnReordering]="true" [height]="gridHeight"
    [allowColumnResizing]="true" [repaintChangesOnly]="true" [showBorders]="true" [rowAlternationEnabled]="true"
    [columnAutoWidth]="true" [wordWrapEnabled]="true" [hoverStateEnabled]="true" (onInitNewRow)="onInitNewRow($event)"
    class="m-auto" (onExporting)="gridService.onExporting($event, 'Brokers')">

    <dxo-search-panel [visible]="true" location="before" [width]="200"></dxo-search-panel>
    <dxo-export [enabled]="true"></dxo-export>
    <dxo-scrolling mode="standard"></dxo-scrolling>
    <dxo-paging [enabled]="false"></dxo-paging>
    <dxo-header-filter [visible]="true"></dxo-header-filter>

    <dxo-editing mode="row" [allowUpdating]="true" [allowAdding]="true" [allowDeleting]="true" [useIcons]="true"
      startEditAction="click" refreshMode="full">
    </dxo-editing>

    <dxi-column dataField="id" dataType="number" [visible]='false'></dxi-column>

    <dxi-column dataField="name" [minWidth]="200" sortOrder="asc">
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>

    <dxi-column dataField="description" [minWidth]="150" [hidingPriority]="1"> </dxi-column>

    <dxi-column dataField="address.streetName1" caption="Address" [width]="250" [hidingPriority]="6"></dxi-column>

    <dxi-column dataField="address.suburbTown" caption="Suburb" [width]="150" [hidingPriority]="5"></dxi-column>

    <dxi-column dataField="address.state" caption="State" [width]="80" [hidingPriority]="4"></dxi-column>

    <dxi-column dataField="address.postCode" caption="Post Code" [width]="90" [hidingPriority]="3"></dxi-column>

    <dxi-column dataField="commissionCost" dataType="number" format="#,##0" [width]="120" [hidingPriority]="9"
      [setCellValue]="setCommissionCost"></dxi-column>

    <dxi-column dataField="commissionMarkUp" caption="Commission Mark-Up %" dataType="number" format="#0.00"
      [width]="120" [hidingPriority]="7" [setCellValue]="setCommissionMarkUp"></dxi-column>

    <dxi-column dataField="commissionGST" caption="Commission GST %" dataType="number" format="#0.00" [width]="120"
      [hidingPriority]="10" [setCellValue]="setCommissionGST"></dxi-column>

    <dxi-column dataField="commissionIncGST" caption="Commission Total Inc GST" dataType="number" format="#,##0"
      [width]="120" [hidingPriority]="8" [setCellValue]="setCommissionIncGST"></dxi-column>

    <dxi-column dataField="defaultHoldDays" dataType="number" format="#" [width]="100"
      [hidingPriority]="11"></dxi-column>

    <dxi-column dataField="isActive" caption="Active" dataType="boolean" [width]="90" [hidingPriority]="2"></dxi-column>

    <dxi-column type="buttons" [width]="140" [fixed]="true">
      <dxi-button name="edit"></dxi-button>
      <dxi-button name="delete"></dxi-button>
      <dxi-button icon="image" hint='load logo' [onClick]="maintainLogo"></dxi-button>
      <dxi-button icon="user" hint='edit users' [onClick]="maintainUsers"></dxi-button>
    </dxi-column>

  </dx-data-grid>
</div>
