import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgbModule, NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';

import {
  BrowserCacheLocation,
  InteractionType, IPublicClientApplication,
  LogLevel, PublicClientApplication
} from '@azure/msal-browser';
import {
  MsalModule, MsalInterceptor,
  MsalInterceptorConfiguration, MsalGuardConfiguration,
  MSAL_INSTANCE, MSAL_GUARD_CONFIG,
  MSAL_INTERCEPTOR_CONFIG, MsalService,
  MsalBroadcastService, MsalRedirectComponent, MsalGuard
} from '@azure/msal-angular';

import { SharedModule } from './shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { LoginModule } from './login/login.module';

import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { UserService } from './services/felixApi/user.service';
import { CompanyService } from './services/felixApi/company.service';
import { GlobalService } from './services/global.service';
import { AuthService } from './services/auth.service';
import { NotificationService } from './services/notification.service';
import { RefreshGuard } from './services/refresh-guard.service';
import { MenuComponent } from './shared/menu/menu.component';
import { JobService } from './services/felixApi/job.service';
import { CompanyActivityService } from './services/felixApi/company-activity.service';
import { BrokerComponent } from './broker-view/broker-view.component';
import { DownloadPackageComponent } from './download-package/download-package.component';
import { DeveloperViewComponent } from './developer-view/developer-view.component';
import { BuilderViewComponent } from './builder-view/builder-view.component';
import { MapViewComponent } from './map-view/map-view.component';
import { BrokerService } from './services/felixApi/broker.service';
import { BrokersComponent } from './maintenance/brokers/brokers.component';
import { AllocatePackageComponent } from './builder-view/allocate-package/allocate-package.component';
import { UserMaintenanceComponent } from './maintenance/user-maintenance/user-maintenance.component';
import { LogoComponent } from './maintenance/logo/logo.component';
import { ApproveHoldComponent } from './builder-view/approve-hold/approve-hold.component';
import { DeveloperService } from './services/felixApi/developer.service';
import { DevelopersComponent } from './maintenance/developers/developers.component';
import { MaintenanceMenuComponent } from './maintenance/maintenance-menu/maintenance-menu.component';
import { EstateService } from './services/felixApi/estate.service';
import { EstatesComponent } from './maintenance/estates/estates.component';
import { EstateAttachmentsComponent } from './maintenance/estate-attachments/estate-attachments.component';
import { DeclineModalComponent } from './builder-view/decline-modal/decline-modal.component';
import { OnHoldLogService } from './services/felixApi/on-hold-log.service';
import { OnHoldLogComponent } from './maintenance/on-hold-log/on-hold-log.component';
import { RequestHoldComponent } from './broker-view/request-hold/request-hold.component';
import { SuburbService } from './services/felixApi/suburb.service';
import { SuburbsComponent } from './maintenance/suburbs/suburbs.component';
import { PackageControlComponent } from './maintenance/package-control/package-control.component';
import { PackageControlService } from './services/felixApi/package-control.service';
import { JobDocumentsComponent } from './job-documents/job-documents.component';
import { ReportComponent } from './report/report.component';
import { BrokerUsersComponent } from './maintenance/broker-users/broker-users.component';
import { LandFieldsComponent } from './land/land-fields/land-fields.component';
import { LandFieldLookupComponent } from './land/land-field-lookup/land-field-lookup.component';
import { LandHoldCategoriesComponent } from './land/land-hold-categories/land-hold-categories.component';
import { LandMastersComponent } from './land/land-masters/land-masters.component';
import { LandHoldWarningsComponent } from './land/land-hold-warnings/land-hold-warnings.component';
import { StateStoreComponent } from './shared/state-store/state-store.component';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

export function loggerCallback(logLevel: LogLevel, message: string) {
  if (logLevel === LogLevel.Error) {
    console.error(message);
    // how to log to a service?
  } else {
    // console.log(message);
  }
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.clientID,
      authority: environment.authority,
      redirectUri: environment.applicationUrl + 'auth',
      postLogoutRedirectUri: '/',
      knownAuthorities: [environment.authoryDomain]
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    }
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set(environment.apiUrl, environment.consentScopes);
  // protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: [...environment.consentScopes],
    },
  };
}

@NgModule({
  declarations: [
    AppComponent,
    WelcomeComponent,
    MenuComponent,
    BrokerComponent,
    DownloadPackageComponent,
    DeveloperViewComponent,
    BuilderViewComponent,
    MapViewComponent,
    BrokersComponent,
    AllocatePackageComponent,
    UserMaintenanceComponent,
    LogoComponent,
    ApproveHoldComponent,
    DevelopersComponent,
    MaintenanceMenuComponent,
    EstatesComponent,
    EstateAttachmentsComponent,
    DeclineModalComponent,
    OnHoldLogComponent,
    RequestHoldComponent,
    SuburbsComponent,
    PackageControlComponent,
    JobDocumentsComponent,
    ReportComponent,
    BrokerUsersComponent,
    LandFieldsComponent,
    LandFieldLookupComponent,
    LandHoldCategoriesComponent,
    LandMastersComponent,
    LandHoldWarningsComponent,
    StateStoreComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgbCollapseModule,
    NgbModule,
    ToastrModule.forRoot(),
    AppRoutingModule,
    SharedModule,
    LoginModule,
    MsalModule
  ],
  providers: [
    UserService,
    CompanyService,
    GlobalService,
    AuthService,
    NotificationService,
    JobService,
    CompanyActivityService,
    BrokerService,
    DeveloperService,
    EstateService,
    OnHoldLogService,
    SuburbService,
    PackageControlService,
    RefreshGuard,
    HttpClientModule,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }
