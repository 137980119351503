import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class VariablesService {

  maintenanceSections: string[] = [];
  selectedMaintenanceSection: string;

  constructor() { }

  setPackagesSections() {
    this.maintenanceSections = ['Brokers', 'Developers', 'Estates', 'Suburbs', 'Logs', 'Package Control'];
    this.selectedMaintenanceSection = 'Brokers';
  }


  setLandSections() {
    this.maintenanceSections.push('Land Fields');
    this.maintenanceSections.push('Land Hold Categories');

    if (this.maintenanceSections.length === 1) {
      this.selectedMaintenanceSection = 'Land Fields';
    }
  }

  clearMaintenanceSections() {
    this.maintenanceSections = [];
    this.selectedMaintenanceSection = '';
  }

}
