import { GlobalService } from '../services/global.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import { Subscription } from 'rxjs';
import { BrokerService } from '../services/felixApi/broker.service';
import { OnHoldStatusTypeEnum } from '../dtos/onHoldStatusTypeEnum';
import { BrokerJob } from '../dtos/broker-job';
import { NotificationService } from '../services/notification.service';
import { GridService } from '../services/grid.service';

@Component({
  selector: 'js-developer-view',
  templateUrl: './developer-view.component.html',
  styleUrls: ['./developer-view.component.scss']
})
export class DeveloperViewComponent implements OnInit, OnDestroy {

  subscriptions: Subscription[] = [];
  gridHeight: number;
  dataSource: DataSource;
  showSold = false;
  onHoldStatusTypeEnum = OnHoldStatusTypeEnum;
  onHoldStatusTypes: any[];
  loadingData = false;
  brokerJobs: BrokerJob[];

  constructor(
    private brokerService: BrokerService,
    private globalService: GlobalService,
    protected gridService: GridService,
    private notiService: NotificationService) {
  }

  ngOnInit() {
    this.onHoldStatusTypes = [
      { id: 1, description: 'Available' },
      { id: 2, description: 'Unavailable' },
      { id: 3, description: 'Approved' },
      { id: 4, description: 'Pending' },
      { id: 9, description: 'Sold' }
    ];

    this.setHeightWidths();

    this.subscriptions.push(
      this.globalService.innerHeightWidthChanged.subscribe(
        () => {
          setTimeout(() => {
            this.setHeightWidths();
          }, 200); // wait for iPhone and grid
        }
      )
    );

    this.globalService.setAreaSelected('developer-view');

    this.getBrokerJobs(this.showSold);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  setHeightWidths() {
    this.gridHeight = window.innerHeight - 70;
  }

  getBrokerJobs(showSold: boolean) {
    this.loadingData = true;
    this.subscriptions = this.subscriptions.concat(
      this.brokerService.getBrokerJobsForDeveloper(showSold)
        .subscribe({
          next: (brokerJobs) => {
            this.brokerJobs = brokerJobs;
            this.loadingData = false;
            this.setUpDataSource();
          },
          error: (error) => {
            this.notiService.showError(error);
            this.loadingData = false;
          }
        })
    );
  }

  setUpDataSource() {
    this.dataSource = new DataSource({
      key: 'id',
      load: () => this.brokerJobs
    });
  }

  onToolbarPreparing(e) {
    const toolbarItems = e.toolbarOptions.items;

    toolbarItems.unshift(
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxCheckBox',
        options: {
          text: 'Show Sold',
          value: this.showSold,
          rtlEnabled: true,
          onValueChanged: this.showSoldChanged.bind(this)
        }
      },
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          onClick: this.refresh.bind(this)
        }
      });
  }

  refresh() {
    this.getBrokerJobs(this.showSold);
  }

  showSoldChanged() {
    this.showSold = !this.showSold;
    this.getBrokerJobs(this.showSold);
  }
}
