import { BrokerService } from './../services/felixApi/broker.service';
import { GlobalService } from './../services/global.service';
import { JobService } from './../services/felixApi/job.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import { Subscription } from 'rxjs';
import { Broker } from '../dtos/broker';
import { BrokerJob } from '../dtos/broker-job';
import { NotificationService } from '../services/notification.service';
import { UserTypeEnum } from '../dtos/user-type.enum';
import { GridService } from '../services/grid.service';
import { JobTask } from '../dtos/job-task';
import { ReportGridItem } from '../dtos/report-grid-item';
import { DxColumn, DxGridState } from '../dtos/dx-grid';
import { StateStoreService } from '../services/felixApi/state-store.service';
import { StateStoreTypeEnum } from '../shared/state-store/state-store-type-enum';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StateStoreComponent } from '../shared/state-store/state-store.component';
import { StateStore } from '../dtos/state-store';

@Component({
  selector: 'js-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit, OnDestroy {

  subscriptions: Subscription[] = [];
  loadingData = true;
  brokerId: number;
  dataSource: DataSource;
  gridHeight: number;
  brokerJobs: BrokerJob[] = [];
  jobTasks: JobTask[] = [];
  brokers: Broker[] = [];

  gridColumns: ReportGridItem[] = [];
  lastColSortDirectionAscending: boolean;
  reportGridData: any[];
  packagesAdmin = false;
  packagesWrite: boolean;
  layouts: StateStore[];
  layoutId: number;

  constructor(
    private jobService: JobService,
    private globalService: GlobalService,
    private brokerService: BrokerService,
    private modalService: NgbModal,
    private notiService: NotificationService,
    protected gridService: GridService,
    private stateStoreService: StateStoreService
  ) { }

  ngOnInit() {
    this.setHeightWidths();
    this.getStateStoreForProgress();

    this.subscriptions.push(
      this.globalService.innerHeightWidthChanged.subscribe(
        () => {
          setTimeout(() => {
            this.setHeightWidths();
          }, 200); // wait for iPhone and grid
        }
      )
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  setHeightWidths() {
    this.gridHeight = window.innerHeight - 70;
  }

  getStateStoreForProgress() {
    this.loadingData = true;
    this.subscriptions = this.subscriptions.concat(
      this.stateStoreService.getAllStateStoresForMe(StateStoreTypeEnum.PackageProgressGrid)
        .subscribe({
          next: (stateStores) => {
            this.layouts = stateStores;
            this.layoutId = null;
            if (stateStores && stateStores.length) {
              const storedState = JSON.parse(stateStores[0].stateString) as DxGridState;
              localStorage.removeItem('package-progress');
              localStorage.setItem('package-progress', JSON.stringify(storedState));
              this.layoutId = stateStores[0].id;
            }
            this.checkBrokerForJobs();
          },
          error: () => {
            // assume no state stored
            this.checkBrokerForJobs();
          }
        })
    );
  }

  checkBrokerForJobs() {
    if (this.globalService.userTypeId === UserTypeEnum.SalesBroker || this.globalService.userTypeId === UserTypeEnum.Office) {
      this.brokerId = this.globalService.brokerId;
    } else if (this.globalService.packagesAdmin) {
      this.packagesAdmin = true;
    }
    this.packagesWrite = this.globalService.packagesWrite;
    this.getJobExtras();
  }

  getJobExtras() {
    if (this.packagesWrite) {
      this.subscriptions = this.subscriptions.concat(
        this.jobService.getAllJobExtras(true)
          .subscribe({
            next: () => {
              this.getBrokers();
            },
            error: (error) => {
              this.notiService.showError(error);
              this.loadingData = false;
            }
          })
      );
    } else {
      this.subscriptions = this.subscriptions.concat(
        this.jobService.getJobExtrasForBroker()
          .subscribe({
            next: () => {
              this.getBrokerJobs(this.brokerId);
            },
            error: () => {
              this.notiService.showWarning('No jobs found for Broker');
              this.loadingData = false;
            }
          })
      );
    }
  }

  brokerChanged(e) {
    this.brokerId = e.value;
    this.getBrokerJobs(this.brokerId);
  }

  layoutChanged(e) {
    this.layoutId = e;
    this.loadingData = true;
    const storedState = JSON.parse(this.layouts.find(i => i.id === this.layoutId).stateString) as DxGridState;
    localStorage.removeItem('package-progress');
    localStorage.setItem('package-progress', JSON.stringify(storedState));

    setTimeout(() => {
      this.setupColumns();
    }, 300);
  }

  getBrokers() {
    this.subscriptions = this.subscriptions.concat(
      this.brokerService.getBrokers(true)
        .subscribe({
          next: (brokers) => {
            this.brokers = brokers;
            if (this.brokers && this.brokers.length) {
              this.brokerId = this.brokers[0].id;
              this.getBrokerJobs(this.brokerId);
            }
          },
          error: (error) => {
            this.notiService.showError(error);
            this.loadingData = false;
          }
        })
    );
  }

  getBrokerJobs(brokerId: number) {
    this.loadingData = true;
    this.subscriptions = this.subscriptions.concat(
      this.brokerService.getBrokerJobs(true, brokerId)
        .subscribe({
          next: (brokerJobs) => {
            this.brokerJobs = brokerJobs?.filter(i => i.job?.salesDate);
            this.getData();
          },
          error: (error) => {
            this.notiService.showError(error);
            this.loadingData = false;
          }
        })
    );
  }

  getData() {
    // get the list of jobId's for the broker jobs
    const jobIds = this.brokerJobs.map(i => i.jobId);

    this.subscriptions = this.subscriptions.concat(
      this.brokerService.getProgressReport(this.brokerId, jobIds).subscribe({
        next: (res) => {
          this.jobTasks = res;
          this.setupColumns();
        },
        error: (err) => {
          this.notiService.notify(err);
          this.setupColumns();
        }
      })
    );
  }

  setupColumns() {
    let state: DxGridState;
    const stateStr = localStorage.getItem('package-progress');
    if (stateStr) {
      state = JSON.parse(stateStr) as DxGridState;
    }
    const noState = !state || !state.columns || !state.columns.length;
    this.gridColumns = [];

    // default cols //
    let res = this.checkStoredCol(state?.columns, 'jobNumber');
    this.addToColumns('jobNumber', 'Job Number', 'string', '', 'center', noState ? true : res[0], noState ? true : res[1], true);

    res = this.checkStoredCol(state?.columns, 'contractName');
    this.addToColumns('contractName', 'Client', 'string', '', 'left', noState ? true : res[0], noState ? true : res[1], true);

    res = this.checkStoredCol(state?.columns, 'siteAddress');
    this.addToColumns('siteAddress', 'Site Address', 'string', '', 'left', noState ? true : res[0], noState ? true : res[1], true);

    res = this.checkStoredCol(state?.columns, 'suburb');
    this.addToColumns('suburb', 'Suburb', 'string', '', 'left', noState ? false : res[0], noState ? false : res[1]);

    res = this.checkStoredCol(state?.columns, 'postCode');
    this.addToColumns('postCode', 'Postcode', 'string', '', 'left', noState ? false : res[0], noState ? false : res[1]);

    res = this.checkStoredCol(state?.columns, 'salesDate');
    this.addToColumns('salesDate', 'Sales Date', 'date', 'd-MMM-yy', 'center', noState ? false : res[0], noState ? false : res[1]);

    res = this.checkStoredCol(state?.columns, 'contractSignedDate');
    this.addToColumns('contractSignedDate', 'Contract Signed Date', 'date', 'd-MMM-yy', 'center', noState ? false : res[0], noState ? false : res[1]);

    res = this.checkStoredCol(state?.columns, 'currentActivity');
    this.addToColumns('currentActivity', 'Current Activity', 'string', '', 'left', noState ? true : res[0], noState ? true : res[1]);

    res = this.checkStoredCol(state?.columns, 'completionDate');
    this.addToColumns('completionDate', 'Practical Completion Date', 'date', 'd-MMM-yy', 'center', noState ? true : res[0], noState ? true : res[1]);

    res = this.checkStoredCol(state?.columns, 'handoverDate');
    this.addToColumns('handoverDate', 'Handover Date', 'date', 'd-MMM-yy', 'center', noState ? true : res[0], noState ? true : res[1]);

    res = this.checkStoredCol(state?.columns, 'targetCompletionDate');
    this.addToColumns('targetCompletionDate', 'Target Completion Date', 'date', 'd-MMM-yy', 'center', noState ? true : res[0], noState ? false : res[1]);

    res = this.checkStoredCol(state?.columns, 'forecastCompletionDate');
    this.addToColumns('forecastCompletionDate', 'Forecast Completion Date', 'date', 'd-MMM-yy', 'center', noState ? true : res[0], noState ? false : res[1]);

    // tasks
    this.brokerService.taskMasters.forEach(taskMaster => {
      const inId = 'taskIn' + taskMaster.id;
      res = this.checkStoredCol(state?.columns, inId);
      this.addToColumns(inId, taskMaster.clientDescriptionIn ?? taskMaster.taskTitle + ' In',
        'date', 'd-MMM-yy', 'center', noState ? true : res[0], noState ? false : res[1]);

      const outId = 'taskOut' + taskMaster.id;
      res = this.checkStoredCol(state?.columns, outId);
      this.addToColumns(outId, taskMaster.clientDescriptionOut ?? taskMaster.taskTitle + ' Out',
        'date', 'd-MMM-yy', 'center', noState ? true : res[0], noState ? false : res[1]);

      const forecastInId = 'taskForecastIn' + taskMaster.id;
      res = this.checkStoredCol(state?.columns, forecastInId);
      this.addToColumns(forecastInId, taskMaster.clientDescriptionIn ? taskMaster.clientDescriptionIn + ' (Forecast)' : taskMaster.taskTitle + ' Forecast In',
        'date', 'd-MMM-yy', 'center', noState ? false : res[0], noState ? false : res[1]);

      const forecastOutId = 'taskForecastOut' + taskMaster.id;
      res = this.checkStoredCol(state?.columns, forecastOutId);
      this.addToColumns(forecastOutId, taskMaster.clientDescriptionOut ? taskMaster.clientDescriptionOut + ' (Forecast)' : taskMaster.taskTitle + ' Forecast Out',
        'date', 'd-MMM-yy', 'center', noState ? false : res[0], noState ? false : res[1]);
    });



    res = this.checkStoredCol(state?.columns, 'nextClaimStage');
    this.addToColumns('nextClaimStage', 'Next Claim Stage', 'string', '', 'left', noState ? false : res[0], noState ? false : res[1]);
    res = this.checkStoredCol(state?.columns, 'nextClaimAmount');
    this.addToColumns('nextClaimAmount', 'Next Claim Amount', 'number', '#,###', 'right', noState ? false : res[0], noState ? false : res[1]);

    res = this.checkStoredCol(state?.columns, 'nextClaimDate');
    this.addToColumns('nextClaimDate', 'Next Claim Date', 'date', 'd-MMM-yy', 'center', noState ? false : res[0], noState ? false : res[1]);


    this.generateGridRows();
  }

  checkStoredCol(columns: DxColumn[], colField: string): [inGrid: boolean, visible: boolean] {
    if (!columns) {
      return [false, false];
    }
    const foundCol = columns.filter(c => c.dataField === colField);
    if (foundCol && foundCol.length === 1 && foundCol[0].visible) {
      return [true, true];
    } else if (foundCol && foundCol.length === 1) {
      return [true, false];
    } else {
      return [false, false];
    }
  }

  generateGridRows() {
    this.reportGridData = [];

    this.brokerJobs?.forEach(brokerJob => {
      const reportGridRow = {};
      reportGridRow['jobId'] = brokerJob.jobId;
      reportGridRow['jobNumber'] = brokerJob.job.jobNumber;
      reportGridRow['contractName'] = brokerJob.job.contractName;
      reportGridRow['siteAddress'] = brokerJob.job.jobAddressString;
      reportGridRow['salesDate'] = brokerJob.job.salesDate;
      reportGridRow['contractSignedDate'] = brokerJob.job.contractSignedDate;
      reportGridRow['suburb'] = brokerJob.job.jobAddress?.suburbTown;
      reportGridRow['postCode'] = brokerJob.job.jobAddress?.postCode;

      const jobExtra = this.jobService.jobExtras?.find(i => i.jobId === brokerJob.jobId);

      if (jobExtra && jobExtra.maintenanceCompleteDate) {
        reportGridRow['currentActivity'] = 'Maintenance Complete';
        reportGridRow['currentActivityCode'] = '';
      } else if (jobExtra && jobExtra.currentActivityId) {
        const currentActivity = this.brokerService.activities.find(i => i.id === jobExtra.currentActivityId);
        reportGridRow['currentActivity'] = currentActivity ? currentActivity.description : '';
        reportGridRow['currentActivityCode'] = currentActivity ? currentActivity.activityCode : '';
      } else {
        if (brokerJob.job.salesDate) {
          reportGridRow['currentActivity'] = 'Sold';
        } else {
          reportGridRow['currentActivity'] = 'Pending Sale';
        }
        reportGridRow['currentActivityCode'] = '';
      }

      reportGridRow['completionDate'] = jobExtra ? jobExtra.completionDate : '';
      reportGridRow['handoverDate'] = jobExtra ? jobExtra.handoverDate : '';
      reportGridRow['targetCompletionDate'] = jobExtra ? jobExtra.targetCompletionDate : '';
      reportGridRow['forecastCompletionDate'] = jobExtra ? jobExtra.forecastCompletionDate : '';

      // tasks
      const tasksForJob = this.jobTasks.filter(i => i.jobId === brokerJob.jobId);
      const forecastsForJob = this.brokerService.forecastTasks.filter(i => i.jobId === brokerJob.jobId);


      const nextClaim = this.brokerService.nextClaims.find(i => i.jobId == brokerJob.jobId);
      reportGridRow['nextClaimStage'] = nextClaim?.activityDescription;
      reportGridRow['nextClaimAmount'] = nextClaim?.amount;

      this.brokerService.taskMasters.forEach(taskMaster => {
        const jobTask = tasksForJob.find(i => i.taskMasterId === taskMaster.id);
        if (jobTask) {
          reportGridRow['taskIn' + taskMaster.id] = jobTask.startDate;
          reportGridRow['taskOut' + taskMaster.id] = jobTask.endDate;
        } else {
          // still set to null to not be undefined so that sorting works
          reportGridRow['taskIn' + taskMaster.id] = null;
          reportGridRow['taskOut' + taskMaster.id] = null;
        }

        const forecastTask = forecastsForJob.find(i => i.taskMasterId === taskMaster.id);
        if (forecastTask) {
          reportGridRow['taskForecastIn' + taskMaster.id] = forecastTask.forecastStartDate;
          reportGridRow['taskForecastOut' + taskMaster.id] = forecastTask.forecastCompletionDate;

          if (nextClaim?.taskMasterId === taskMaster.id) {
            reportGridRow['nextClaimDate'] = forecastTask.forecastCompletionDate;
          }
        } else {
          reportGridRow['taskForecastIn' + taskMaster.id] = null;
          reportGridRow['taskForecastOut' + taskMaster.id] = null;
        }
      });

      this.reportGridData.push(reportGridRow);
    });

    this.loadingData = false;

    this.setUpDataSource();
  }

  addToColumns(
    dataField: string,
    caption: string,
    dataType: string,
    format: string,
    alignment: 'left' | 'right' | 'center',
    inGrid: boolean,
    visible: boolean = false,
    fixed: boolean = false
  ) {
    const col = {
      dataField: dataField,
      caption: caption,
      dataType: dataType,
      format: format,
      alignment: alignment,
      visible: visible,
      fixed: fixed
    };

    if (dataType === 'date') {
      col['sortingMethod'] = this.dateSort;
    }


    if (dataField.length >= 5 && dataField.substring(0, 5) === 'field') {
      col['allowEditing'] = true;
    } else if (dataField.length >= 7 && dataField.substring(0, 7) === 'taskDue') {
      col['allowEditing'] = true;
    } else if (dataField.length >= 11 && dataField.substring(0, 11) === 'taskComment') {
      col['allowEditing'] = true;
    } else {
      col['allowEditing'] = false;
    }

    // if (inGrid) {
    this.gridColumns.push(col);
    // }
  }

  dateSort = (one, two): number => {
    if (!one && !two) { return 0; }
    if (!one && two) {
      return this.lastColSortDirectionAscending ? 1 : -1;
    }
    if (one && !two) {
      return this.lastColSortDirectionAscending ? -1 : 1;
    }
    if (one < two) { return -1; }
    if (one > two) { return 1; }
    return 0;
  }

  gridOptionChanged(e) {
    if (e.value === 'asc') {
      this.lastColSortDirectionAscending = true;
    } else if (e.value === 'desc') {
      this.lastColSortDirectionAscending = false;
    }
  }

  setUpDataSource() {
    this.dataSource = new DataSource({
      key: 'jobId',
      load: () => this.reportGridData
    });
  }

  onToolbarPreparing(e, templateName: string) {
    const toolbarItems = e.toolbarOptions.items;

    toolbarItems.push({
      location: 'before',
      locateInMenu: 'auto',
      template: templateName
    });

    if (this.packagesAdmin) {
      toolbarItems.unshift(
        {
          location: 'after',
          widget: 'dxButton',
          options: {
            text: 'Save Layout',
            onClick: this.saveState.bind(this)
          }
        },
        {
          location: 'after',
          widget: 'dxButton',
          options: {
            text: 'Reset Layout',
            onClick: this.resetState.bind(this)
          }
        }
      );
    }
  }

  saveState() {
    let currentState = JSON.parse(localStorage.getItem('package-progress'));
    if (currentState) {
      const modalRef = this.modalService.open(StateStoreComponent, { windowClass: 'modal-stateStore' });
      modalRef.componentInstance.stateStoreTypeId = StateStoreTypeEnum.PackageProgressGrid;
      modalRef.componentInstance.stateString = JSON.stringify(currentState);

      modalRef.result.then(() => {
        this.getStateStoreForProgress();
      }, () => {
        // reload layouts
        this.getStateStoreForProgress();
      });
    }
  }

  resetState() {
    localStorage.removeItem('package-progress');
    this.loadingData = true;
    setTimeout(() => {
      this.setupColumns();
    }, 300);
  }
}
