import { JobService } from './../../services/felixApi/job.service';
import { Component, EventEmitter, HostListener, Input, OnChanges, OnDestroy, Output } from '@angular/core';
import { NotificationService } from '../../services/notification.service';
import { Subscription } from 'rxjs';
import { JobDocumentService } from '../../services/felixApi/job-document.service';
import { AttachmentTypeEnum } from '../../dtos/attachment-type.enum';

@Component({
  selector: 'js-show-image',
  templateUrl: './show-image.component.html',
  styleUrls: ['./show-image.component.scss']
})
export class ShowImageComponent implements OnChanges, OnDestroy {
  @Input() imageId: number;
  @Input() attachmentTypeId: number;
  @Input() isSharePoint: boolean;
  @Input() sharePointId: string;

  @Output() emitDate: EventEmitter<Date> =
    new EventEmitter<Date>();

  subscriptions: Subscription[] = [];
  image: any;
  imageFound: boolean;
  loading: boolean;
  imageTypeId: number;
  maxWidth: number;
  maxHeight: number;

  @HostListener('window:resize') onResize() {
    setTimeout(() => {
      this.setHeightWidths();
    }, 600); // wait
  }

  constructor(
    private jobDocumentService: JobDocumentService,
    private jobService: JobService,
    private notiService: NotificationService) { }

  ngOnChanges() {
    this.setHeightWidths();
    this.imageFound = false;
    this.loading = true;

    if (!this.isSharePoint) {
        this.subscriptions = this.subscriptions.concat(
          this.jobDocumentService.getJobDocAttachment(this.imageId, true, true).subscribe(
            jobDocAttachment => {
              this.emitDate.emit(jobDocAttachment.fileModifiedDate);
              this.getImage(jobDocAttachment.attachment, jobDocAttachment.attachmentTypeId);
            },
            err => {
              this.loading = false;
              this.notiService.notify(err);
            })
        );
    } else {
      this.subscriptions = this.subscriptions.concat(
        this.jobDocumentService.getSharePointDocument(this.jobService.currentJob?.jobNumber, this.sharePointId, true, true).subscribe(
          res => {
            this.getImage(res.attachment, res.attachmentTypeId);
          },
          err => {
            this.loading = false;
            this.notiService.notify(err);
          })
      );
    }
  }

  setHeightWidths() {
    this.maxWidth = window.innerWidth > 700 ? 600 : window.innerWidth - 100;
    this.maxHeight = this.maxWidth * 2 / 3
  }

  getImage(attachment, attachmentTypeId) {
    if (attachmentTypeId === AttachmentTypeEnum.JPEG) {
      this.image = 'data:image/jpeg;base64,' + attachment;
    } else {
      this.image = 'data:image/png;base64,' + attachment;
    }
    this.imageTypeId = attachmentTypeId;
    this.imageFound = true;
    this.loading = false;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }
}
