<nav *ngIf="signedIn && currComp" class="navbar navbar-expand-md navbar-light navbarclass">
  <div>
    <img class="navbar-brand logo" src="../../../assets/logoTransparent.png" [routerLink]="['/welcome']" />
  </div>

  <span *ngIf="currComp && !(innerWidth < 768)" class="cust-divider-vertical"></span>

  <button class="navbar-toggler d-lg-none" type="button" (click)="isCollapsed = !isCollapsed"
    [attr.aria-expanded]="!isCollapsed" aria-controls="navbarsExampleDefault" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div *ngIf="!loadingConfigs" class="collapse navbar-collapse" id="navbarsExampleDefault" [ngbCollapse]="isCollapsed">
    <ul class="navbar-nav mr-auto mt-2 mt-lg-0" style="margin-top: 0!important;">

      <a *ngIf="((isBroker && canSeeMapsView) || isOfficeUser) && !loadingConfigs" class="nav-item active nav-link tab"
        [routerLink]="['/map-view']" [class.disabled]="disabledMenu" [class.selected]="areaSelected === 'map-view'"
        (click)="isCollapsed = true; menuPress('map-view')">
        Map-View
      </a>

      <a *ngIf="isBroker && canSeePackages" class="nav-item active nav-link tab" [routerLink]="['/broker-view']"
        [class.disabled]="disabledMenu" [class.selected]="areaSelected === 'broker-view'"
        (click)="isCollapsed = true; menuPress('broker-view')">
        Packages
      </a>

      <a *ngIf="isDeveloper" class="nav-item active nav-link tab" [routerLink]="['/developer-view']"
        [class.disabled]="disabledMenu" [class.selected]="areaSelected === 'developer-view'"
        (click)="isCollapsed = true; menuPress('developer-view')">
        Packages
      </a>

      <a *ngIf="isOfficeUser" class="nav-item active nav-link tab" [routerLink]="['/builder-view']"
        [class.disabled]="disabledMenu" [class.selected]="areaSelected === 'builder-view'"
        (click)="isCollapsed = true; menuPress('builder-view')">
        Packages
      </a>

      <a *ngIf="(isBroker && canSeeProgress) || isOfficeUser" class="nav-item active nav-link tab"
        [routerLink]="['/report']" [class.disabled]="disabledMenu" [class.selected]="areaSelected === 'report'"
        (click)="isCollapsed = true; menuPress('report')">
        Progress
      </a>

      <a *ngIf="(isBroker && canSeeDocuments) || isOfficeUser" class="nav-item active nav-link tab"
        [routerLink]="['/job-documents']" [class.disabled]="disabledMenu"
        [class.selected]="areaSelected === 'job-documents'" (click)="isCollapsed = true; menuPress('job-documents')">
        Documents
      </a>

      <a *ngIf="isLandUser" class="nav-item active nav-link tab" [routerLink]="['/land']"
        [class.disabled]="disabledMenu" [class.selected]="areaSelected === 'land'"
        (click)="isCollapsed = true; menuPress('land')">
        Land
      </a>

      <a *ngIf="canSeeMaintenance || canSeeLandMaintenance" class="nav-item active nav-link tab"
        [routerLink]="['/maintenance']" [class.disabled]="disabledMenu"
        [class.selected]="areaSelected === 'maintenance'" (click)="isCollapsed = true; menuPress('maintenance')">
        Set-Up
      </a>

    </ul>
  </div>

  <div *ngIf="innerWidth > 450">
    <a class="currComp" [routerLink]="['/welcome']">{{currComp.companyShortName}}</a>
  </div>
</nav>
