<dx-tab-panel *ngIf="variablesService.maintenanceSections.length" #panel height="calc(100vh - 50px)"
  [dataSource]="variablesService.maintenanceSections" [selectedIndex]="selectedIndex" [loop]="false"
  [swipeEnabled]="false" (onTitleClick)="tabSelected($event)">

  <div *dxTemplate="let title of 'title'">
    <span>{{title}}</span>
  </div>

  <div *dxTemplate="let section of 'item'">
    <div style="height: calc(100vh - 100px); max-width: 1850px; margin: auto;">
      <js-brokers *ngIf="section === 'Brokers'"></js-brokers>
      <js-developers *ngIf="section === 'Developers'"></js-developers>
      <js-estates *ngIf="section === 'Estates'"></js-estates>
      <js-suburbs *ngIf="section === 'Suburbs'"></js-suburbs>
      <js-on-hold-log *ngIf="section === 'Logs'"></js-on-hold-log>
      <js-package-control *ngIf="section === 'Package Control'"></js-package-control>
      <js-land-fields *ngIf="section === 'Land Fields'"></js-land-fields>
      <js-land-hold-categories *ngIf="section === 'Land Hold Categories'"></js-land-hold-categories>
    </div>
  </div>
</dx-tab-panel>
