import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TrackingColourEnum } from '../../dtos/tracking-colour.enum';
import { Subscription } from 'rxjs';

@Component({
  selector: 'js-set-colour',
  templateUrl: './set-colour.component.html',
  styleUrls: ['./set-colour.component.scss']
})
export class SetColourComponent implements OnInit {
  trackingColourEnum = TrackingColourEnum;
  subscriptions: Subscription[] = [];
  updating = false;

  constructor(
    private _activeModal: NgbActiveModal
  ) { }

  ngOnInit() {
  }

  cancel() {
    this._activeModal.dismiss();
  }

  markColour(colourId: number) {
    this._activeModal.close(colourId);
  }

}
