<div class="modal-header">
  <h3 class="modal-title">Download Package</h3>
  <button type="button" class="close" aria-label="Close" (click)="cancel()" tabindex="-1">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div>Job: {{brokerJob.job.jobNumber}}</div>
  <div>{{brokerJob.job.jobAddressString}} {{brokerJob.job.jobAddress.suburbTown}}</div>
  <br>

  <div *ngIf="brokerJob.onHoldStatusTypeId === onHoldStatusTypeEnum.Approved">
    <div class="field-label">Contract Name:</div>
    <div class="field-value">
      <dx-text-area [(ngModel)]="contractName" [height]="150" [width]="fieldValueWidth"></dx-text-area>
    </div>

    <br><br>

    <div class="field-label">Quote Date:</div>
    <div class="field-value">
      <dx-date-box [(ngModel)]="quoteDate" [width]="160"></dx-date-box>
    </div>
  </div>

  <js-loading-spinner *ngIf="loading" [usePopUp]="false"></js-loading-spinner>
</div>

<div class="modal-footer">
  <dx-button *ngIf="!loading" [text]="downloadText" type="success" class="mt-2"
    (onClick)="saveAndDownload()"></dx-button>
</div>
