<div class="modal-header">
  <h3 class="modal-title">Hold Warnings</h3>
  <button type="button" class="close" aria-label="Close" (click)="cancel()" tabindex="-1">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <dx-data-grid [height]="gridHeight" [dataSource]="dataSource" [allowColumnReordering]="false"
    [allowColumnResizing]="true" [repaintChangesOnly]="true" [showBorders]="true" [rowAlternationEnabled]="true"
    [wordWrapEnabled]="true" [hoverStateEnabled]="true"
    (onExporting)="gridService.onExporting($event, 'Hold-Warnings')">

    <dxo-search-panel [visible]="true" location="after" [width]="200"></dxo-search-panel>
    <dxo-export [enabled]="true"></dxo-export>
    <dxo-scrolling mode="standard"></dxo-scrolling>
    <dxo-paging [enabled]="false"></dxo-paging>

    <dxo-editing mode="row" [allowUpdating]="true" [allowDeleting]="true" [allowAdding]="true"
      [useIcons]="true"></dxo-editing>

    <dxi-column dataField="id" dataType="number" [visible]='false' sortOrder="asc"></dxi-column>

    <dxi-column dataField="emailAddresses"></dxi-column>

    <dxi-column dataField="daysPriorToHoldExpiry" caption="Days prior to send warning" dataType="number"
      [width]="100"></dxi-column>

    <dxi-column dataField="dateSent" dataType="date" alignment="center" format="d-MMM-yy" [width]="100"
      [allowEditing]="false"></dxi-column>
  </dx-data-grid>
</div>
