<div class="modal-header">
  <h4 class="modal-title">{{landFieldName}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>


<div class="modal-body">
  <div class="page">

    <dx-data-grid #landLookupGrid id="landLookupGrid" class="m-auto taskgrid-height" [dataSource]="dataSource"
      [allowColumnReordering]="true" [allowColumnResizing]="true" [showBorders]="true" [rowAlternationEnabled]="true"
      [wordWrapEnabled]="true" (onInitNewRow)="onInitNewRow($event)">

      <dxo-scrolling mode="standard"></dxo-scrolling>
      <dxo-search-panel [visible]="true" location="before" width="250px"></dxo-search-panel>
      <dxo-editing mode="row" [allowAdding]="true" [allowUpdating]="true" [allowDeleting]="true" [useIcons]="true"
        refreshMode="reshape">
      </dxo-editing>
      <dxo-paging [enabled]="false"></dxo-paging>
      <dxo-sorting mode="none"></dxo-sorting>

      <dxi-column dataField="id" dataType="number" [visible]='false'> </dxi-column>

      <dxi-column dataField="description" sortOrder="asc">
        <dxi-validation-rule type="required"></dxi-validation-rule>
      </dxi-column>
    </dx-data-grid>

  </div>
</div>

<div class="modal-footer">
  <button class="btn btn-primary" (click)="close()">Close</button>
</div>
