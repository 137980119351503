<div class="modal-header">
  <h3 class="modal-title">Select Colour</h3>
  <button type="button" class="close" aria-label="Close" (click)="cancel()" tabindex="-1">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div *ngIf="!updating" class="modal-body">
  <span class="me-2">
    <dx-button id="orangeButton2" [width]="130" type="default" text="Orange"
      (onClick)="markColour(trackingColourEnum.Orange)"></dx-button>
  </span>
  <span class="me-2">
    <dx-button id="yellowButton2" [width]="130" type="danger" text="Yellow"
      (onClick)="markColour(trackingColourEnum.Yellow)"></dx-button>
  </span>
  <span class="me-2">
    <dx-button id="tealButton2" [width]="130" type="danger" text="Teal" (onClick)="markColour(trackingColourEnum.Teal)">
    </dx-button>
  </span>
  <span class="me-2">
    <dx-button id="blueButton2" [width]="130" type="default" text="Blue" (onClick)="markColour(trackingColourEnum.Blue)">
    </dx-button>
  </span>
  <span class="me-2">
    <dx-button id="magentaButton2" [width]="130" type="default" text="Magenta"
      (onClick)="markColour(trackingColourEnum.Magenta)"></dx-button>
  </span>
  <span class="me-2">
    <dx-button id="greyButton2" [width]="130" type="default" text="Grey" (onClick)="markColour(trackingColourEnum.Grey)">
    </dx-button>
  </span>
  <span>
    <dx-button id="clearButton2" [width]="130" type="default" stylingMode="outlined" text="Clear"
      (onClick)="markColour(0)">
    </dx-button>
  </span>
</div>
