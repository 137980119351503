import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { MsalGuard } from '@azure/msal-angular';
import { RefreshGuard } from './services/refresh-guard.service';
import { BrokerComponent } from './broker-view/broker-view.component';
import { DeveloperViewComponent } from './developer-view/developer-view.component';
import { BuilderViewComponent } from './builder-view/builder-view.component';
import { MapViewComponent } from './map-view/map-view.component';
import { MaintenanceMenuComponent } from './maintenance/maintenance-menu/maintenance-menu.component';
import { JobDocumentsComponent } from './job-documents/job-documents.component';
import { ReportComponent } from './report/report.component';
import { LandMastersComponent } from './land/land-masters/land-masters.component';

const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'login/:comp', component: LoginComponent },
  { path: 'welcome', component: WelcomeComponent, canActivate: [RefreshGuard] }, // msal stopped working when this guarded
  { path: 'welcome/:comp', component: WelcomeComponent, canActivate: [MsalGuard, RefreshGuard] },
  { path: 'broker-view', component: BrokerComponent, canActivate: [MsalGuard, RefreshGuard], runGuardsAndResolvers: 'always' },
  { path: 'developer-view', component: DeveloperViewComponent, canActivate: [MsalGuard, RefreshGuard], runGuardsAndResolvers: 'always' },
  { path: 'builder-view', component: BuilderViewComponent, canActivate: [MsalGuard, RefreshGuard], runGuardsAndResolvers: 'always' },
  { path: 'map-view', component: MapViewComponent, canActivate: [MsalGuard, RefreshGuard], runGuardsAndResolvers: 'always' },
  { path: 'job-documents', component: JobDocumentsComponent, canActivate: [MsalGuard, RefreshGuard], runGuardsAndResolvers: 'always' },
  { path: 'maintenance', component: MaintenanceMenuComponent, canActivate: [MsalGuard, RefreshGuard], runGuardsAndResolvers: 'always' },
  { path: 'report', component: ReportComponent, canActivate: [MsalGuard, RefreshGuard], runGuardsAndResolvers: 'always' },
  { path: 'land', component: LandMastersComponent, canActivate: [MsalGuard, RefreshGuard], runGuardsAndResolvers: 'always' },
  { path: 'login', redirectTo: '', pathMatch: 'full' },
  { path: '**', redirectTo: '', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'ignore' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
