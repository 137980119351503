import { CompanyService } from './company.service';
import { Injectable } from '@angular/core';
import { throwError as observableThrowError, Observable, of, forkJoin } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Job } from '../../dtos/job';
import { GlobalService } from '../global.service';
import { TruthEngineSession, SessionName } from '../../dtos/session';
import { JobExtra } from '../../dtos/job-extra';
import { HouseType } from '../../dtos/house-type';
import { HttpService } from '../http.service';
import { AuthService } from '../auth.service';
import { IJobItemAttachment } from '../../dtos/job-item-attachment';
import { BrokerService } from './broker.service';
import { SuburbService } from './suburb.service';
import { EstateService } from './estate.service';

@Injectable({
  providedIn: 'root'
})
export class JobService {

  private currentJobNo: string;
  jobs: Job[] = [];
  currentJob: Job;
  jobsCompany: number;
  jobExtras: JobExtra[] = [];
  currentJobExtra: JobExtra;
  jobExtrasCompany: number;
  houseTypes: HouseType[] = [];
  houseTypesCompany: number;
  jobDocumentsJobId: number;
  jobRolesCompany: number;
  private _jobItemUrl = '';

  constructor(
    private _http: HttpClient,
    private authService: AuthService,
    private httpService: HttpService,
    private companyService: CompanyService,
    private suburbService: SuburbService,
    private brokerService: BrokerService,
    private estateService: EstateService,
    private globalService: GlobalService) { }


  getSessionObject(): TruthEngineSession {
    if (sessionStorage.getItem(SessionName)) {
      return JSON.parse(sessionStorage.getItem(SessionName));
    } else {
      const session = new TruthEngineSession();
      sessionStorage.setItem(SessionName, JSON.stringify(session));
      return JSON.parse(sessionStorage.getItem(SessionName));
    }
  }

  setSessionAtt(att: string, obj: any) {
    const session = this.getSessionObject();
    session[att] = obj;
    const sessionString = JSON.stringify(session);
    sessionStorage.setItem(SessionName, sessionString);
  }

  setCurrentJob(job: string) {
    this.currentJobNo = job;
    // store the latest job number to be the default in long term storage as well as session
    localStorage.setItem('jobANNX-LastJobNumber', job);
    this.setSessionAtt('currentJobNo', job);
  }

  // get one job
  getJob(jobNo: string): Observable<Job> {
    return this._http.get<Job>(this.globalService.getApiUrl() + '/jobs/packages/' + jobNo,
      this.httpService.getHttpOptions()).pipe(
        catchError(this.handleError));
  }

  getCurrentJob(): string {
    if (this.currentJobNo !== undefined && this.currentJobNo !== null) {
      return this.currentJobNo;
    }
    // return the last used job number on this client as default
    const session = this.getSessionObject();
    if (session && session.currentJobNo) {
      this.currentJobNo = session.currentJobNo;
      return this.currentJobNo;
    }
    if (localStorage.getItem('jobANNX-LastJobNumber')) {
      this.currentJobNo = localStorage.getItem('jobANNX-LastJobNumber');
      if (this.currentJobNo !== undefined && this.currentJobNo !== null) {
        return this.currentJobNo;
      }
    }
    return '';
  }

  // search for jobs by a matching contract name string
  getJobsByAddress(useCache: boolean, includeName: boolean, includeSuburb: boolean): Observable<Job[]> {
    if (useCache && this.jobsCompany === this.globalService.getCurrentCompany().id
      && this.jobs && this.jobs.length) {
      return of(this.jobs);
    } else {
      const searchString = '';
      return this._http.get<Job[]>(this.globalService.getApiUrl() + '/jobs/packages?jobSearchType=JobAddress&searchString=' + searchString,
        this.httpService.getHttpOptions()).pipe(
          tap(res => {
            this.jobs = res; this.jobsCompany = this.globalService.getCurrentCompany().id;

            this.jobs.forEach(job => {
              job.jobAddressString = this.globalService.getJobString(job, includeName, includeSuburb);
            });
          }),
          catchError(this.handleError));
    }
  }

  getJobsByAssignee(userId: number) {
    return this._http.get<Job[]>(this.globalService.getApiUrl() + '/jobs?jobSearchType=ContractName&userId='
      + userId, this.httpService.getHttpOptions()).pipe(
        catchError(this.handleError));
  }

  // search for jobs by a matching contract name string
  getTemplateJobs(): Observable<Job[]> {
    return this._http.get<Job[]>(this.globalService.getApiUrl() + '/jobs?jobSearchType=Templates',
      this.httpService.getHttpOptions()).pipe(
        catchError(this.handleError));
  }

  getMyJobs() {
    return this._http.get<Job[]>(this.globalService.getApiUrl() + '/jobs?jobSearchType=ContractName&userId='
      + this.authService.getCurrentUserId(), this.httpService.getHttpOptions()).pipe(
        catchError(this.handleError));
  }

  getJobsByAddressWithExtras(useCache: boolean, includeName: boolean, includeSuburb: boolean): Observable<Job[]> {
    return forkJoin(
      [this.getJobsByAddress(useCache, includeName, includeSuburb),
      this.getAllJobExtras(useCache),
      this.getHouseTypes(),
      this.companyService.getCompanyActivities(false, true),
      this.suburbService.getSuburbs(true)
      ])
      .pipe(map(
        ([jobs]) => {
          return jobs;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }

  getJobsWithBroker(useCache: boolean, includeName: boolean, includeSuburb: boolean): Observable<Job[]> {
    return forkJoin(
      [this.getJobsByAddress(useCache, includeName, includeSuburb),
      this.getAllJobExtras(useCache),
      this.getHouseTypes(),
      this.suburbService.getSuburbs(true),
      this.brokerService.getBrokers(useCache),
      this.estateService.getEstates(useCache),
      this.companyService.getHolidays()
      ])
      .pipe(map(
        ([jobs]) => {
          return jobs;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }

  getMyJobsWithExtras(): Observable<Job[]> {
    return forkJoin(
      [this.getMyJobs(),
      this.companyService.getCompanyActivities(false, true),
      this.getAllJobExtras(false)
      ])
      .pipe(map(
        ([jobs]) => {
          return jobs;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }


  // extra details for hold flag etc
  getAllJobExtras(useCache: boolean): Observable<JobExtra[]> {
    if (useCache && this.jobExtrasCompany === this.globalService.getCurrentCompany().id
      && this.jobExtras && this.jobExtras.length) {
      return of(this.jobExtras);
    } else {
      return this._http.get<JobExtra[]>(this.globalService.getApiUrl() + '/job-extras',
        this.httpService.getHttpOptions()).pipe(
          tap(res => {
            this.jobExtras = res; this.jobExtrasCompany = this.globalService.getCurrentCompany().id;
          }),
          catchError(this.handleError));
    }
  }

  getJobExtras(jobId: number): Observable<JobExtra> {
    return this._http.get<JobExtra>(this.globalService.getApiUrl() + '/job-extras/' + jobId,
      this.httpService.getHttpOptions()).pipe(
        catchError(this.handleError));
  }

  getJobExtrasForBroker(): Observable<JobExtra[]> {
    return this._http.get<JobExtra[]>(this.globalService.getApiUrl() + '/job-extras/for-broker-me',
      this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.jobExtras = res;
        }),
        catchError(this.handleError));
  }


  getHouseTypes(): Observable<HouseType[]> {
    if (this.houseTypesCompany === this.globalService.getCurrentCompany().id
      && this.houseTypes && this.houseTypes.length) {
      return of(this.houseTypes);
    } else {
      return this._http.get<HouseType[]>(this.globalService.getApiUrl() + '/house-types?activeOnly=false',
        this.httpService.getHttpOptions()).pipe(
          tap(res => {
            this.houseTypes = res;
            this.houseTypesCompany = this.globalService.getCurrentCompany().id;
          }),
          catchError(this.handleError));
    }
  }

  getSalesQuotePDF(printImages: boolean, printPrices: boolean,
    printNotApplicable: boolean, printNonPrintItems: boolean, quoteDate: string, printConnectedTogether: boolean,
    printVONumber: boolean, printProvisionalSums: boolean, printWholeAddendum: boolean,
    printItemsNotTaken: boolean, printChangeHistory: boolean,
    printIncludedAmounts: boolean, printSalesQuoteOnly: boolean,
    printPdfAttachments: boolean,
    printFromSalesPriceOnwards: boolean,
    exportToXML: boolean, isPriceGuide: boolean): Observable<IJobItemAttachment> {
    this._jobItemUrl = this.globalService.getApiUrl() + '/jobs/1192/items/sales-pdf';

    if (printImages) {
      this._jobItemUrl += '?printImages=true';
    } else {
      this._jobItemUrl += '?printImages=false';
    }
    if (printPrices) {
      this._jobItemUrl += '&printPrices=true';
    } else {
      this._jobItemUrl += '&printPrices=false';
    }
    if (printNotApplicable) {
      this._jobItemUrl += '&printNotApplicable=true';
    } else {
      this._jobItemUrl += '&printNotApplicable=false';
    }
    if (printNonPrintItems) {
      this._jobItemUrl += '&printNonPrintItems=true';
    } else {
      this._jobItemUrl += '&printNonPrintItems=false';
    }
    if (printConnectedTogether) {
      this._jobItemUrl += '&printConnectedTogether=true';
    } else {
      this._jobItemUrl += '&printConnectedTogether=false';
    }
    if (printVONumber) {
      this._jobItemUrl += '&printVONumber=true';
    } else {
      this._jobItemUrl += '&printVONumber=false';
    }
    if (printProvisionalSums) {
      this._jobItemUrl += '&printProvisionalSums=true';
    } else {
      this._jobItemUrl += '&printProvisionalSums=false';
    }
    if (printWholeAddendum) {
      this._jobItemUrl += '&printWholeAddendum=true';
    } else {
      this._jobItemUrl += '&printWholeAddendum=false';
    }
    if (printItemsNotTaken) {
      this._jobItemUrl += '&printItemsNotTaken=true';
    } else {
      this._jobItemUrl += '&printItemsNotTaken=false';
    }
    if (printChangeHistory) {
      this._jobItemUrl += '&printChangeHistory=true';
    } else {
      this._jobItemUrl += '&printChangeHistory=false';
    }
    if (printIncludedAmounts) {
      this._jobItemUrl += '&printIncludedAmounts=true';
    }
    if (printSalesQuoteOnly) {
      this._jobItemUrl += '&printSalesQuoteOnly=true';
    }
    if (printPdfAttachments) {
      this._jobItemUrl += '&printPdfAttachments=true';
    } else {
      this._jobItemUrl += '&printPdfAttachments=false';
    }
    if (printFromSalesPriceOnwards) {
      this._jobItemUrl += '&printFromSalesOnwards=true';
    } else {
      this._jobItemUrl += '&printFromSalesOnwards=false';
    }
    if (quoteDate) {
      this._jobItemUrl += '&quoteDate=' + quoteDate;
    }
    if (exportToXML) {
      this._jobItemUrl += '&exportToXML=' + exportToXML;
    }
    if (isPriceGuide) {
      this._jobItemUrl += '&isPriceGuide=' + isPriceGuide;
    }

    return this._http.get<IJobItemAttachment>(this._jobItemUrl,
      this.httpService.getHttpFilePDFOptions()).pipe(
        catchError(this.handleError));
  }


  private handleError(err: HttpErrorResponse) {
    console.log(JSON.stringify(err));
    return observableThrowError(err);
  }
}
