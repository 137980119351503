import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable, of, forkJoin } from 'rxjs';
import { mergeMap, map } from 'rxjs/operators';

import { GlobalService } from './global.service';
import { AuthService } from './auth.service';
import { TruthEngineSession } from '../dtos/session';
import { NotificationService } from './notification.service';
import { UserService } from './felixApi/user.service';
import { AuthApiService } from './felixApi/auth-api.service';
import { LogService } from './log.service';
import { environment } from '../../environments/environment';
import { CompanyService } from './felixApi/company.service';

@Injectable()
export class RefreshGuard implements CanActivate {

  constructor(
    private router: Router,
    private authService: AuthService,
    private authApi: AuthApiService,
    private globalService: GlobalService,
    private notiService: NotificationService,
    private compService: CompanyService,
    private userService: UserService,
    private logger: LogService) { }

  canActivate() {
    console.log('canActivate');
    this.authService.currentVersion = environment.fullVersion;
    this.checkCorrectVersion();

    if (this.authService.getUserEmail()) {
      return true;
    } else {
      const session = this.globalService.getSessionObject();
      const email = this.authService.getMsalUserEmail();
      if (session.currentCompanyId && email) {
        return this.restoreFromCache(session, email);
      } else {
        this.router.navigate(['']);
      }
    }
  }

  checkCorrectVersion() {
    this.authApi.getCurrentApplicationVersion().subscribe({
      next: (appVersion) => {
        if (!appVersion || appVersion.version.trim() !== environment.majorVersion) {
          this.authService.versionIncorrect = true;
          this.authService.correctVersion = appVersion.version.trim();
        } else {
          this.authService.versionIncorrect = false;
        }
      },
      error: (err) => {
        this.logger.log('refresh-guard', '', err, 'couldn\'t get app version', true);
      }
    });
  }

  restoreFromCache(session: TruthEngineSession, email: string): Observable<boolean> {
    if (!email) {
      this.authService.signOut();
    } else {
      this.authService.setUserEmail(email);
      return this.authApi.getAuthorisedCompanies().pipe(mergeMap(
        comps => {
          const company = comps.filter(x => x.id === session.currentCompanyId);
          if (company.length !== 1) {
            return of(this.error());
          }
          this.globalService.currentCompany = company[0];
          return forkJoin(
            [
              this.userService.getUser(company[0].userId),
              this.compService.getCompanyConfigs(company[0].userId)
            ]
          ).pipe(map(
            ([user]) => {
              this.authService.setCurrentUser(user);
              this.globalService.userTypeId = user.userTypeId;
              this.globalService.brokerId = user.brokerId;
              this.authService.signIn();
              console.log('restoring');
              this.globalService.setCurrentCompany(company[0]); // need twice so we have the user set
              this.globalService.setAreaSelected(session.areaSelected);
              return true;
            }, () => {
              return this.error();
            }
          ));
        }
      ));
    }
  }

  error() {
    this.router.navigate(['']);
    this.notiService.showError('There was an error fetching data from the session so you will have to login again');
    return false;
  }
}
