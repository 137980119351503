import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalService } from '../../services/global.service';
import { Subscription } from 'rxjs';
import DataSource from 'devextreme/data/data_source';
import { UserService } from '../../services/felixApi/user.service';
import { GridService } from '../../services/grid.service';
import { UserTypeEnum } from '../../dtos/user-type.enum';

@Component({
  selector: 'js-user-maintenance',
  templateUrl: './user-maintenance.component.html',
  styleUrls: ['./user-maintenance.component.scss']
})
export class UserMaintenanceComponent implements OnInit, OnDestroy {
  @Input() userTypeId: number;
  @Input() brokerId: number;
  @Input() developerId: number;
  @Input() titleDescription: string;

  subscriptions: Subscription[] = [];
  gridHeight: number;
  dataSource: DataSource;
  userTypeEnum = UserTypeEnum;

  constructor(
    private _activeModal: NgbActiveModal,
    private userService: UserService,
    protected gridService: GridService,
    private globalService: GlobalService) {
  }


  ngOnInit(): void {
    this.setHeightWidths();
    this.subscriptions = this.subscriptions.concat(
      this.globalService.innerHeightWidthChanged.subscribe(
        () => {
          this.setHeightWidths();
        }
      )
    );

    this.setUpDataSource();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  setHeightWidths() {
    this.gridHeight = window.innerHeight - 300;
  }

  cancel() {
    this._activeModal.dismiss();
  }

  setUpDataSource() {
    this.dataSource = new DataSource({
      key: 'id',
      load: async () => {
        return new Promise((resolve, reject) =>
          this.userService.getUsersForType(this.userTypeId, this.brokerId, this.developerId).subscribe({
            next: (res) => {
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      },
      insert: async (values) => {
        values.brokerId = this.brokerId;
        values.developerId = this.developerId;
        return new Promise((resolve, reject) =>
          this.userService.addUserToComp(values).subscribe({
            next: (res) => {
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      },
      update: async (key, values) => {
        values.id = encodeURIComponent(key);
        return new Promise((resolve, reject) =>
          this.userService.updateUserCompany(values.id, values).subscribe({
            next: (res) => {
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      }
    });
  }

  onInitNewRow(e) {
    e.data.userTypeId = this.userTypeId;
    e.data.isActive = true;
  }
}
