import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { throwError as observableThrowError, Observable, of, forkJoin } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { User } from '../../dtos/user';
import { GlobalService } from '../global.service';
import { HttpService } from '../http.service';
import { AuthService } from '../auth.service';
import { UserTypeEnum } from '../../dtos/user-type.enum';
import { UserBrokerPermission, UserBrokerPermissionEnum } from '../../dtos/user-broker-permission';

@Injectable()
export class UserService {
  userBrokerPermissions: UserBrokerPermission[];
  officeUsers: User[] = [];
  users: User[] = [];

  constructor(
    private http: HttpClient,
    private httpService: HttpService,
    private auth: AuthService,
    private globalService: GlobalService) { }


  getUsersForType(userTypeId: number, brokerId: number, developerId: number): Observable<User[]> {
    if (userTypeId === UserTypeEnum.SalesBroker) {
      const brokerUrl = this.globalService.getApiUrl() + '/companies/users/broker/' + brokerId;
      return this.http.get<User[]>(brokerUrl, this.httpService.getHttpOptions()).pipe(catchError(this.handleError));
    } else {
      let url = this.globalService.getApiUrl() + '/companies/users?userTypeId=' + userTypeId;

      if (brokerId) {
        url += '&brokerId=' + brokerId;
      }

      if (developerId) {
        url += '&developerId=' + developerId;
      }

      return this.http.get<User[]>(url, this.httpService.getHttpOptions()).pipe(catchError(this.handleError));
    }
  }

  getBrokerUsers(brokerId: number): Observable<User[]> {
    const brokerUrl = this.globalService.getApiUrl() + '/user-brokers?brokerId=' + brokerId;
    return this.http.get<User[]>(brokerUrl, this.httpService.getHttpOptions()).pipe(catchError(this.handleError));
  }

  getCurrCompUsers(): Observable<User[]> {
    const url = this.globalService.getApiUrl() + '/companies/users';
    return this.http.get<User[]>(url, this.httpService.getHttpOptions()).pipe(
      tap(res => {
        this.users = res;
        this.officeUsers = res.filter(i => i.userTypeId === UserTypeEnum.Office);
      }),
      catchError(this.handleError));
  }

  getUser(id: number): Observable<User> {
    return this.http.get<User>(
      this.globalService.getApiUrl() + '/users/' + id + '?includeUserType=true',
      this.httpService.getHttpOptions());
  }

  getUserCompany(id: number): Observable<User> {
    return this.http.get<User>(
      this.globalService.getApiUrl() + '/companies/users/' + id,
      this.httpService.getHttpOptions());
  }

  getUserByEmail(email: string) {
    return this.http.get<User>(
      this.globalService.getApiUrl() + '/users/' + email,
      this.httpService.getHttpOptions());
  }


  addUserToComp(user: User): Observable<User> {
    const url = this.globalService.getApiUrl() + '/companies/users/broker-developer';
    return this.http.post<User>(url, JSON.stringify(user), this.httpService.getHttpOptions());
  }

  addOfficeUserToBroker(user: User): Observable<User> {
    const url = this.globalService.getApiUrl() + '/user-brokers';
    return this.http.post<User>(url, JSON.stringify(user), this.httpService.getHttpOptions());
  }

  updateUserCompany(userId: any, user: User): Observable<User> {
    const url = this.globalService.getApiUrl() + '/companies/users/' + userId + '/broker-developer';
    return this.http.patch<User>(url, JSON.stringify(user), this.httpService.getHttpOptions());
  }

  deleteBrokerUser(userId: string, brokerId: number) {
    const url = this.globalService.getApiUrl() + '/user-brokers/' + userId + '?brokerId=' + brokerId;
    return this.http.delete(url, this.httpService.getHttpOptions());
  }


  getBrokerUsersExtras(brokerId: number): Observable<User[]> {
    return forkJoin(
      [
        this.getBrokerUsers(brokerId),
        this.getPermissionsForBrokerUsers(),
        this.getCurrCompUsers()
      ])
      .pipe(map(
        ([data]) => {
          return data;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }


  getPermissionsForBrokerUsers(): Observable<UserBrokerPermission[]> {
    const url = this.globalService.getApiUrl() + '/user-broker-permissions';
    return this.http.get<UserBrokerPermission[]>(url, this.httpService.getHttpOptions()).pipe(
      tap(res => {
        this.userBrokerPermissions = res;
      }),
      catchError(this.handleError));
  }

  getPermissionsForBrokerUser(): Observable<UserBrokerPermission[]> {
    const url = this.globalService.getApiUrl() + '/user-broker-permissions/me';
    return this.http.get<UserBrokerPermission[]>(url, this.httpService.getHttpOptions()).pipe(
      tap(res => {
        this.userBrokerPermissions = res;
      }),
      catchError(this.handleError));
  }

  calculateMapViewPermission(userId: number) {
    const permission = this.userBrokerPermissions
      .find(x => x.userId === userId && x.brokerPermissionId === UserBrokerPermissionEnum.MapView);
    return permission?.noneReadWrite ? true : false;
  }

  calculatePackagesPermission(userId: number) {
    const permission = this.userBrokerPermissions
      .find(x => x.userId === userId && x.brokerPermissionId === UserBrokerPermissionEnum.Packages);
    return permission?.noneReadWrite ? true : false;
  }

  calculateProgressPermission(userId: number) {
    const permission = this.userBrokerPermissions
      .find(x => x.userId === userId && x.brokerPermissionId === UserBrokerPermissionEnum.Progress);
    return permission?.noneReadWrite ? true : false;
  }

  calculateDocumentsPermission(userId: number) {
    const permission = this.userBrokerPermissions
      .find(x => x.userId === userId && x.brokerPermissionId === UserBrokerPermissionEnum.Documents);
    return permission?.noneReadWrite ? true : false;
  }


  private handleError(err: HttpErrorResponse) {
    console.log(JSON.stringify(err));
    return observableThrowError(err);
  }
}
