import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from '../../services/notification.service';
import { Subscription } from 'rxjs';
import { BrokerService } from '../../services/felixApi/broker.service';
import { Logo } from '../../dtos/logo';
import { AttachmentTypeEnum } from '../../dtos/attachment-type.enum';
import { UtilsService } from '../../services/utils.service';

@Component({
  selector: 'js-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss']
})
export class LogoComponent implements OnInit, OnDestroy {
  @Input() userTypeId: number;
  @Input() brokerId: number;
  @Input() developerId: number;
  @Input() titleDescription: string;


  subscriptions: Subscription[] = [];
  loading = true;
  imageFound: boolean;
  logoDto: Logo;
  wrongFileType = false
  filesToUpload: File[] = [];
  attachmentTypeEnum = AttachmentTypeEnum;

  constructor(
    private _activeModal: NgbActiveModal,
    private brokerService: BrokerService,
    private notiService: NotificationService,
    private utilsService: UtilsService) { }


  ngOnInit(): void {
    this.getLogo();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  cancel() {
    this._activeModal.dismiss();
  }

  getLogo() {
    this.imageFound = false;
    this.loading = true;

    this.subscriptions = this.subscriptions.concat(
      this.brokerService.getBrokerLogo(this.brokerId)
        .subscribe({
          next: (logoDto) => {
            this.logoDto = logoDto;
            this.imageFound = true;
            this.loading = false;
          },
          error: () => {
            this.loading = false;
          }
        })
    );
  }


  handleFileInput(eventRecord) {
    for (let i = 0; i < eventRecord.value.length; i++) {
      const fileType = this.utilsService.getFileType(eventRecord.value[i].name);

      if (!fileType
        || (fileType !== this.attachmentTypeEnum.JPEG
          && fileType !== this.attachmentTypeEnum.JPG
          && fileType !== this.attachmentTypeEnum.PNG)) {
        this.wrongFileType = true;
        return;
      }
    }
    this.wrongFileType = false;
    this.filesToUpload = eventRecord.value;
  }

  saveLogo() {
    this.loading = true;
    const fileList = this.filesToUpload;
    const image: File = fileList[0];
    const form = this.utilsService.fileToFormData(image);

    this.subscriptions = this.subscriptions.concat(
      this.brokerService.addLogo(this.brokerId, form).subscribe({
        next: () => {
          this.getLogo();
        }, error: (err) => {
          this.notiService.notify(err);
          this.loading = false;
        }
      })
    );
  }
}
