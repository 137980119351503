import { NotificationService } from './../../services/notification.service';
import { GlobalService } from '../../services/global.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import { Subscription } from 'rxjs';
import { LandService } from '../../services/felixApi/land.service';
import { LandField } from '../../dtos/land-field';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GridService } from '../../services/grid.service';
import { LandFieldTypeEnum } from '../../dtos/land-field-type.enum';
import { LandFieldLookupComponent } from '../land-field-lookup/land-field-lookup.component';

@Component({
  selector: 'js-land-fields',
  templateUrl: './land-fields.component.html',
  styleUrls: ['./land-fields.component.scss']
})
export class LandFieldsComponent implements OnInit, OnDestroy {

  subscriptions: Subscription[] = [];
  gridHeight: number;
  landFields: LandField[] = [];
  dataSource: DataSource;
  trackingFieldTypes: { id: number; description: string; }[] = [];


  constructor(
    private landService: LandService,
    private globalService: GlobalService,
    protected gridService: GridService,
    private notiService: NotificationService,
    private modalService: NgbModal) {
  }

  ngOnInit() {
    this.gridHeight = window.innerHeight - 120;
    this.trackingFieldTypes = [
      { id: 1, description: 'Yes/No' },
      { id: 2, description: 'Number' },
      { id: 3, description: 'Text' },
      { id: 4, description: 'Date' },
      { id: 6, description: 'Lookup' }
    ];
    this.setUpDataSource();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  setUpDataSource() {
    this.dataSource = new DataSource({
      key: 'id',
      load: async () => {
        return new Promise((resolve, reject) =>
          this.landService.getLandFields(false).subscribe({
            next: (res) => {
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      },
      insert: async (values) => {
        return new Promise((resolve, reject) =>
          this.landService.addLandField(values).subscribe({
            next: (res) => {
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      },
      update: async (key, values) => {
        return new Promise((resolve, reject) =>
          this.landService.updateLandField(encodeURIComponent(key), values).subscribe({
            next: (res) => {
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      },
      remove: async (key) => {
        return new Promise((resolve, reject) =>
          this.landService.deleteLandField(encodeURIComponent(key)).subscribe({
            next: () => {
              return resolve();
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      }
    });
  }

  onInitNewRow(e) {
    e.data.isActive = true;
  }

  onEditorPreparing(e: any) {
    if (e.parentType !== 'dataRow') {
      return;
    } else {
      if (e.dataField === 'fieldName') {
        e.editorName = 'dxTextArea';
        e.editorOptions.autoResizeEnabled = true;
        let prevHeight = null;
        e.editorOptions.onInput = (args) => {
          const td = args.element.closest('td');
          if (td && prevHeight !== td.offsetHeight) {
            const overlay = e.element.querySelector('.dx-datagrid-focus-overlay');
            if (overlay != null) {
              overlay.style.height = (td.offsetHeight + 1) + 'px';
            }
            prevHeight = td.offsetHeight;
          }
        };
      }
    }
  }

  editList(e) {
    if (e.data.trackingFieldTypeId !== LandFieldTypeEnum.Lookup) {
      this.notiService.showInfo('List entries are only applicable if the field type is Lookup');
    } else {
      const modalRef = this.modalService.open(LandFieldLookupComponent);
      modalRef.componentInstance.landFieldId = e.data.id;
      modalRef.componentInstance.landFieldName = e.data.fieldName;

      modalRef.result.then(() => { }, () => { });
    }
  }
}
