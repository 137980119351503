<div class="page">
  <js-loading-spinner *ngIf="loadingData" [usePopUp]="false"></js-loading-spinner>

  <dx-data-grid *ngIf="!loadingData" #landDatabase id="landDatabase" class="m-auto" [height]="gridHeight"
    [dataSource]="dataSource" columnResizingMode="widget" [columns]='gridColumns' allowColumnReordering="true"
    allowColumnResizing="true" columnAutoWidth="true" columnRenderingMode="virtual" remoteOperations="false"
    showRowLines="true" showBorders="true" wordWrapEnabled="true" filterSyncEnabled="true"
    (onInitNewRow)="initNewRow($event)" (onOptionChanged)="gridOptionChanged($event)"
    (onToolbarPreparing)="onToolbarPreparing($event)" (onRowPrepared)="onRowPrepared($event)"
    (onExporting)="gridService.onExporting($event, 'Land-Database')" (onSaved)="getLandMasters(false)">

    <dxo-scrolling mode="standard"></dxo-scrolling>
    <dxo-export [enabled]="true"></dxo-export>
    <dxo-search-panel [visible]="true" location="before" width="150px"></dxo-search-panel>
    <dxo-sorting mode='multiple'></dxo-sorting>
    <dxo-column-chooser [enabled]="true" [search]="{enabled: true}" [height]="500" [width]="400"></dxo-column-chooser>

    <dxo-paging [enabled]="false"></dxo-paging>
    <dxo-filter-panel [visible]="true"></dxo-filter-panel>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-header-filter [visible]="true"></dxo-header-filter>

    <dxo-editing mode="batch" refreshMode="full" [allowAdding]="landAdmin" [allowUpdating]="landAdmin"
      [allowDeleting]="landAdmin" [useIcons]="true"></dxo-editing>

    <dxo-state-storing [enabled]="true" type="localStorage" storageKey="land-database" [savingTimeout]="200">
    </dxo-state-storing>

    <!-- Templates -->
    <dxo-summary>
      <dxi-total-item column="landNumber" summaryType="count" displayFormat="{0} records">
      </dxi-total-item>
      <dxi-total-item column="price" summaryType="sum" displayFormat="{0}" valueFormat="#,##0">
      </dxi-total-item>
    </dxo-summary>
  </dx-data-grid>
</div>
