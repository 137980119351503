import { BrokerService } from './../services/felixApi/broker.service';
import { GlobalService } from './../services/global.service';
import { JobService } from './../services/felixApi/job.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import { Subscription } from 'rxjs';
import { Job } from '../dtos/job';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DownloadPackageComponent } from '../download-package/download-package.component';
import { Broker } from '../dtos/broker';
import { AllocatePackageComponent } from './allocate-package/allocate-package.component';
import { OnHoldStatusTypeEnum } from '../dtos/onHoldStatusTypeEnum';
import { BrokerJob } from '../dtos/broker-job';
import { NotificationService } from '../services/notification.service';
import { ApproveHoldComponent } from './approve-hold/approve-hold.component';
import { DeclineModalComponent } from './decline-modal/decline-modal.component';
import { UtilsService } from '../services/utils.service';
import { SuburbService } from '../services/felixApi/suburb.service';
import { EstateService } from '../services/felixApi/estate.service';
import { CompanyService } from '../services/felixApi/company.service';
import { UserTypeEnum } from '../dtos/user-type.enum';
import { AuthService } from '../services/auth.service';
import { GridService } from '../services/grid.service';

@Component({
  selector: 'js-builder-view',
  templateUrl: './builder-view.component.html',
  styleUrls: ['./builder-view.component.scss']
})
export class BuilderViewComponent implements OnInit, OnDestroy {

  subscriptions: Subscription[] = [];
  loadingData = true;
  gridHeight: number;
  jobs: Job[];
  dataSource: DataSource;
  showSold = false;
  brokers: Broker[];
  selectedRecord: any;
  onHoldStatusTypeEnum = OnHoldStatusTypeEnum;
  phoneSize: boolean;
  buttonColumnWidth: number;
  onHoldStatusTypes: any[];
  brokerJobs: BrokerJob[];
  packagesAdmin: boolean;

  constructor(
    private jobService: JobService,
    private globalService: GlobalService,
    private brokerService: BrokerService,
    private notiService: NotificationService,
    private suburbService: SuburbService,
    private estateService: EstateService,
    private utilService: UtilsService,
    private compService: CompanyService,
    private authService: AuthService,
    protected gridService: GridService,
    private modalService: NgbModal) {
    this.calcAddress = this.calcAddress.bind(this);
    this.approveHold = this.approveHold.bind(this);
    this.declineHold = this.declineHold.bind(this);
    this.downloadPackage = this.downloadPackage.bind(this);
    this.onCellPrepared = this.onCellPrepared.bind(this);
    this.isApproveButtonVisible = this.isApproveButtonVisible.bind(this);
    this.isDeclineButtonVisible = this.isDeclineButtonVisible.bind(this);
    this.isDownloadButtonVisible = this.isDownloadButtonVisible.bind(this);
    this.calculateBrokerSortValue = this.calculateBrokerSortValue.bind(this);
    this.calcHasCoordinates = this.calcHasCoordinates.bind(this);
    this.setCommissionCost = this.setCommissionCost.bind(this);
    this.setCommissionMarkUp = this.setCommissionMarkUp.bind(this);
    this.setCommissionGST = this.setCommissionGST.bind(this);
    this.setCommissionIncGST = this.setCommissionIncGST.bind(this);
  }

  ngOnInit() {
    this.onHoldStatusTypes = [
      { id: 1, description: 'Available' },
      { id: 2, description: 'Unavailable' },
      { id: 3, description: 'Approved' },
      { id: 4, description: 'Pending' },
      { id: 9, description: 'Sold' }
    ];

    this.setHeightWidths();

    this.subscriptions.push(
      this.globalService.innerHeightWidthChanged.subscribe(
        () => {
          setTimeout(() => {
            this.setHeightWidths();
          }, 200); // wait for iPhone and grid
        }
      )
    );

    this.globalService.setAreaSelected('builder-view');
    this.packagesAdmin = this.globalService.packagesAdmin;
    this.getJobs();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  setHeightWidths() {
    this.gridHeight = window.innerHeight - 70;

    if (window.innerWidth > 800) {
      this.phoneSize = false;
      this.buttonColumnWidth = 140;
    } else {
      this.buttonColumnWidth = 70;
      this.phoneSize = true;
    }
  }

  getJobs() {
    this.loadingData = true;
    this.subscriptions = this.subscriptions.concat(
      this.jobService.getJobsWithBroker(false, false, false)
        .subscribe({
          next: (jobs) => {
            this.jobs = jobs;
            this.brokers = this.brokerService.brokers;
            this.getBrokerJobs(this.showSold);
          },
          error: (error) => {
            this.notiService.showError(error);
            this.loadingData = false;
          }
        })
    );
  }

  getBrokerJobs(showSold: boolean) {
    this.loadingData = true;
    this.subscriptions = this.subscriptions.concat(
      this.brokerService.getBrokerJobs(showSold, null)
        .subscribe({
          next: (brokerJobs) => {
            this.brokerJobs = brokerJobs;
            this.loadingData = false;
            this.setUpDataSource();
          },
          error: (error) => {
            this.notiService.showError(error);
            this.loadingData = false;
          }
        })
    );
  }

  setUpDataSource() {
    this.dataSource = new DataSource({
      key: 'id',
      load: () => this.brokerJobs,
      update: async (key, values) => {
        return new Promise((resolve, reject) =>
          this.brokerService.updateBrokerJob(encodeURIComponent(key), values).subscribe({
            next: (res) => {
              const brokerJob = this.brokerJobs.find(i => i.id === key);
              if (values.commissionCost !== undefined) {
                brokerJob.commissionCost = values.commissionCost;
              }
              if (values.commissionMarkUp !== undefined) {
                brokerJob.commissionMarkUp = values.commissionMarkUp;
              }
              if (values.commissionGST !== undefined) {
                brokerJob.commissionGST = values.commissionGST;
              }
              if (values.commissionIncGST !== undefined) {
                brokerJob.commissionIncGST = values.commissionIncGST;
              }
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      }
    });
  }

  calcAddress(data) {
    return this.jobs.find(i => i.id === data.jobId)?.jobAddressString;
  }

  calcHasCoordinates(data: BrokerJob): string {
    if (data.job) {
      if (data.job?.jobAddress?.longitude && data.job?.jobAddress?.latitude) {
        return 'Set';
      }

      if (this.estateService.estateHasLocation(data.job.estateMasterId)) {
        return 'Estate';
      } else if (this.suburbService.suburbHasLocation(data.job)) {
        return 'Suburb';
      }
      return 'Not Set';
    }
    return '';
  }

  calculateTotalPackage(data) {
    return data.landPrice + data.salesQuotePrice + (data.commissionIncGST ?? 0);
  }

  onSelectionChanged(event) {
    if (event?.selectedRowKeys?.length > 0) {
      this.jobService.setCurrentJob(event.selectedRowsData[0].job.jobNumber);
    }
  }

  downloadPackage(e) {
    const modalRef = this.modalService.open(DownloadPackageComponent);
    modalRef.componentInstance.brokerJob = e.row.data;

    modalRef.result.then(() => { });
  }

  onToolbarPreparing(e) {
    const toolbarItems = e.toolbarOptions.items;

    toolbarItems.unshift(
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxCheckBox',
        options: {
          text: 'Show Sold',
          value: this.showSold,
          rtlEnabled: true,
          onValueChanged: this.showSoldChanged.bind(this)
        }
      },
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          onClick: this.getJobs.bind(this)
        }
      },
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          type: 'default',
          text: 'Allocate Packages',
          onClick: this.addBrokersForJob.bind(this)
        }
      });
  }

  isApproveButtonVisible(e) {
    if (e.row.data.onHoldStatusTypeId === OnHoldStatusTypeEnum.WaitingApproval
      || e.row.data.onHoldStatusTypeId === OnHoldStatusTypeEnum.Available
      || e.row.data.onHoldStatusTypeId === OnHoldStatusTypeEnum.Approved
      || e.row.data.onHoldStatusTypeId === OnHoldStatusTypeEnum.Sold) {
      return true;
    }
    return false;
  }

  isDeclineButtonVisible(e) {
    if (e.row.data.onHoldStatusTypeId === OnHoldStatusTypeEnum.WaitingApproval
      || (e.row.data.onHoldStatusTypeId === OnHoldStatusTypeEnum.Approved)) {
      return true;
    }
    return false;
  }

  isDownloadButtonVisible(e) {
    if (e.row.data.onHoldStatusTypeId === OnHoldStatusTypeEnum.Approved && !this.phoneSize) {
      return true;
    }
    return false;
  }

  showSoldChanged() {
    this.showSold = !this.showSold;
    this.getBrokerJobs(this.showSold);
  }

  addBrokersForJob() {
    const modalRef = this.modalService.open(AllocatePackageComponent, { windowClass: 'modal-1000' });

    modalRef.result.then(() => {
      this.selectedRecord = null;
      this.getBrokerJobs(this.showSold);
    }, () => { });
  }

  approveHold(e) {
    const modalRef = this.modalService.open(ApproveHoldComponent);
    modalRef.componentInstance.brokerJob = e.row.data;

    modalRef.result.then(() => {
      this.getBrokerJobs(this.showSold);
    }, () => { });
  }

  declineHold(e) {
    const modalRef = this.modalService.open(DeclineModalComponent);
    modalRef.componentInstance.brokerJob = e.row.data;

    modalRef.result.then(() => {
      this.getBrokerJobs(this.showSold);
    }, () => { });
  }

  onCellPrepared(e) {
    if (e.rowType === 'data') {
      if (e.data.expiryDate && (e.column.dataField === 'expiryDate')
        && this.utilService.convertDateToString(e.data.expiryDate) <= this.utilService.convertDateToString(new Date())) {
        e.cellElement.style.color = 'rgb(240, 0, 0)'; // red
      }

      if (e.column.dataField === 'onHoldStatusTypeId') {
        if (e.data.onHoldStatusTypeId === OnHoldStatusTypeEnum.WaitingApproval) {
          e.cellElement.style.color = 'rgb(240, 0, 0)';
        }
      }

      if (e.column.dataField === 'hasCoordinates') {
        if (this.calcHasCoordinates(e.data) === 'Not Set') {
          e.cellElement.style.color = 'rgb(240, 0, 0)';
        }
      }
    }
  }

  calculateBrokerSortValue(data) {
    return this.brokers.find(i => i.id === data?.brokerId)?.name;
  }

  setCommissionCost(rowData: BrokerJob, value, originalData) {
    rowData.commissionCost = value;

    const markup = rowData.commissionMarkUp === undefined ? originalData.commissionMarkUp : rowData.commissionMarkUp;
    const gstPercent = rowData.commissionGST === undefined ? originalData.commissionGST : rowData.commissionGST;

    if (markup && gstPercent && value) {
      rowData.commissionIncGST = Math.round((value * (1 + markup / 100) * (1 + gstPercent / 100)) * 100) / 100;
    } else {
      rowData.commissionIncGST = 0;
    }
  }

  setCommissionMarkUp(rowData: BrokerJob, value, originalData) {
    rowData.commissionMarkUp = value;

    const commissionCost = rowData.commissionCost === undefined ? originalData.commissionCost : rowData.commissionCost;
    const gstPercent = rowData.commissionGST === undefined ? originalData.commissionGST : rowData.commissionGST;

    if (commissionCost && gstPercent && value) {
      rowData.commissionIncGST = Math.round((commissionCost * (1 + rowData.commissionMarkUp / 100) * (1 + gstPercent / 100)) * 100) / 100;
    } else {
      rowData.commissionIncGST = 0;
    }
  }

  setCommissionGST(rowData: BrokerJob, value, originalData) {
    rowData.commissionGST = value;

    const commissionCost = rowData.commissionCost === undefined ? originalData.commissionCost : rowData.commissionCost;
    const markup = rowData.commissionMarkUp === undefined ? originalData.commissionMarkUp : rowData.commissionMarkUp;

    if (commissionCost && markup && value) {
      rowData.commissionIncGST = Math.round((commissionCost * (1 + markup / 100) * (1 + rowData.commissionGST / 100)) * 100) / 100;
    } else {
      rowData.commissionIncGST = 0;
    }
  }

  setCommissionIncGST(rowData: BrokerJob, value, originalData) {
    rowData.commissionIncGST = value;

    const markup = rowData.commissionMarkUp === undefined ? originalData.commissionMarkUp : rowData.commissionMarkUp;
    const gstPercent = rowData.commissionGST === undefined ? originalData.commissionGST : rowData.commissionGST;

    if (markup && gstPercent && value) {
      rowData.commissionCost = Math.round((value / (1 + gstPercent / 100) / (1 + markup / 100)) * 100) / 100;
    } else {
      rowData.commissionCost = 0;
    }
  }
}
