import { LogService } from './../services/log.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { UserTypeEnum } from '../dtos/user-type.enum';
import { Company } from '../dtos/company';
import { User } from '../dtos/user';
import { AuthService } from '../services/auth.service';
import { GlobalService } from '../services/global.service';
import { UserService } from '../services/felixApi/user.service';
import { NotificationService } from '../services/notification.service';
import { CompanyService } from '../services/felixApi/company.service';
import { AuthApiService } from '../services/felixApi/auth-api.service';


@Component({
  selector: 'js-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit, OnDestroy {

  COMPONENT_NAME = 'welcome';
  userTypeEnum = UserTypeEnum;
  userCompanies: Company[];
  currComp: Company;
  currUser: User;
  currUserEmail: string;
  multipleCompanies = false;
  admin = false;
  loading = true;
  innerWidth: number;
  compForm: UntypedFormGroup;
  subscriptions: Subscription[] = [];
  officeUser = false;
  currentVersion: string;


  constructor(
    private router: Router,
    private userService: UserService,
    public globalService: GlobalService,
    private notiService: NotificationService,
    private authService: AuthService,
    private authApi: AuthApiService,
    private compService: CompanyService,
    private logService: LogService) {

    this.subscriptions.push(
      this.globalService.innerHeightWidthChanged.subscribe(() => {
        this.innerWidth = window.innerWidth;
      })
    );
  }

  ngOnInit() {
    console.log('welcome');
    this.innerWidth = window.innerWidth;
    this.globalService.setAreaSelected('');

    this.currentVersion = this.authService.currentVersion;

    this.currComp = this.globalService.getCurrentCompany();
    this.currUserEmail = this.authService.getUserEmail();

    if (!this.currUserEmail) {
      this.authService.signOut();
    } else {
      this.currUser = this.authService.getCurrentUser();
      if ((this.currComp && this.currComp.userTypeId === this.userTypeEnum.Admin) || (this.currUser && this.currUser.isSuperUser)) {
        this.admin = true;
        this.officeUser = true;
      } else if (this.currComp && this.currComp.userTypeId === this.userTypeEnum.Office) {
        this.officeUser = true;
      }

      this.getCompanies();
    }
  }

  getCompanies() {
    this.userCompanies = this.compService.getCurrentCompanies();
    if (this.userCompanies) {
      this.populateCompanies();
    } else {
      if (!this.authService.getUserEmail()) {
        this.signOut();
      } else {
        this.subscriptions.push(
          this.authApi.getAuthorisedCompanies().subscribe({
            next: (companies) => {
              this.userCompanies = companies;
              this.populateCompanies();
            },
            error: (err) => {
              this.notiService.notify(err);
              this.currUser = null;
              this.loading = false;
            }
          })
        );
      }
    }
  }

  populateCompanies() {
    // console.log('populateCompanies');
    this.compService.setCurrentCompanies(this.userCompanies);
    this.loading = false;
    if (this.userCompanies.length === 1) {
      this.multipleCompanies = false;
      this.currComp = this.userCompanies[0];
      if (!this.authService.signedIn) {
        this.compSelected();
      }
    } else {
      // check for a passed company
      const session = this.globalService.getSessionObject();

      if (session.passedCompanyId) {
        const thisCompany = this.userCompanies.filter(i => i.id === +session.passedCompanyId);
        if (thisCompany.length) {
          this.currComp = thisCompany[0];
          this.multipleCompanies = false;
          this.globalService.setSessionAtt('passedCompanyId', ''); // reset so we can go back to welcome page
          this.compSelected();
        } else {
          this.multipleCompanies = true;
        }
      } else {
        this.multipleCompanies = true;
      }
    }
  }

  compSelected() {
    this.loading = true;
    this.globalService.menuDisable.emit(true);
    this.globalService.setCurrentCompany(this.currComp);

    // get the user master - only have all user info once company is selected
    // only then do we fully sign in
    this.subscriptions.push(
      this.userService.getUser(this.currComp.userId).subscribe({
        next: (currUser) => {
          this.currUser = currUser;
          this.authService.setCurrentUser(this.currUser);
          this.authService.signIn();
          if (this.currComp.userTypeId === this.userTypeEnum.Admin) {
            this.admin = true;
          }

          this.globalService.userTypeId = this.currUser.userTypeId;
          this.globalService.brokerId = this.currUser.brokerId;

          this.globalService.menuDisable.emit(false);
          this.globalService.setCurrentCompany(this.currComp); // do twice so we have the user set
          this.writeLoginLog();
        },
        error: (err) => {
          this.notiService.notify(err);
          this.currUser = null;
          this.globalService.menuDisable.emit(false);
          this.loading = false;
        }
      })
    );
  }

  writeLoginLog() {
    this.subscriptions.push(
      this.logService.loginLog(this.authService.getUserEmail(), true, 'Company ' + this.currComp.id.toString()).subscribe({
        next: () => {
          this.routeToStart();
        },
        error: (err) => {
          this.notiService.notify(err);
          this.routeToStart();
        }
      })
    );
  }

  routeToStart() {
    if (this.currUser.userTypeId === UserTypeEnum.SalesBroker || this.currUser.userTypeId === UserTypeEnum.Office) {
      // get the users permissions
      this.subscriptions.push(
        this.userService.getPermissionsForBrokerUser().subscribe({
          next: () => {
            this.currUser.mapViewPermission = this.userService.calculateMapViewPermission(this.currUser.id);
            this.currUser.packagesPermission = this.userService.calculatePackagesPermission(this.currUser.id);
            this.currUser.progressPermission = this.userService.calculateProgressPermission(this.currUser.id);
            this.currUser.documentsPermission = this.userService.calculateDocumentsPermission(this.currUser.id);
            this.currUser.brokerId = this.userService.userBrokerPermissions[0].brokerId;
            this.authService.setCurrentUser(this.currUser);

            if (this.currUser.packagesPermission) {
              this.router.navigateByUrl('broker-view');
            } else if (this.currUser.progressPermission) {
              this.router.navigateByUrl('report');
            } else if (this.currUser.documentsPermission) {
              this.router.navigateByUrl('job-documents');
            } else if (this.currUser.mapViewPermission) {
              this.router.navigateByUrl('map-view');
            } else {
              this.notiService.showError('You do not have permission to access any areas.');
              this.currUser = null;
              this.globalService.menuDisable.emit(false);
              this.loading = false;
            }
          },
          error: (err) => {
            this.notiService.notify(err);
            this.currUser = null;
            this.globalService.menuDisable.emit(false);
            this.loading = false;
          }
        })
      );
    } else if (this.currUser.userTypeId === UserTypeEnum.Developer) {
      this.router.navigateByUrl('developer-view');
    } else if (this.currUser.userTypeId === UserTypeEnum.Admin || this.currUser.userTypeId === UserTypeEnum.SuperUser) {
      this.router.navigateByUrl('builder-view');
    } else {
      this.notiService.showError('You do not have permission to access any areas.');
      this.currUser = null;
      this.globalService.menuDisable.emit(false);
      this.loading = false;
    }
  }

  compareComps(comp1: Company, comp2: Company) {
    if (!comp1 || !comp2) {
      return false;
    } else {
      return comp1.id === comp2.id;
    }
  }

  signOut() {
    this.loading = true;
    this.authService.signOut();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  clearCache() {
    localStorage.clear();
    this.notiService.showInfo('Cache cleared.');
    setTimeout(() => {
      this.signOut();
    }, 5000);
  }
}
