import { NotificationService } from './../../services/notification.service';
import { PackageControl } from './../../dtos/package-control';
import { PackageControlService } from './../../services/felixApi/package-control.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'js-package-control',
  templateUrl: './package-control.component.html',
  styleUrls: ['./package-control.component.scss']
})
export class PackageControlComponent implements OnInit, OnDestroy {

  subscriptions: Subscription[] = [];
  loading = true;
  packageControl: PackageControl;
  packageControlForm: PackageControl;
  updatedData: any;

  constructor(
    private notiService: NotificationService,
    private packageControlService: PackageControlService) { }

  ngOnInit() {
    this.getData();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  getData() {
    this.subscriptions = this.subscriptions.concat(
      this.packageControlService.getPackageControl()
        .subscribe({
          next: (packageControl) => {
            this.packageControl = packageControl;

            if (this.packageControl) {
              this.packageControlForm = JSON.parse(JSON.stringify(this.packageControl));;
            } else {
              this.packageControlForm = new PackageControl;
            }

            this.loading = false;
          },
          error: () => {
            this.loading = false;
          }
        })
    );
  }

  update() {
    if (this.packageControl) {
      // edit
      this.updatedData = {};
      let isItemsToUpdate = false;

      if (this.packageControlForm.requestEmail !== this.packageControl.requestEmail) {
        this.updatedData.requestEmail = this.packageControlForm.requestEmail;
        isItemsToUpdate = true;
      }

      if (this.packageControlForm.expiryEmail !== this.packageControl.expiryEmail) {
        this.updatedData.expiryEmail = this.packageControlForm.expiryEmail;
        isItemsToUpdate = true;
      }

      if (this.packageControlForm.declineText !== this.packageControl.declineText) {
        this.updatedData.declineText = this.packageControlForm.declineText;
        isItemsToUpdate = true;
      }


      if (isItemsToUpdate) {
        this.loading = true;

        this.subscriptions.push(
          this.packageControlService.updatePackageControl(this.updatedData)
            .subscribe({
              next: () => {
                this.getData();
              },
              error: (err) => {
                this.notiService.notify(err);
                this.loading = false;
              }
            })
        );
      }
    } else {
      // add
      this.loading = true;

      this.subscriptions.push(
        this.packageControlService.addPackageControl(this.packageControlForm)
          .subscribe({
            next: () => {
              this.getData();
            },
            error: (err) => {
              this.notiService.notify(err);
              this.loading = false;
            }
          })
      );
    }
  }
}
