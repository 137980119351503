import { Injectable, EventEmitter } from '@angular/core';
import { HttpHeaders, HttpClient, HttpErrorResponse } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { Company } from '../dtos/company';
import { User } from '../dtos/user';
import { UserTypeEnum } from '../dtos/user-type.enum';
import { MsalService } from '@azure/msal-angular';
import { CompanyConfiguration } from '../dtos/company-configuration';
import { TruthEngineSession, SessionName } from '../dtos/session';
import { Job } from '../dtos/job';
import { AttachmentTypeEnum } from '../dtos/attachment-type.enum';
import { throwError as observableThrowError } from 'rxjs';


@Injectable({
  providedIn: 'root',
})
export class GlobalService {

  currentCompany: Company;

  private areaSelected = '';
  areaChanged = new EventEmitter<string>();

  menuDisable = new EventEmitter<boolean>();

  innerHeightWidthChanged = new EventEmitter();

  companySelected = new EventEmitter<Company>();

  // public companyConfiguration: CompanyConfiguration[];
  invoicePermission: string;
  externalInvoiceId: string;
  canSeeAdminReports: boolean;
  ordersPermWrite: boolean;
  selectedCostCentreId: number;
  jobstring: string;
  blob: Blob;
  brokerId: number;
  userTypeId: number;
  globalGSTRate: number;
  packagesAdmin: boolean;
  isBroker: boolean;
  isDeveloper: boolean;
  isOfficeUser: boolean;
  canSeeMaintenance: boolean;
  landAdmin: boolean;
  packagesWrite: boolean;
  landRead: boolean;

  constructor() { }

  isHttps() {
    return window.location.protocol.startsWith('https') || window.location.hostname === 'localhost';
  }

  getApiUrl(): string {
    return environment.apiUrl;
  }

  getAppID() {
    return environment.appId;
  }

  setInnerHeightWidth() {
    this.innerHeightWidthChanged.emit();
  }

  setAreaSelected(area) {
    this.areaSelected = area;
    this.setSessionAtt('areaSelected', area);
    if (area && area !== '') {
      localStorage.setItem('truthEngineCostingArea', area);
    }
    this.areaChanged.emit(this.areaSelected);
  }

  setCurrentCompany(company: Company) {
    this.currentCompany = company;
    this.setSessionAtt('currentCompanyId', company.id);
    this.companySelected.emit(this.currentCompany);
  }

  getCurrentCompany(): Company {
    return this.currentCompany;
  }

  getAddendumName(): string {
    return this.currentCompany && this.currentCompany.addendumName && this.currentCompany.addendumName !== ''
      ? this.currentCompany.addendumName : 'Addendum';
  }

  getAddendumLetter(): string {
    return this.currentCompany && this.currentCompany.addendumName && this.currentCompany.addendumName !== ''
      ? this.currentCompany.addendumName[0] : 'A';
  }

  clearCurrentCompany() {
    this.currentCompany = null;
  }

  isCompSet() {
    if (this.currentCompany) {
      return true;
    } else {
      return false;
    }
  }

  getCurrentCompanyId(): string {
    if (this.currentCompany !== undefined && this.currentCompany.id !== undefined && this.currentCompany.id !== null) {
      return this.currentCompany.id.toString();
    }
    return '';
  }

  isSalesVariationsActive() {
    return this.currentCompany.salesVariationsActive;
  }

  getSessionObject(): TruthEngineSession {
    if (sessionStorage.getItem(SessionName)) {
      return JSON.parse(sessionStorage.getItem(SessionName));
    } else {
      const session = new TruthEngineSession();
      sessionStorage.setItem(SessionName, JSON.stringify(session));
      return JSON.parse(sessionStorage.getItem(SessionName));
    }
  }

  setSessionAtt(att: string, obj: any) {
    const session = this.getSessionObject();
    session[att] = obj;
    const sessionString = JSON.stringify(session);
    sessionStorage.setItem(SessionName, sessionString);
  }

  getJobString(job: Job, includeName: boolean, includeSuburb: boolean): string {
    this.jobstring = '';
    if (job?.contractName && includeName) {
      this.jobstring = job.contractName.trim() + ': ';
    }
    if (job?.jobAddress) {
      if (job.jobAddress.lotNumber && job.jobAddress.lotNumber.trim() !== '') {
        this.jobstring = this.jobstring.concat('Lot ' + job.jobAddress.lotNumber.trim());
      }
      if (job.jobAddress.streetNumber && job.jobAddress.streetNumber.trim() !== '') {
        this.jobstring = this.jobstring.concat(' #' + job.jobAddress.streetNumber.trim());
      }
      if (job.jobAddress.streetName1 && job.jobAddress.streetName1.trim() !== '') {
        this.jobstring = this.jobstring.concat(' ' + job.jobAddress.streetName1.trim());
      }
      if (job.jobAddress.streetName2 && job.jobAddress.streetName2.trim() !== '') {
        this.jobstring = this.jobstring.concat(' ' + job.jobAddress.streetName2.trim());
      }
      if (includeSuburb && job.jobAddress.suburbTown && job.jobAddress.suburbTown.trim() !== '') {
        this.jobstring = this.jobstring.concat(' ' + job.jobAddress.suburbTown.trim());
      }
      if (includeSuburb && job.jobAddress.postCode && job.jobAddress.postCode.trim() !== '') {
        this.jobstring = this.jobstring.concat(' ' + job.jobAddress.postCode.trim());
      }
    }
    return this.jobstring;
  }

  sortBy(name, minor, isAscending = true) {
    const reverseMutliplier = isAscending ? 1 : -1;
    return function (o, p) {
      let a, b;
      let result;
      a = o[name];
      b = p[name];
      if (a === b) {
        return typeof minor === 'function' ? minor(o, p) : 0;
      }
      if (typeof a === typeof b) {
        result = a < b ? -1 : 1;
      } else {
        result = typeof a < typeof b ? -1 : 1;
      }
      return result * reverseMutliplier;
    };
  }

  returnError(err: any): string {
    console.log(JSON.stringify(err));
    if (err && err.error && err.error.message) {
      return JSON.stringify(err.error.message);
    } else if (err && err.error && err.error.detail) {
      return JSON.stringify(err.error.detail);
    } else {
      return JSON.stringify(err);
    }
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  getBlobFromBase64(base64: any, attachmentTypeId: number): Blob {
    if (attachmentTypeId === AttachmentTypeEnum.JPEG || attachmentTypeId === AttachmentTypeEnum.PNG) {
      return base64;
    }
    const binary_string = window.atob(base64);
    const len = binary_string.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }

    if (attachmentTypeId === AttachmentTypeEnum.XLS
      || attachmentTypeId === AttachmentTypeEnum.XLSM
      || attachmentTypeId === AttachmentTypeEnum.XLSX) {
      return new Blob([bytes], { type: 'application/vnd.ms-excel' });
    } else if (attachmentTypeId === AttachmentTypeEnum.PDF) {
      return new Blob([bytes], { type: 'application/pdf' });
    } else if (attachmentTypeId === AttachmentTypeEnum.DOCX) {
      return new Blob([bytes], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
    } else if (attachmentTypeId === AttachmentTypeEnum.PNG) {
      return new Blob([bytes], { type: 'application/png' });
    } else if (attachmentTypeId === AttachmentTypeEnum.JPEG) {
      return new Blob([bytes], { type: 'application/jpeg' });
    } else {
      return new Blob([bytes], { type: 'image/svg+xml' });
    }
  }

  base64toBlob(base64Data, contentType) {
    contentType = contentType || '';
    const sliceSize = 1024;
    const byteCharacters = atob(base64Data);
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);

    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      const begin = sliceIndex * sliceSize;
      const end = Math.min(begin + sliceSize, bytesLength);

      const bytes = new Array(end - begin);
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: contentType });
  }

  base64ToArrayBuffer(base64, saveName: string): Blob {
    const binary_string = window.atob(base64);
    const len = binary_string.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    if (saveName.endsWith('.pdf') || !saveName.includes('.')) {
      return new Blob([bytes], { type: 'application/pdf' });
    } else if (saveName.toLowerCase().endsWith('.jpg')) {
      return new Blob([bytes], { type: 'image/jpg' });
    } else if (saveName.toLowerCase().endsWith('.png')) {
      return new Blob([bytes], { type: 'image/png' });
    } else if (saveName.toLowerCase().endsWith('.doc') || saveName.toLowerCase().endsWith('.docx')) {
      return new Blob([bytes], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
    } else if (saveName.toLowerCase().endsWith('.xls') || saveName.toLowerCase().endsWith('.xlsx') || saveName.toLowerCase().endsWith('.xlsm')) {
      return new Blob([bytes], { type: 'application/vnd.ms-excel' });
    } else {
      return new Blob([bytes], { type: 'application/octet-stream' });
    }
  }

  handleError(err: HttpErrorResponse) {
    console.log(JSON.stringify(err));
    return observableThrowError(err);
  }

  jsonReplacer(key, value) {
    if (this[key] instanceof Date) {
      return this[key].toDateString();
    }
    return value;
  }
}
