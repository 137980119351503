import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


/* MATERIAL */
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatGridListModule } from '@angular/material/grid-list';

/* DevExtreme */
import {
  DxButtonModule,
  DxBoxModule,
  DxDataGridModule,
  DxPopupModule,
  DxTemplateModule,
  DxFileUploaderModule,
  DxSelectBoxModule,
  DxDropDownBoxModule,
  DxTextBoxModule,
  DxCheckBoxModule,
  DxTextAreaModule,
  DxDateBoxModule,
  DxNumberBoxModule,
  DxLoadPanelModule,
  DxListModule,
  DxToolbarModule,
  DxTabPanelModule,
  DxHtmlEditorModule,
  DxFormModule,
  DxResizableModule,
  DxScrollViewModule,
  DxDropDownButtonModule,
  DxTagBoxModule,
  DxMapModule,
  DxRadioGroupModule,
  DxTreeListModule
} from 'devextreme-angular';

/* COMPONENTS */
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { ErrorPopupComponent } from './error-popup/error-popup.component';
import { JobNumberComponent } from './job-number/job-number.component';
import { JobSearchModalComponent } from './job-search-modal/job-search-modal.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ShowImageComponent } from './show-image/show-image.component';
import { SetColourComponent } from './set-colour/set-colour.component';


@NgModule({
    imports: [
        CommonModule,
        DxCheckBoxModule,
        DxDataGridModule,
        DxTextBoxModule,
        DxButtonModule,
        DxBoxModule,
        DxPopupModule,
        DxTemplateModule,
        DxFileUploaderModule,
        DxSelectBoxModule,
        DxDropDownBoxModule,
        DxTextAreaModule,
        DxDateBoxModule,
        DxNumberBoxModule,
        DxLoadPanelModule,
        DxListModule,
        DxToolbarModule,
        DxTabPanelModule,
        DxFormModule,
        DxHtmlEditorModule,
        DxResizableModule,
        DxScrollViewModule,
        DxTagBoxModule,
        DxDropDownButtonModule,
        DxMapModule,
        DxRadioGroupModule,
        DxTreeListModule
    ],
    declarations: [
        LoadingSpinnerComponent,
        ErrorPopupComponent,
        JobNumberComponent,
        JobSearchModalComponent,
        ShowImageComponent,
        SetColourComponent
    ],
    exports: [
        CommonModule,
        FormsModule,
        // mat
        MatSelectModule,
        MatTableModule,
        MatTabsModule,
        MatGridListModule,
        MatTooltipModule,
        DxTabPanelModule,
        DxFormModule,
        DxHtmlEditorModule,
        // dx
        DxButtonModule,
        DxBoxModule,
        DxDataGridModule,
        DxPopupModule,
        DxTemplateModule,
        DxFileUploaderModule,
        DxSelectBoxModule,
        DxDropDownBoxModule,
        DxTextBoxModule,
        DxCheckBoxModule,
        DxTextAreaModule,
        DxDateBoxModule,
        DxNumberBoxModule,
        DxLoadPanelModule,
        DxListModule,
        DxToolbarModule,
        DxResizableModule,
        DxScrollViewModule,
        DxTagBoxModule,
        DxDropDownButtonModule,
        DxMapModule,
        DxRadioGroupModule,
        DxTreeListModule,
        // ng
        ReactiveFormsModule,
        HttpClientModule,
        BrowserAnimationsModule,
        LoadingSpinnerComponent,
        ErrorPopupComponent,
        JobNumberComponent,
        JobSearchModalComponent,
        ShowImageComponent
    ],
    providers: []
})
export class SharedModule { }
