import { UtilsService } from './../../services/utils.service';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalService } from '../../services/global.service';
import { NotificationService } from '../../services/notification.service';
import { Subscription } from 'rxjs';
import { EstateService } from '../../services/felixApi/estate.service';
import DataSource from 'devextreme/data/data_source';
import { AttachmentTypeEnum } from '../../dtos/attachment-type.enum';
import { saveAs } from 'file-saver';
import { GridService } from '../../services/grid.service';

@Component({
  selector: 'js-estate-attachments',
  templateUrl: './estate-attachments.component.html',
  styleUrls: ['./estate-attachments.component.scss']
})
export class EstateAttachmentsComponent implements OnInit, OnDestroy {
  @Input() estateId: number;
  @Input() titleDescription: string;


  subscriptions: Subscription[] = [];
  gridHeight: number;
  dataSource: DataSource;
  uploadFilePopupVisible = false;
  acceptedTypes: string;
  wrongFileType: boolean;
  filesToUpload: File[] = [];
  loading = false;
  blob: Blob;
  estateAttachmentId: number;
  estateAttachmentDescription: any;

  constructor(
    private _activeModal: NgbActiveModal,
    private estateService: EstateService,
    private notiService: NotificationService,
    private utilsService: UtilsService,
    protected gridService: GridService,
    private globalService: GlobalService) {
    this.uploadAttachment = this.uploadAttachment.bind(this);
    this.downloadAttachment = this.downloadAttachment.bind(this);
    this.onReorder = this.onReorder.bind(this);
  }


  ngOnInit(): void {
    this.setHeightWidths();
    this.subscriptions = this.subscriptions.concat(
      this.globalService.innerHeightWidthChanged.subscribe(
        () => {
          this.setHeightWidths();
        }
      ));

    this.acceptedTypes = '.docx, or .pdf';

    this.setUpDataSource();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  setHeightWidths() {
    this.gridHeight = window.innerHeight - 300;
  }

  cancel() {
    this._activeModal.dismiss();
  }

  setUpDataSource() {
    this.dataSource = new DataSource({
      key: 'id',
      load: async () => {
        return new Promise((resolve, reject) =>
          this.estateService.getEstateAttachments(this.estateId).subscribe({
            next: (res) => {
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      },
      insert: async (values) => {
        values.estateId = this.estateId;
        return new Promise((resolve, reject) =>
          this.estateService.addEstateAttachment(values).subscribe({
            next: (res) => {
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      },
      update: async (key, values) => {
        values.id = encodeURIComponent(key);
        return new Promise((resolve, reject) =>
          this.estateService.updateEstateAttachment(encodeURIComponent(key), values).subscribe({
            next: (res) => {
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      },
      remove: async (key) => {
        return new Promise((resolve, reject) =>
          this.estateService.deleteEstateAttachment(encodeURIComponent(key)).subscribe({
            next: () => {
              return resolve();
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      }
    });
  }

  uploadAttachment(e) {
    if (e.row.data.id) {
      this.loading = false;
      this.uploadFilePopupVisible = true;
      this.filesToUpload = [];
      this.estateAttachmentId = e.row.data.id;
      this.estateAttachmentDescription = e.row.data.description;
    }
  }


  handleFileInput(eventRecord) {
    for (let i = 0; i < eventRecord.value.length; i++) {
      const fileType = this.utilsService.getFileType(eventRecord.value[i].name);

      if (!fileType
        || (fileType !== AttachmentTypeEnum.PDF
          && fileType !== AttachmentTypeEnum.DOCX)) {
        this.wrongFileType = true;
        return;
      }
    }
    this.wrongFileType = false;
    this.filesToUpload = eventRecord.value;
  }

  uploadFile() {
    this.loading = true;
    const fileList = this.filesToUpload;
    const image: File = fileList[0];
    const form = this.utilsService.fileToFormData(image);

    this.subscriptions.push(
      this.estateService.uploadEstateAttachmentFile(this.estateAttachmentId, form).subscribe({
        next: (res) => {
          this.notiService.showSuccess('File Updated');
          this.loading = false;
          this.uploadFilePopupVisible = false;
        }, error: (err) => {
          this.notiService.notify(err);
          this.loading = false;
        }
      })
    );
  }

  downloadAttachment(e) {
    if (e.row.data.id) {
      this.loading = true;

      this.subscriptions = this.subscriptions.concat(
        this.estateService.getEstateAttachmentFile(e.row.data.id).subscribe({
          next: (jobItemAttachment) => {
            this.loading = false;
            this.base64ToArrayBuffer(jobItemAttachment.attachment);
            this.save(jobItemAttachment.attachmentName);
          },
          error: (err) => {
            this.loading = false;
            this.notiService.notify(err);
          }
        })
      );
    }
  }

  base64ToArrayBuffer(base64) {
    const binary_string = window.atob(base64);
    const len = binary_string.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    this.blob = new Blob([bytes], { type: 'application/pdf' });

    return bytes.buffer;
  }

  save(saveName: string) {
    saveAs(this.blob, saveName);
  }

  onReorder(e) {
    e.promise = this.processReorder(e);
  }

  async processReorder(e) {
    this.subscriptions.push(
      this.estateService.updateEstateAttachment(e.itemData.id, { orderNo: e.toIndex + 1 }).subscribe({
        next: () => {
          e.component.refresh();
        }, error: (err) => {
          this.notiService.notify(err);
          this.loading = false;
        }
      })
    );
  }
}
