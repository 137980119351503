<div class="page">
  <js-loading-spinner *ngIf="loadingData" [usePopUp]="false"></js-loading-spinner>

  <dx-data-grid *ngIf="!loadingData" #packageGrid id="poGrid" [height]="gridHeight"
    [dataSource]="dataSource" [allowColumnReordering]="true" [allowColumnResizing]="true" [repaintChangesOnly]="true"
    [showBorders]="true" [rowAlternationEnabled]="true" [wordWrapEnabled]="true" [hoverStateEnabled]="true"
    [selection]="{ mode: 'single' }" [focusedRowEnabled]="true" [showRowLines]="true" [focusedRowKey]="selectedRecord"
    [selectedRowKeys]="selectedRecord" (onSelectionChanged)="onSelectionChanged($event)"
    (onToolbarPreparing)="onToolbarPreparing($event)" (onCellPrepared)="onCellPrepared($event)"
    (onCellPrepared)="onCellPrepared($event)" (onExporting)="gridService.onExporting($event, 'Builder-View')">

    <dxo-search-panel [visible]="true" location="before" [width]="200"></dxo-search-panel>
    <dxo-export [enabled]="true"></dxo-export>
    <dxo-scrolling mode="standard"></dxo-scrolling>
    <dxo-paging [enabled]="false"></dxo-paging>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-header-filter [visible]="true"></dxo-header-filter>
    <dxo-filter-panel [visible]="true"></dxo-filter-panel>
    <dxo-group-panel [visible]="true"></dxo-group-panel>
    <dxo-grouping #expand [autoExpandAll]="true" [contextMenuEnabled]="false"></dxo-grouping>

    <dxo-selection selectAllMode='allPages' showCheckBoxesMode='always' mode="single">
    </dxo-selection>

    <dxo-editing mode="row" [allowAdding]="false" [allowUpdating]="packagesAdmin" [allowDeleting]="false"
      [useIcons]="true" startEditAction="click" refreshMode="full">
    </dxo-editing>

    <dxi-column dataField="job.jobNumber" caption="Job" [width]="90" alignment="center" sortOrder="asc"
      [hidingPriority]="15" [allowEditing]="false"></dxi-column>

    <dxi-column dataField="job.contractName" caption="Contract Name" [hidingPriority]="1" [minWidth]="150"
      [allowEditing]="false"></dxi-column>

    <dxi-column dataField="jobAddress" caption='Site Address' [calculateCellValue]="calcAddress" [minWidth]="150"
      [allowEditing]="false"></dxi-column>

    <dxi-column dataField="job.jobAddress.suburbTown" caption="Suburb" [width]="120" [hidingPriority]="10"
      [allowEditing]="false"></dxi-column>

    <dxi-column dataField="job.estate" caption="Estate" [width]="120" [hidingPriority]="11"
      [allowEditing]="false"></dxi-column>

    <dxi-column dataField="hasCoordinates" caption="Location" dataType="string" [width]="105"
      [calculateCellValue]="calcHasCoordinates" alignment="center" [hidingPriority]="2"
      [allowEditing]="false"></dxi-column>

    <dxi-column dataField="brokerId" dataType="number" caption="Broker" [width]="160" [hidingPriority]="13"
      sortOrder="asc" [calculateSortValue]="calculateBrokerSortValue" [allowEditing]="false">
      <dxo-lookup [dataSource]="brokers" valueExpr="id" displayExpr="name"> </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="landPrice" caption="Land" [width]="90" format="#,###" [hidingPriority]="7"
      [allowEditing]="false"></dxi-column>

    <dxi-column dataField="salesQuotePrice" caption="House" [width]="90" format="#,###" [hidingPriority]="8"
      [allowEditing]="false"></dxi-column>

    <dxi-column *ngIf="packagesAdmin" dataField="commissionCost" dataType="number" format="#,###" [width]="110"
      [hidingPriority]="3" [setCellValue]="setCommissionCost"></dxi-column>

    <dxi-column *ngIf="packagesAdmin" dataField="commissionMarkUp" caption="Commission Mark-Up %" dataType="number"
      format="##.##" [width]="110" [hidingPriority]="4" [setCellValue]="setCommissionMarkUp"></dxi-column>

    <dxi-column *ngIf="packagesAdmin" dataField="commissionGST" caption="Commission GST %" dataType="number"
      format="##.##" [width]="110" [hidingPriority]="5" [setCellValue]="setCommissionGST"></dxi-column>

    <dxi-column *ngIf="packagesAdmin" dataField="commissionIncGST" caption="Commission Total Inc GST" dataType="number"
      format="#,###" [width]="110" [hidingPriority]="6" [setCellValue]="setCommissionIncGST"></dxi-column>

    <dxi-column dataField="totalPackage" caption="Package" [width]="90" format="#,###"
      [calculateCellValue]="calculateTotalPackage" [hidingPriority]="9" [allowEditing]="false"></dxi-column>

    <dxi-column dataField="onHoldStatusTypeId" caption="Status" [width]="90" alignment="center" [allowEditing]="false">
      <dxo-lookup [dataSource]="onHoldStatusTypes" valueExpr="id" displayExpr="description"> </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="holdRequestedBy" caption="Requested By" [width]="120" [hidingPriority]="12"></dxi-column>

    <dxi-column dataField="expiryDate" caption="Expiry" dataType="date" [width]="90" alignment="center"
      format="d-MMM-yy" [hidingPriority]="14" [allowEditing]="false"></dxi-column>

    <dxi-column *ngIf="showSold" dataField="job.salesDate" caption="Sold" dataType="date" alignment="center"
      format="d-MMM-yy" [width]="90" [allowEditing]="false"></dxi-column>


    <dxi-column type="buttons" [width]="buttonColumnWidth">
      <dxi-button [visible]="!phoneSize" name="edit"></dxi-button>
      <dxi-button [visible]="isApproveButtonVisible" icon="check" [onClick]="approveHold"></dxi-button>
      <dxi-button [visible]="isDeclineButtonVisible" icon="close" [onClick]="declineHold"></dxi-button>
      <dxi-button [visible]="isDownloadButtonVisible" icon="download" [onClick]="downloadPackage"></dxi-button>
    </dxi-column>

  </dx-data-grid>
</div>
