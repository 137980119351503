import { GlobalService } from '../../services/global.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import { Subscription } from 'rxjs';
import { SuburbService } from '../../services/felixApi/suburb.service';
import { Suburb } from '../../dtos/suburb';
import { GridService } from '../../services/grid.service';

@Component({
  selector: 'js-suburbs',
  templateUrl: './suburbs.component.html',
  styleUrls: ['./suburbs.component.scss']
})
export class SuburbsComponent implements OnInit, OnDestroy {

  subscriptions: Subscription[] = [];
  gridHeight: number;
  suburbs: Suburb[];
  dataSource: DataSource;
  showSold = false;

  constructor(
    private suburbService: SuburbService,
    protected gridService: GridService,
    private globalService: GlobalService) {
  }

  ngOnInit() {
    this.gridHeight = window.innerHeight - 120;
    this.setUpDataSource();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  setUpDataSource() {
    this.dataSource = new DataSource({
      key: 'id',
      load: async () => {
        return new Promise((resolve, reject) =>
          this.suburbService.getSuburbs(false).subscribe({
            next: (res) => {
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      },
      insert: async (values) => {
        return new Promise((resolve, reject) =>
          this.suburbService.addSuburb(values).subscribe({
            next: (res) => {
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      },
      update: async (key, values) => {
        return new Promise((resolve, reject) =>
          this.suburbService.updateSuburb(encodeURIComponent(key), values).subscribe({
            next: (res) => {
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      },
      remove: async (key) => {
        return new Promise((resolve, reject) =>
          this.suburbService.deleteSuburb(encodeURIComponent(key)).subscribe({
            next: () => {
              return resolve();
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      }
    });
  }
}
