import { LandService } from '../../services/felixApi/land.service';
import { GlobalService } from '../../services/global.service';
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DxDataGridComponent } from 'devextreme-angular';
import CustomStore from 'devextreme/data/custom_store';

@Component({
  selector: 'js-land-field-lookup',
  templateUrl: './land-field-lookup.component.html',
  styleUrls: ['./land-field-lookup.component.scss']
})
export class LandFieldLookupComponent implements OnInit {
  @Input() landFieldId: number;
  @Input() landFieldName: string;

  @ViewChild('landLookupGrid') grid: DxDataGridComponent;

  dataSource: CustomStore;

  constructor(
    private activeModal: NgbActiveModal,
    private globalService: GlobalService,
    private landService: LandService) {
    this.onReorder = this.onReorder.bind(this);
    this.onInitNewRow = this.onInitNewRow.bind(this);
  }

  ngOnInit() {
    this.setUpDataSet();
  }

  setUpDataSet() {
    this.dataSource = new CustomStore({
      key: 'id',
      load: async () => {
        return new Promise((resolve, reject) =>
          this.landService.getLandFieldLookups(this.landFieldId).subscribe({
            next: (res) => {
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      },
      insert: async (values) => {
        return new Promise((resolve, reject) =>
          this.landService.addLandFieldLookup(values).subscribe({
            next: (res) => {
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      },
      update: async (key, values) => {
        return new Promise((resolve, reject) =>
          this.landService.updateLandFieldLookup(encodeURIComponent(key), values).subscribe({
            next: (res) => {
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      },
      remove: async (key) => {
        return new Promise((resolve, reject) =>
          this.landService.deleteLandFieldLookup(encodeURIComponent(key)).subscribe({
            next: () => {
              return resolve();
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      }
    });
  }

  onReorder(e) {
    const visibleRows = e.component.getVisibleRows();
    const newOrderIndex = visibleRows[e.toIndex].data.orderNo;

    this.landService.updateLandFieldLookup(e.itemData.id, { orderNo: newOrderIndex }).subscribe(() => {
      e.component.refresh();
    });
  }

  close() {
    this.activeModal.close();
  }

  onInitNewRow(e) {
    e.data.landFieldId = this.landFieldId;
    e.data.orderNo = 0;
  }
}
