import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalService } from '../../services/global.service';
import { NotificationService } from '../../services/notification.service';
import { Subscription } from 'rxjs';
import { LandService } from '../../services/felixApi/land.service';
import { BrokerService } from '../../services/felixApi/broker.service';
import DataSource from 'devextreme/data/data_source';
import { Broker } from '../../dtos/broker';
import { GridService } from '../../services/grid.service';

@Component({
  selector: 'js-land-hold-warnings',
  templateUrl: './land-hold-warnings.component.html',
  styleUrls: ['./land-hold-warnings.component.scss']
})
export class LandHoldWarningsComponent implements OnInit, OnDestroy {
  @Input() landId: number;

  subscriptions: Subscription[] = [];
  gridHeight: number;
  dataSource: DataSource;

  constructor(
    private _activeModal: NgbActiveModal,
    protected gridService: GridService,
    private landService: LandService,
    private notiService: NotificationService,
    private globalService: GlobalService) {
      this.setUpDataSource = this.setUpDataSource.bind(this);
     }


  ngOnInit(): void {
    this.setHeightWidths();
    this.subscriptions = this.subscriptions.concat(
      this.globalService.innerHeightWidthChanged.subscribe(
        () => {
          this.setHeightWidths();
        }
      ));
      this.setUpDataSource();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  setHeightWidths() {
    this.gridHeight = window.innerHeight < 500 ? window.innerHeight : 500;
  }


  cancel() {
    this._activeModal.dismiss();
  }

  setUpDataSource() {
    this.dataSource = new DataSource({
      key: 'id',
      load: async () => {
        return new Promise((resolve, reject) =>
          this.landService.getLandHoldWarnings(this.landId).subscribe({
            next: (res) => {
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      },
      insert: async (values) => {
        values.landId = this.landId;
        return new Promise((resolve, reject) =>
          this.landService.addLandHoldWarning(values).subscribe({
            next: (res) => {
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      },
      update: async (key, values) => {
        return new Promise((resolve, reject) =>
          this.landService.updateLandHoldWarning(encodeURIComponent(key), values).subscribe({
            next: (res) => {
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      },
      remove: async (key) => {
        return new Promise((resolve, reject) =>
          this.landService.deleteLandHoldWarning(encodeURIComponent(key)).subscribe({
            next: () => {
              return resolve();
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      }
    });
  }
}
