<div class="page">
  <dx-data-grid #packageGrid id="poGrid" [dataSource]="dataSource" [allowColumnReordering]="true" [width]="1000"
    [height]="gridHeight" [allowColumnResizing]="true" [repaintChangesOnly]="true" [showBorders]="true"
    [rowAlternationEnabled]="true" [columnAutoWidth]="true" [wordWrapEnabled]="true" [hoverStateEnabled]="true"
    class="m-auto" (onExporting)="gridService.onExporting($event, 'Suburbs')">

    <dxo-search-panel [visible]="true" location="before" [width]="200"></dxo-search-panel>
    <dxo-export [enabled]="true"></dxo-export>
    <dxo-scrolling mode="standard"></dxo-scrolling>
    <dxo-paging [enabled]="false"></dxo-paging>
    <dxo-header-filter [visible]="true"></dxo-header-filter>

    <dxo-editing mode="row" [allowUpdating]="true" [allowAdding]="true" [allowDeleting]="true" [useIcons]="true"
      startEditAction="click" refreshMode="full">
    </dxo-editing>

    <dxi-column dataField="id" dataType="number" [visible]='false'></dxi-column>

    <dxi-column dataField="suburbTown" caption="Suburb" [minWidth]="250">
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>

    <dxi-column dataField="state" [width]="150" [hidingPriority]="3">
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>

    <dxi-column dataField="postCode" [width]="110" [hidingPriority]="2">
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>

    <dxi-column dataField="latitude" dataType="number" [width]="110"></dxi-column>

    <dxi-column dataField="longitude" dataType="number" [width]="110"></dxi-column>

    <dxi-column dataField="isActive" caption="Active" dataType="boolean" [width]="90" [hidingPriority]="1"></dxi-column>

  </dx-data-grid>
</div>
