import { CompanyService } from './../../services/felixApi/company.service';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from '../../services/notification.service';
import { Subscription } from 'rxjs';
import { BrokerService } from '../../services/felixApi/broker.service';
import { BrokerJob } from '../../dtos/broker-job';
import { UserService } from '../../services/felixApi/user.service';
import { UserTypeEnum } from '../../dtos/user-type.enum';
import { User } from '../../dtos/user';

@Component({
  selector: 'js-approve-hold',
  templateUrl: './approve-hold.component.html',
  styleUrls: ['./approve-hold.component.scss']
})
export class ApproveHoldComponent implements OnInit, OnDestroy {
  @Input() brokerJob: BrokerJob;

  subscriptions: Subscription[] = [];
  expiryDate: Date;
  showLoadingSpinner: boolean;
  brokerUsers: User[];
  selectedBrokerUserId: number;
  sendEmail = true;
  emailNote = '';

  constructor(
    private _activeModal: NgbActiveModal,
    private brokerService: BrokerService,
    private companyService: CompanyService,
    private userService: UserService,
    private notiService: NotificationService) { }


  ngOnInit(): void {
    this.expiryDate = this.brokerJob.expiryDate ?? this.getNewExpiry();
    this.selectedBrokerUserId = this.brokerJob.onHoldUserId;
    this.getUsersForBroker();
  }

  ngOnDestroy() {
  }

  getNewExpiry(): Date {
    if (this.brokerJob.broker.defaultHoldDays) {
      return this.companyService.addDaysExHolidays(new Date, this.brokerJob.broker.defaultHoldDays)
    }
    return new Date;
  }

  cancel() {
    this._activeModal.dismiss();
  }

  getUsersForBroker() {
    this.subscriptions = this.subscriptions.concat(
      this.userService.getUsersForType(UserTypeEnum.SalesBroker, this.brokerJob.brokerId, null)
        .subscribe({
          next: (users) => {
            this.brokerUsers = users;
          },
          error: (error) => {
            this.notiService.notify(error);
          }
        })
    );
  }

  approveHold() {
    this.showLoadingSpinner = true;

    if (this.brokerJob.onHoldId) {
      this.subscriptions = this.subscriptions.concat(
        this.brokerService.approveHold(this.brokerJob.onHoldId, this.expiryDate, this.selectedBrokerUserId, this.emailNote, this.sendEmail)
          .subscribe({
            next: () => {
              this.showLoadingSpinner = false;
              this._activeModal.close();
            },
            error: (error) => {
              this.showLoadingSpinner = false;
              this.notiService.notify(error);
            }
          })
      );
    } else {
      // needs a new hold record
      this.subscriptions = this.subscriptions.concat(
        this.brokerService.addHoldApproved(this.brokerJob.jobId, this.expiryDate, this.brokerJob.brokerId, this.selectedBrokerUserId, this.emailNote, this.sendEmail)
          .subscribe({
            next: () => {
              this.showLoadingSpinner = false;
              this._activeModal.close();
            },
            error: (error) => {
              this.showLoadingSpinner = false;
              this.notiService.notify(error);
            }
          })
      );
    }
  }
}
