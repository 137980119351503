<div #ParentDiv>
  <div class="titleBar">
    <div class="title">Truth Engine Packages</div>
  </div>

  <div class="welcome">

    <br>

    <div class="chooseComp" *ngIf="multipleCompanies && !loading">
      <div *ngIf="!currComp" class="subTitle">Please Select a Company:</div>
      <div *ngIf="currComp" class="subTitle">Current Company:</div>
      <br>
      <div [class.formFieldWidth300]="innerWidth > 500">
        <mat-form-field>
          <mat-select [(ngModel)]="currComp" (ngModelChange)="compSelected()" [compareWith]="compareComps"
            name="compSelect">
            <mat-option *ngFor="let comp of userCompanies" [value]="comp">{{comp.companyName}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div *ngIf="loading" style="margin-bottom: 50px">
      <js-loading-spinner></js-loading-spinner>
    </div>

    <br>

    <div *ngIf="!loading">
      <div *ngIf="!(innerWidth < 750)" class="user">
        <div class="userDetails">
          <div style="text-align: left;">
            <span *ngIf="currUser" class="username">{{currUser.firstName}} {{currUser.lastName}}</span>
            <span *ngIf="!currUser" class="username">User</span>
          </div>
          {{currUserEmail}}
        </div>
        <div class="userButtons">
          <button class="btn btn-outline-primary" (click)="signOut()">Sign out</button>
        </div>
      </div>
      <div *ngIf="innerWidth < 750" class="userSmall">
        <div class="userDetailsSmall">
          <div *ngIf="currUser" class="username">{{currUser.firstName}} {{currUser.lastName}}</div>
          <div *ngIf="!currUser" class="username">User</div>
          {{currUserEmail}}
        </div>
        <div class="userButtonsSmall">
          <button class="btn btn-outline-primary buttonSmall" (click)="signOut()">Sign out</button>
        </div>
      </div>
    </div>
    <br>

    <div *ngIf="currentVersion" class='versionString'>version {{currentVersion}}</div>
  </div>

  <br>
  <button class="btn btn-sm ms-2" (click)="clearCache()">
    to clear memory click here
  </button>
</div>
