<div class="page">
  <js-loading-spinner *ngIf="loadingData" [usePopUp]="false"></js-loading-spinner>

  <dx-data-grid *ngIf="!loadingData" #packageGrid id="poGrid" [height]="gridHeight"
    [dataSource]="dataSource" [allowColumnReordering]="true" [allowColumnResizing]="true" [repaintChangesOnly]="true"
    [showBorders]="true" [rowAlternationEnabled]="true" [wordWrapEnabled]="true" [hoverStateEnabled]="true"
    (onToolbarPreparing)="onToolbarPreparing($event)" (onCellPrepared)="onCellPrepared($event)"
    (onExporting)="gridService.onExporting($event, 'Broker-View')">

    <dxo-search-panel [visible]="true" location="before" [width]="100"></dxo-search-panel>
    <dxo-export [enabled]="true"></dxo-export>
    <dxo-scrolling mode="standard"></dxo-scrolling>
    <dxo-paging [enabled]="false"></dxo-paging>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-header-filter [visible]="true"></dxo-header-filter>
    <dxo-filter-panel [visible]="true"></dxo-filter-panel>
    <dxo-group-panel [visible]="true"></dxo-group-panel>
    <dxo-grouping #expand [autoExpandAll]="true" [contextMenuEnabled]="false"></dxo-grouping>

    <dxo-editing mode="row" [allowAdding]="false" [allowUpdating]="false" [allowDeleting]="false" [useIcons]="phoneSize"
      startEditAction="click" refreshMode="full">
    </dxo-editing>

    <dxi-column dataField="job.jobNumber" caption="Job" [width]="100" alignment="center" sortOrder="asc"
      [hidingPriority]="9"></dxi-column>

    <dxi-column dataField="job.contractName" caption="Contract Name" [hidingPriority]="1" [minWidth]="200"></dxi-column>

    <dxi-column dataField="job.jobAddressString" caption='Site Address' [minWidth]="200"></dxi-column>

    <dxi-column dataField="job.jobAddress.suburbTown" caption="Suburb" [width]="140" [hidingPriority]="5"></dxi-column>

    <dxi-column dataField="job.estate" caption="Estate" [width]="140" [hidingPriority]="6"></dxi-column>

    <dxi-column dataField="landPrice" caption="Land" [width]="90" format="#,###" [hidingPriority]="2"></dxi-column>

    <dxi-column dataField="salesQuoteIncCommission" caption="House" [width]="90" format="#,###"
      [hidingPriority]="3"></dxi-column>

    <dxi-column dataField="totalPackage" caption="Package" [width]="90" format="#,###"
      [calculateCellValue]="calculateTotalPackage" [hidingPriority]="4"></dxi-column>

    <dxi-column dataField="onHoldStatusTypeId" caption="Status" [width]="110" alignment="center">
      <dxo-lookup [dataSource]="onHoldStatusTypes" valueExpr="id" displayExpr="description"> </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="expiryDate" caption="Expiry" dataType="date" [width]="100" alignment="center"
      [hidingPriority]="8"></dxi-column>

    <dxi-column *ngIf="showSold" dataField="job.salesDate" caption="Sales Date" dataType="date" [width]="120"
      alignment="center"></dxi-column>

    <dxi-column dataField="holdRequestedBy" caption="Requested By" [width]="140" [hidingPriority]="7"></dxi-column>


    <dxi-column type="buttons" [width]="buttonColumnWidth">
      <!-- <dxi-button id="requestHoldButton" [visible]="isRequestButtonVisible" text="Request Hold" [onClick]="requestHold"></dxi-button> -->
      <dxi-button [visible]="isRequestButtonVisible" template="holdCommand">
        <div class="holdButtonClass" *dxTemplate="let data of 'holdCommand'">
          <span (click)="requestHold(data)">Request-Hold</span>
        </div>
      </dxi-button>

      <dxi-button [visible]="isAddButtonVisible" icon="add" [onClick]="requestHold"></dxi-button>
      <dxi-button [visible]="isDownloadButtonVisible" icon="download" [onClick]="downloadPackage"></dxi-button>
    </dxi-column>

  </dx-data-grid>
</div>
