import { GlobalService } from '../../services/global.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import { Subscription } from 'rxjs';
import { DeveloperService } from '../../services/felixApi/developer.service';
import { Developer } from '../../dtos/developer';
import { UserMaintenanceComponent } from '../user-maintenance/user-maintenance.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserTypeEnum } from '../../dtos/user-type.enum';
import { GridService } from '../../services/grid.service';

@Component({
  selector: 'js-developers',
  templateUrl: './developers.component.html',
  styleUrls: ['./developers.component.scss']
})
export class DevelopersComponent implements OnInit, OnDestroy {

  subscriptions: Subscription[] = [];
  gridHeight: number;
  developers: Developer[];
  dataSource: DataSource;
  showSold = false;

  constructor(
    private developerService: DeveloperService,
    private globalService: GlobalService,
    protected gridService: GridService,
    private modalService: NgbModal) {
    this.maintainUsers = this.maintainUsers.bind(this);
  }

  ngOnInit() {
    this.gridHeight = window.innerHeight - 120;
    this.setUpDataSource();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  setUpDataSource() {
    this.dataSource = new DataSource({
      key: 'id',
      load: async () => {
        return new Promise((resolve, reject) =>
          this.developerService.getDevelopers(false).subscribe({
            next: (res) => {
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      },
      insert: async (values) => {
        return new Promise((resolve, reject) =>
          this.developerService.addDeveloper(values).subscribe({
            next: (res) => {
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      },
      update: async (key, values) => {
        return new Promise((resolve, reject) =>
          this.developerService.updateDeveloper(encodeURIComponent(key), values).subscribe({
            next: (res) => {
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      },
      remove: async (key) => {
        return new Promise((resolve, reject) =>
          this.developerService.deleteDeveloper(encodeURIComponent(key)).subscribe({
            next: () => {
              return resolve();
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      }
    });
  }

  maintainUsers(e) {
    const modalRef = this.modalService.open(UserMaintenanceComponent, { windowClass: 'modal-1000' });
    modalRef.componentInstance.userTypeId = UserTypeEnum.Developer;
    modalRef.componentInstance.developerId = e.row.data.id;
    modalRef.componentInstance.titleDescription = e.row.data.description;

    modalRef.result.then(() => { });
  }
}
