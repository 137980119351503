<div class="toolBarClass">

  <!-- <dx-radio-group id="radioGroup" [items]="mapDataTypes" valueExpr="id" displayExpr="description"
    [value]="selectedMapDataTypeId" (onValueChanged)="onRadioChanged($event)" layout="horizontal">
  </dx-radio-group> -->

  <dx-check-box text="Available" [(value)]='showAvailable' (onValueChanged)="showAvailableChanged($event)">
  </dx-check-box>

  <dx-check-box text="Pending" [(value)]='showPending' (onValueChanged)="showPendingChanged($event)" class="ms-3">
  </dx-check-box>

  <dx-check-box text="Approved" [(value)]='showApproved' (onValueChanged)="showApprovedChanged($event)" class="ms-3">
  </dx-check-box>

  <dx-check-box text="Sold" [(value)]='showSold' (onValueChanged)="showSoldChanged($event)" class="ms-3">
  </dx-check-box>

  <dx-select-box *ngIf="isOfficeUser" id="selectBroker" [dataSource]="brokers" displayExpr="name" valueExpr="id"
    placeholder="Select Broker..." [width]="250" [height]="30" [(value)]='selectedBrokerId'
    (onValueChanged)='getBrokerJobs()' [showClearButton]="true">
  </dx-select-box>

</div>

<dx-popup [width]="250" [height]="150" [showTitle]="true" [title]="popupTitle" [dragEnabled]="true"
  [deferRendering]="true" [hideOnOutsideClick]="true" [(visible)]="showHoldPopup" [shading]="false">
  <dxo-position at='left bottom' offset="150 -100"> </dxo-position>
  <div *dxTemplate="let data of 'content'">
    <div class="addressTextClass">{{addressText}}</div>
    <!-- <br> -->
    <dx-button id="hold-button" text="Request Hold" type="default" stylingMode="default" (onClick)="requestHold()"
      class="holdButton">
    </dx-button>
  </div>
</dx-popup>

<dx-map *ngIf="!loadingData && markers?.length" zoom="auto" [height]="mapHeight" width="100%" [controls]="true" [apiKey]="keys"
  [markers]="markers" type="roadmap">
</dx-map>


<!-- loading spinner - DevExtreme -->
<dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [visible]="showLoadingSpinner || loadingData"
  [showIndicator]="true" [showPane]="true" [shading]="true" [hideOnOutsideClick]="false">
</dx-load-panel>
