<div class="modal-header">
  <h3 class="modal-title">Allocate Package</h3>
  <button type="button" class="close" aria-label="Close" (click)="cancel()" tabindex="-1">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">

  <!-- select job to allocate -->
  <js-job-number (refreshJobNumber)='onJobNumberChanged($event)' [maxJobStringWidth]="600"></js-job-number>

  <dx-data-grid #approvedGrid id="approvedGrid" [height]="gridHeight" [dataSource]="dataSource"
    [allowColumnReordering]="false" [allowColumnResizing]="true" [repaintChangesOnly]="true" [showBorders]="true"
    [rowAlternationEnabled]="true" [wordWrapEnabled]="true" [hoverStateEnabled]="true"
    [(selectedRowKeys)]="selectedRecords" (onToolbarPreparing)="onToolbarPreparing($event)"
    (onExporting)="gridService.onExporting($event, 'Allocate-Packages')">

    <dxo-search-panel [visible]="true" location="after" [width]="200"></dxo-search-panel>
    <dxo-export [enabled]="true"></dxo-export>
    <dxo-scrolling mode="standard"></dxo-scrolling>
    <dxo-paging [enabled]="false"></dxo-paging>

    <dxo-selection selectAllMode='allPages' showCheckBoxesMode='always' mode="multiple">
    </dxo-selection>

    <dxi-column dataField="id" dataType="number" [visible]='false'></dxi-column>

    <dxi-column dataField="name" sortOrder="asc"></dxi-column>
  </dx-data-grid>
</div>

<!-- loading spinner - DevExtreme -->
<dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [(visible)]="showLoadingSpinner" [showIndicator]="true"
  [showPane]="true" [shading]="true" [hideOnOutsideClick]="false">
</dx-load-panel>
