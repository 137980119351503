import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalService } from '../services/global.service';
import { NotificationService } from '../services/notification.service';
import { Subscription } from 'rxjs';
import { IJobItemAttachment } from '../dtos/job-item-attachment';
import { saveAs } from 'file-saver';
import { BrokerJob } from '../dtos/broker-job';
import { BrokerService } from '../services/felixApi/broker.service';
import { OnHoldStatusTypeEnum } from '../dtos/onHoldStatusTypeEnum';

@Component({
  selector: 'js-download-package',
  templateUrl: './download-package.component.html',
  styleUrls: ['./download-package.component.scss']
})
export class DownloadPackageComponent implements OnInit, OnDestroy {
  @Input() brokerJob: BrokerJob;

  subscriptions: Subscription[] = [];
  jobItemAttachment: IJobItemAttachment;
  saveName: string;
  loading = false;
  blob: Blob;
  contractName: string;
  quoteDate: Date;
  fieldValueWidth: number;
  onHoldStatusTypeEnum = OnHoldStatusTypeEnum;
  downloadText: string;

  constructor(
    private brokerService: BrokerService,
    private _activeModal: NgbActiveModal,
    private notiService: NotificationService,
    private globalService: GlobalService) { }

  ngOnInit() {
    this.setHeightWidths();
    this.subscriptions = this.subscriptions.concat(
      this.globalService.innerHeightWidthChanged.subscribe(
        () => {
          setTimeout(() => {
            this.setHeightWidths();
          }, 100); // wait for iPhone and grid
        }
      ));
    this.contractName = this.brokerJob.job.contractName;
    this.quoteDate = this.brokerJob.job.quoteDate ?? new Date;
    this.downloadText = this.brokerJob.onHoldStatusTypeId === OnHoldStatusTypeEnum.Approved ? 'Save & Download' : 'Download';
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  setHeightWidths() {
    this.fieldValueWidth = window.innerWidth > 500 ? 500 - 110 : window.innerWidth - 90;
  }

  cancel() {
    this._activeModal.close(null);
  }

  saveAndDownload() {
    // get the sales quote
    const todaysDate = new Date();

    this.loading = true;

    this.subscriptions = this.subscriptions.concat(
      this.brokerService.getBrokerJobPDF(this.brokerJob.id, this.contractName, this.quoteDate).subscribe({
        next: (jobItemAttachment) => {
          this.jobItemAttachment = jobItemAttachment;
          this.saveName = this.jobItemAttachment.attachmentName;
          this.loading = false;

          this.base64ToArrayBuffer(this.jobItemAttachment.attachment);
          this.save();
        },
        error: (err) => {
          this.loading = false;
          this.notiService.notify(err);
        }
      })
    );
  }

  base64ToArrayBuffer(base64) {
    const binary_string = window.atob(base64);
    const len = binary_string.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    this.blob = new Blob([bytes], { type: 'application/pdf' });

    return bytes.buffer;
  }

  save() {
    saveAs(this.blob, this.saveName);
    this._activeModal.close(null);
  }
}
