export class MapDataType {
  id: number;
  description: string;
}

export enum MapDataTypeEnum {
  Available = 1,
  Pending = 2,
  Allocated = 3,
  Sold = 4,
  All = 9
}
