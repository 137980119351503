import { Injectable } from '@angular/core';
import { Observable, throwError as observableThrowError, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { GlobalService } from '../global.service';
import { HttpService } from '../http.service';
import { IJobDocument } from '../../dtos/job-document';
import { IJobDocAttachment } from '../../dtos/job-doc-attachment';
import { SharePointDocument } from '../../dtos/sharepoint-document';

@Injectable({
  providedIn: 'root'
})
export class JobDocumentService {

  private _jobItemUrl = '';
  cache: IJobDocAttachment[] = [];
  cacheSharePoint: SharePointDocument[] = [];

  constructor(
    private http: HttpClient,
    private httpService: HttpService,
    private globalService: GlobalService) { }

  getJobDocuments(jobId: number): Observable<IJobDocument[]> {
    return this.http
      .get<IJobDocument[]>(this.globalService.getApiUrl() + '/job/' + jobId + '/documents-for-client',
        this.httpService.getHttpOptions()).pipe(
          catchError(this.handleError));
  }

  getJobDocAttachment(jobDocId: number, useCache: boolean, autoConvertHEIC: boolean = false): Observable<IJobDocAttachment> {
    const img = this.cache.filter(i => i.jobDocumentId === jobDocId);
    if (useCache && img[0]) {
      return of(img[0]);
    } else {
      this._jobItemUrl = this.globalService.getApiUrl() + '/job-document-attachments/' + jobDocId;
      if (autoConvertHEIC) 
        this._jobItemUrl += '?autoConvertHEIC=true';

      return this.http.get<IJobDocAttachment>(this._jobItemUrl, this.httpService.getHttpOptions())
        .pipe(
          tap(res => this.cache = this.cache.concat(res)),
          catchError(this.handleError)
        );
    }
  }

  // get a SharePoint/Google document
  getSharePointDocument(jobNumber: string, sharePointFileId: string, useCache: boolean, autoConvertHEIC: boolean = false): Observable<SharePointDocument> {
    const img = this.cacheSharePoint.filter(i => i.id === sharePointFileId);
    if (useCache && img[0]) {
      return of(img[0]);
    } else {
      this._jobItemUrl = this.globalService.getApiUrl() + '/sharepoint/job/' + jobNumber + '/get-file?sharePointFileId=' + sharePointFileId;
      if (autoConvertHEIC) 
        this._jobItemUrl += '&autoConvertHEIC=true';

      return this.http.get<SharePointDocument>(this._jobItemUrl, this.httpService.getHttpOptions())
        .pipe(
          tap(res => this.cacheSharePoint.push(res)),
          catchError(this.handleError)
        );
    }
  }

  private handleError(err: HttpErrorResponse) {
    console.log(JSON.stringify(err));
    return observableThrowError(err);
  }
}
