<div class="modal-header">
  <h3 class="modal-title">{{titleDescription}} - logins</h3>
  <button type="button" class="close" aria-label="Close" (click)="cancel()" tabindex="-1">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <dx-data-grid [height]="gridHeight" [dataSource]="dataSource" [allowColumnReordering]="false"
    [allowColumnResizing]="true" [repaintChangesOnly]="true" [showBorders]="true" [rowAlternationEnabled]="true"
    [wordWrapEnabled]="true" [hoverStateEnabled]="true" (onExporting)="gridService.onExporting($event, 'Users')"
    (onInitNewRow)='onInitNewRow($event)'>

    <dxo-search-panel [visible]="true" location="after" [width]="200"></dxo-search-panel>
    <dxo-export [enabled]="true"></dxo-export>
    <dxo-scrolling mode="standard"></dxo-scrolling>
    <dxo-paging [enabled]="false"></dxo-paging>

    <dxo-editing mode="row" [allowUpdating]="true" [allowAdding]="true" [allowDeleting]="false" [useIcons]="true"
      startEditAction="click" refreshMode="full">
    </dxo-editing>

    <dxi-column dataField="id" dataType="number" [visible]='false'></dxi-column>

    <dxi-column dataField="email" [minWidth]="250" sortOrder="asc"></dxi-column>

    <dxi-column dataField="firstName"></dxi-column>

    <dxi-column dataField="lastName"></dxi-column>

    <dxi-column dataField="isActive" caption="Active" dataType="boolean" [width]="65"></dxi-column>
  </dx-data-grid>
</div>
