<div class="page">

  <js-loading-spinner *ngIf="loading"></js-loading-spinner>

  <div class="page max-w-800 m-auto">

    <dx-form id="form" *ngIf="!loading" [(formData)]="packageControlForm" [readOnly]="false"
      [showColonAfterLabel]="true" validationGroup="formData" labelLocation="left" [minColWidth]="500" [colCount]="1"
      [width]="800">

      <dxi-item itemType="group">
        <dxi-item dataField="requestEmail" editorType="dxTextBox">
          <dxo-label location="left" alignment="right" text="Hold Request Email Address(es)">
          </dxo-label>
        </dxi-item>

        <dxi-item dataField="expiryEmail" editorType="dxTextBox">
          <dxo-label location="left" alignment="right" text="Hold Expiry Email Address(es)">
          </dxo-label>
        </dxi-item>

        <!-- <dxi-item dataField="declineText" editorType="dxTextBox">
          <dxo-label location="left" alignment="right" text="Decline Email Text">
          </dxo-label>
        </dxi-item> -->
      </dxi-item>

      <dxi-item itemType="group">
        <dxi-item dataField="modifiedUserFullName" [editorOptions]="{ width: '250px', disabled: true }" editorType="dxTextBox">
          <dxo-label location="left" alignment="right" text="Last modified by"></dxo-label>
        </dxi-item>

        <dxi-item dataField="modifiedDate" [editorOptions]="{ width: '120px', disabled: true }" editorType="dxDateBox">
          <dxo-label location="left" alignment="right" text="Last modified"></dxo-label>
        </dxi-item>
      </dxi-item>

    </dx-form>


    <dx-button *ngIf="!loading" text="Update" class="mt-1" type="default" (onClick)="update()">
    </dx-button>

  </div>
</div>
