import { BrokerService } from './../services/felixApi/broker.service';
import { GlobalService } from './../services/global.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Marker } from '../dtos/marker';
import { Subscription } from 'rxjs';
import { NotificationService } from '../services/notification.service';
import { OnHoldStatusTypeEnum } from '../dtos/onHoldStatusTypeEnum';
import { BrokerJob } from '../dtos/broker-job';
import { environment } from '../../environments/environment';
import { Broker } from '../dtos/broker';
import { UserTypeEnum } from '../dtos/user-type.enum';
import { MapDataType, MapDataTypeEnum } from '../dtos/mapDataType';
import { SuburbService } from '../services/felixApi/suburb.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RequestHoldComponent } from '../broker-view/request-hold/request-hold.component';
import { EstateService } from '../services/felixApi/estate.service';
import { ConfigurationEnum } from '../dtos/configuration-enum';
import { CompanyService } from '../services/felixApi/company.service';
import { AuthService } from '../services/auth.service';


@Component({
  selector: 'js-map-view',
  templateUrl: './map-view.component.html',
  styleUrls: ['./map-view.component.scss']
})
export class MapViewComponent implements OnInit, OnDestroy {

  subscriptions: Subscription[] = [];
  loadingData = true;
  customMarkerUrl: string;
  markers: Marker[] = [];
  keys = {};
  showPopup = false;
  addressText: string;
  popupTitle: string;
  mapHeight: number;
  showAvailable = true;
  showPending = false;
  showApproved = false;
  showSold = false;
  showHoldPopup: boolean;
  salesDate: Date;
  showLoadingSpinner = false;
  brokerJob: BrokerJob;
  selectedBrokerId: number;
  brokers: Broker[] = [];
  userTypeEnum = UserTypeEnum;
  isBroker: boolean;
  isDeveloper: boolean;
  isOfficeUser: boolean;
  mapDataTypes: MapDataType[] = [];
  maplocation: number[];
  brokerJobs: BrokerJob[];

  constructor(
    private brokerService: BrokerService,
    private notiService: NotificationService,
    private globalService: GlobalService,
    private suburbService: SuburbService,
    private estateService: EstateService,
    private modalService: NgbModal,
    private compService: CompanyService,
    private authService: AuthService
  ) {
    this.onMarkerClick = this.onMarkerClick.bind(this);
  }

  ngOnInit(): void {
    this.setHeightWidths();
    this.subscriptions.push(
      this.globalService.innerHeightWidthChanged.subscribe(
        () => {
          this.showHoldPopup = false;
          setTimeout(() => {
            this.setHeightWidths();
          }, 200); // wait for iPhone and grid
        }
      )
    );

    this.keys["google"] = environment.googleMapsKey;

    this.mapDataTypes.push({ id: MapDataTypeEnum.Available, description: 'Available' });
    this.mapDataTypes.push({ id: MapDataTypeEnum.Pending, description: 'Pending' });
    this.mapDataTypes.push({ id: MapDataTypeEnum.Allocated, description: 'Approved' });
    this.mapDataTypes.push({ id: MapDataTypeEnum.Sold, description: 'Sold' });
    this.mapDataTypes.push({ id: MapDataTypeEnum.All, description: 'All' });

    if (this.globalService.userTypeId === this.userTypeEnum.SalesBroker) {
      this.isBroker = true;
      this.getData();
    } else if (this.globalService.userTypeId === this.userTypeEnum.Developer) {
      this.isDeveloper = true;
      this.getData();
    } else {
      if (this.compService.companyConfigs?.find(i => i.configurationId === ConfigurationEnum.PackageSystemActive)?.configurationValue) {
        if (this.globalService.userTypeId === this.userTypeEnum.Admin || this.globalService.userTypeId === this.userTypeEnum.SuperUser) {
          this.isOfficeUser = true;
          this.getOfficeData();
        } else {
          const maintPerm = this.authService.areaPermissions.find(i => i.applicationArea === 'Packages');
          if (maintPerm?.permissionType === 'Admin') {
            this.isOfficeUser = true;
            this.getOfficeData();
          } else {
            this.isBroker = true;
            this.getData();
          }
        }
      } else {
        this.notiService.showError('Cannot access the packages system for this company.');
      }
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  setHeightWidths() {
    this.mapHeight = window.innerHeight - 90;
  }

  getOfficeData() {
    this.subscriptions = this.subscriptions.concat(
      this.brokerService.getMapData(true)
        .subscribe({
          next: (brokers) => {
            this.brokers = brokers;
            this.getBrokerJobs();
          },
          error: () => {
            this.loadingData = false;
          }
        })
    );
  }

  getData() {
    this.subscriptions = this.subscriptions.concat(
      this.brokerService.getSuburbsAndEstates(true)
        .subscribe({
          next: () => {
            this.getBrokerJobs();
          },
          error: () => {
            this.loadingData = false;
          }
        })
    );
  }

  getBrokerJobs() {
    this.showLoadingSpinner = true;

    this.subscriptions = this.subscriptions.concat(
      this.brokerService.getBrokerJobs(this.showSold, this.selectedBrokerId)
        .subscribe({
          next: (brokerJobs) => {
            this.brokerJobs = brokerJobs;
            this.showLoadingSpinner = false;
            this.filterRecords();
          },
          error: (err) => {
            this.loadingData = false;
            this.showLoadingSpinner = false;
            this.notiService.notify(err);
          }
        })
    );
  }

  filterRecords() {
    this.markers = [];
    this.addressText = null;
    this.salesDate = null;
    this.showHoldPopup = false;
    let suburbOffset = 0;
    let estateOffset = 0;

    this.brokerJobs.forEach(brokerJob => {
      // only show the one job once
      if (!this.markers.find(i => i.jobId === brokerJob.jobId)) {
        if ((this.showSold && brokerJob.job.salesDate)
          || (this.showAvailable && brokerJob.onHoldStatusTypeId === OnHoldStatusTypeEnum.Available)
          || (this.showPending && brokerJob.onHoldStatusTypeId === OnHoldStatusTypeEnum.WaitingApproval)
          || (this.showApproved && brokerJob.onHoldStatusTypeId === OnHoldStatusTypeEnum.Approved)) {

          this.maplocation = [];
          let isSuburbMapLocation = false;
          let isEstateMapLocation = false;

          if (brokerJob.job.jobAddress.latitude && brokerJob.job.jobAddress.longitude) {
            this.maplocation = [brokerJob.job.jobAddress.latitude, brokerJob.job.jobAddress.longitude];

          } else if (this.estateService.estateHasLocation(brokerJob.job.estateMasterId)) {
            // get Estate location
            const estate = this.estateService.estates.find(i => i.id === brokerJob.job.estateMasterId);
            this.maplocation = [estate.latitude, estate.longitude + estateOffset];
            isEstateMapLocation = true;
            estateOffset = estateOffset + .0005;
          } else if (this.suburbService.suburbHasLocation(brokerJob.job)) {
            // suburb
            const suburb = this.suburbService.suburbs
              .find(i => i.suburbTown.toLowerCase().trim() === brokerJob.job.jobAddress.suburbTown?.toLowerCase().trim()
                && i.state?.toLowerCase().trim() === brokerJob.job.jobAddress.state?.toLowerCase().trim()
                && i.postCode?.toLowerCase().trim() === brokerJob.job.jobAddress.postCode?.toLowerCase().trim());

            if (suburb) {
              this.maplocation = [suburb.latitude, suburb.longitude + suburbOffset];
              isSuburbMapLocation = true;
              // so we can avoid suburb markers on top of each other
              suburbOffset = suburbOffset + .0005;
            }
          }

          if (this.maplocation.length) {
            this.markers.push(
              {
                jobId: brokerJob.jobId,
                jobNumber: brokerJob.job.jobNumber,
                jobAddressString: brokerJob.job.jobAddressString,
                location: this.maplocation,
                onClick: '',
                tooltip: {
                  isShown: false,
                  text: 'Job ' + brokerJob.job.jobNumber + ':  ' + this.globalService.getJobString(brokerJob.job, false, true)
                    + (isEstateMapLocation ? '(Estate location only)' : isSuburbMapLocation ? ' (Suburb location only)' : '')
                    + (brokerJob.job.salesDate ? ' - (Sold)' : '')
                }
              }
            );
          }
        }
      }
    });

    if (this.markers.length) {
      this.markers.forEach((e) => {
        e.onClick = () => {
          this.onMarkerClick(e);
        };
      });
    } else {
      this.notiService.showInfo('No packages to display');
    }
    this.loadingData = false;
  }

  onMarkerClick(e) {
    // Handle onClick event for markers
    if (this.isBroker) {
      this.brokerJob = this.brokerService.brokerJobs.find(i => i.jobId == e.jobId);
      this.popupTitle = '#' + e.jobNumber;
      this.addressText = this.globalService.getJobString(this.brokerJob.job, false, true);

      this.salesDate = null;
      this.showHoldPopup = false;

      if (this.brokerJob.job.salesDate) {
        this.salesDate = this.brokerJob.job.salesDate;
      } else {
        this.showHoldPopup = this.brokerJob.onHoldStatusTypeId === OnHoldStatusTypeEnum.Available;
      }
    }
  }

  requestHold() {
    this.showHoldPopup = false;
    const modalRef = this.modalService.open(RequestHoldComponent);
    modalRef.componentInstance.brokerJob = this.brokerJob;

    modalRef.result.then(() => {
      this.getBrokerJobs();
    });
  }

  showAvailableChanged($event) {
    this.showAvailable = $event.value;
    this.filterRecords();
  }

  showPendingChanged($event) {
    this.showPending = $event.value;
    this.filterRecords();
  }

  showApprovedChanged($event) {
    this.showApproved = $event.value;
    this.filterRecords();
  }

  showSoldChanged($event) {
    this.showSold = $event.value;
    this.getBrokerJobs();
  }
}
