import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { NotificationService } from '../../services/notification.service';
import { JobSearchTypeEnum } from '../../dtos/job-search-type.enum';
import { JobService } from '../../services/felixApi/job.service';
import { GlobalService } from '../../services/global.service';
import { JobSearchModalComponent } from '../job-search-modal/job-search-modal.component';
import { CompanyActivityService } from '../../services/felixApi/company-activity.service';

@Component({
  selector: 'js-job-number',
  templateUrl: './job-number.component.html',
  styleUrls: ['./job-number.component.scss']
})
export class JobNumberComponent implements OnInit, OnDestroy {
  @Input() maxJobStringWidth: number;

  @Output() refreshJobNumber: EventEmitter<string> =
    new EventEmitter<string>();

  subscriptions: Subscription[] = [];
  jobId: number;
  jobString: string;
  jobAddress: string;
  jobStringMaxWidth: number;
  jobNumber = '';
  newJobNumberUpperCase = '';
  isRestrictedScreen: boolean;
  currentActivity = '';

  constructor(
    private globalService: GlobalService,
    private notiService: NotificationService,
    public jobService: JobService,
    private companyActivityService: CompanyActivityService,
    private modalService: NgbModal) { }

  ngOnInit() {
    this.setJobStringMaxWidth();
    this.subscribeToInnerWidth();

    this.jobNumber = this.jobService.getCurrentJob();
    this.newJobNumberUpperCase = this.jobNumber.toUpperCase().trim();
    if (this.jobNumber && this.jobNumber !== '') {
      this.getJob();
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  setJobStringMaxWidth() {
    this.jobStringMaxWidth = window.innerWidth - 20 - 194; // grid width minus margin - job field
    if (window.innerWidth < 800) {
      this.isRestrictedScreen = true;
    } else {
      this.isRestrictedScreen = false;
      this.jobStringMaxWidth = this.jobStringMaxWidth - 100; // grid width minus job number text
    }

    if (this.maxJobStringWidth && this.maxJobStringWidth < this.jobStringMaxWidth) {
      this.jobStringMaxWidth = this.maxJobStringWidth;
    }
  }

  subscribeToInnerWidth() {
    this.subscriptions = this.subscriptions.concat(
      this.globalService.innerHeightWidthChanged.subscribe(
        () => {
          setTimeout(() => {
            this.setJobStringMaxWidth();
          }, 550); // wait for iPhone
        }
      )
    );
  }

  onJobNumberChanged() {
    this.jobNumber = this.jobNumber.trim();
    if (this.jobNumber && this.jobNumber !== '' && this.newJobNumberUpperCase !== this.jobNumber.toUpperCase()) {
      this.getJob();
    }
  }

  getJob() {
    this.jobString = 'Loading...';
    this.jobAddress = '';
    this.currentActivity = '';
    this.subscriptions.push(
      this.jobService.getJob(this.jobNumber)
        .subscribe({
          next: (currentJob) => {
            if (currentJob) {
              this.jobId = currentJob.id;
              this.jobNumber = currentJob.jobNumber;
              this.newJobNumberUpperCase = this.jobNumber.toUpperCase();
              this.jobString = currentJob.contractName;
              this.jobAddress = this.globalService.getJobString(currentJob, false, true);
              this.jobService.setCurrentJob(this.jobNumber);
              this.jobService.currentJob = currentJob;
              this.jobService.currentJob.jobAddressString = this.jobAddress;
              this.getJobExtras();
              this.refreshJobNumber.emit(this.jobNumber);
            } else {
              this.clearJob();
            }
          },
          error: () => {
            this.clearJob();
          }
        })
    );
  }

  clearJob() {
    this.jobId = null;
    this.jobString = 'Not found';
    this.jobAddress = '';
    this.jobService.currentJob = null;
    this.jobService.currentJobExtra = null;
    this.refreshJobNumber.emit(null);
  }

  jobSearch() {
    const modalRef = this.modalService.open(JobSearchModalComponent, { windowClass: 'modal-1000' });
    modalRef.componentInstance.searchType = JobSearchTypeEnum.AllJobs;

    modalRef.result.then((jobNumber) => {
      if (jobNumber) {
        this.jobNumber = jobNumber;
        this.getJob();
      }
    });
  }

  getJobExtras() {
    this.subscriptions.push(
      this.jobService.getJobExtras(this.jobId)
        .subscribe({
          next: (jobExtra) => {
            this.jobService.currentJobExtra = jobExtra;
            this.currentActivity = this.companyActivityService
              .activities?.find(i => i.id === this.jobService.currentJobExtra?.currentActivityId)?.description;

            this.setActivityNotSold();
          },
          error: (err) => {
            this.notiService.notify(err);
            this.jobService.currentJobExtra = null;
          }
        })
    );
  }

  setActivityNotSold() {
    if ((!this.currentActivity || this.currentActivity === '') && !this.jobService.currentJob.salesDate) {
      this.currentActivity = 'Pending Sale';
    }
  }
}
