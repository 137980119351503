import { Injectable } from '@angular/core';
import { Observable, forkJoin, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../global.service';
import { HttpService } from '../http.service';
import { Developer } from '../../dtos/developer';
import { AuthService } from '../auth.service';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DeveloperService {

  developerURL = environment.apiUrl + '/developers';
  developers: Developer[];
  developersCompany: string;

  constructor(
    private http: HttpClient,
    private httpService: HttpService,
    private authService: AuthService,
    private globalService: GlobalService) { }

  getDevelopers(useCache: boolean) {
    if (useCache && this.developers && this.developers.length && this.developersCompany === this.globalService.getCurrentCompanyId()) {
      return of(this.developers);
    } else {
      return this.http.get<Developer[]>(this.developerURL, this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.developers = res;
          this.developersCompany = this.globalService.getCurrentCompanyId();
        }),
        catchError(this.globalService.handleError));
    }
  }

  addDeveloper(dataRecord: any): Observable<Developer> {
    return this.http.post<Developer>(this.developerURL, JSON.stringify(dataRecord, this.globalService.jsonReplacer), this.httpService.getHttpOptions());
  }

  updateDeveloper(id: string, itm: any): Observable<Developer> {
    const url = this.developerURL + '/' + id;
    return this.http.patch<Developer>(url, JSON.stringify(itm, this.globalService.jsonReplacer), this.httpService.getHttpOptions());
  }

  deleteDeveloper(id: string) {
    const url = this.developerURL + '/' + id;
    return this.http.delete(url, this.httpService.getHttpOptions());
  }
}
