import { Component, OnDestroy, OnInit } from '@angular/core';
import { JobService } from '../services/felixApi/job.service';
import { IJobDocument } from '../dtos/job-document';
import { Subscription } from 'rxjs';
import { JobDocumentService } from '../services/felixApi/job-document.service';
import { NotificationService } from '../services/notification.service';
import { GlobalService } from '../services/global.service';
import { AttachmentTypeEnum } from '../dtos/attachment-type.enum';
import { JobDocumentRecordTypeEnum } from '../dtos/job-document-record-type.enum';
import { saveAs } from 'file-saver';

@Component({
  selector: 'js-job-documents',
  templateUrl: './job-documents.component.html',
  styleUrls: ['./job-documents.component.scss']
})
export class JobDocumentsComponent implements OnInit, OnDestroy {
  jobId: number;
  jobDocuments: IJobDocument[];
  loadingDocs: boolean;
  subscriptions: Subscription[] = [];
  gridHeight: number;
  gridWidth: number;
  attachmentTypeEnum = AttachmentTypeEnum;
  jobDocumentRecordTypeEnum = JobDocumentRecordTypeEnum;

  constructor(private jobService: JobService,
    private globalService: GlobalService,
    private jobDocumentService: JobDocumentService,
    private notiService: NotificationService,
  ) {
    this.download = this.download.bind(this);
  }

  ngOnInit() {
    this.setHeightWidths();

    this.subscriptions.push(
      this.globalService.innerHeightWidthChanged.subscribe(
        () => {
          setTimeout(() => {
            this.setHeightWidths();
          }, 200); // wait for iPhone and grid
        }
      )
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  setHeightWidths() {
    this.gridHeight = window.innerHeight - 110;
    this.gridWidth = window.innerWidth < 800 ? window.innerWidth : 800;
  }

  onJobNumberChanged(jobNo: string) {
    this.jobId = this.jobService.currentJob?.id;

    if (this.jobId) {
      this.getJobDocuments();
    }
  }

  getJobDocuments() {
    // clear cache
    this.jobDocumentService.cache = [];
    this.jobDocumentService.cacheSharePoint = [];
    this.jobDocuments = [];

    this.loadingDocs = true;
    this.subscriptions = this.subscriptions.concat(
      this.jobDocumentService.getJobDocuments(this.jobService.currentJob.id)
        .subscribe({
          next: (res) => {
            this.jobDocuments = res;
            this.loadingDocs = false;
          },
          error: (err) => {
            this.notiService.notify(err);
            this.jobDocuments = [];
            this.loadingDocs = false;
          }
        })
    );
  }

  isDownloadVisible = (e) => {
    return e.row.data.recordTypeId === JobDocumentRecordTypeEnum.Detail;
  }

  download(e) {
    if (!e.row.data.isSharePoint) {
      this.subscriptions = this.subscriptions.concat(
        this.jobDocumentService.getJobDocAttachment(e.row.data.id, false).subscribe({
          next: (res) => {
            this.saveFile(this.globalService.base64ToArrayBuffer(res.attachment, res.attachmentName), res.attachmentName, res.attachmentTypeId);
          },
          error: (err) => {
            this.notiService.notify(err);
          }
        })
      );
    } else {
      this.subscriptions = this.subscriptions.concat(
        this.jobDocumentService.getSharePointDocument(this.jobService.currentJob?.jobNumber, e.row.data.sharePointId, false).subscribe({
          next: (res) => {
            this.saveFile(this.globalService.base64ToArrayBuffer(res.attachment, res.name), res.name, res.attachmentTypeId);
          },
          error: (err) => {
            this.notiService.notify(err);
          }
        })
      );
    }
  }

  saveFile(blob: Blob, saveName: string, attachmentTypeId: number) {
    if (attachmentTypeId && !saveName.includes('.')) {
      saveName += '.' + AttachmentTypeEnum[attachmentTypeId];
    }
    saveAs(blob, saveName);
  }

  updateFileDate(docId: number, fileModifiedDate: Date) {
    const doc = this.jobDocuments.find(d => d.id === docId);
    doc.fileModifiedDate = fileModifiedDate;
  }
}
