import { Developer } from './../../dtos/developer';
import { DeveloperService } from './../../services/felixApi/developer.service';
import { GlobalService } from '../../services/global.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import { Subscription } from 'rxjs';
import { EstateService } from '../../services/felixApi/estate.service';
import { Estate } from '../../dtos/estate';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EstateAttachmentsComponent } from '../estate-attachments/estate-attachments.component';
import { GridService } from '../../services/grid.service';

@Component({
  selector: 'js-estates',
  templateUrl: './estates.component.html',
  styleUrls: ['./estates.component.scss']
})
export class EstatesComponent implements OnInit, OnDestroy {

  subscriptions: Subscription[] = [];
  gridHeight: number;
  estates: Estate[];
  dataSource: DataSource;
  showSold = false;
  loadingData = true;
  developers: Developer[];

  constructor(
    private estateService: EstateService,
    private globalService: GlobalService,
    private developerService: DeveloperService,
    protected gridService: GridService,
    private modalService: NgbModal) {
    this.maintainAttachments = this.maintainAttachments.bind(this);
  }

  ngOnInit() {
    this.gridHeight = window.innerHeight - 120;
    this.getData();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  getData() {
    this.subscriptions = this.subscriptions.concat(
      this.developerService.getDevelopers(true)
        .subscribe({
          next: (developers) => {
            this.developers = developers;
            this.loadingData = false;
            this.setUpDataSource();
          },
          error: () => {
            this.loadingData = false;
          }
        })
    );
  }

  setUpDataSource() {
    this.dataSource = new DataSource({
      key: 'id',
      load: async () => {
        return new Promise((resolve, reject) =>
          this.estateService.getEstates(false).subscribe({
            next: (res) => {
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      },
      insert: async (values) => {
        return new Promise((resolve, reject) =>
          this.estateService.addEstate(values).subscribe({
            next: (res) => {
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      },
      update: async (key, values) => {
        return new Promise((resolve, reject) =>
          this.estateService.updateEstate(encodeURIComponent(key), values).subscribe({
            next: (res) => {
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      },
      remove: async (key) => {
        return new Promise((resolve, reject) =>
          this.estateService.deleteEstate(encodeURIComponent(key)).subscribe({
            next: () => {
              return resolve();
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      }
    });
  }

  maintainAttachments(e) {
    if (e.row.data.id) {
      const modalRef = this.modalService.open(EstateAttachmentsComponent, { windowClass: 'modal-edit' });
      modalRef.componentInstance.estateId = e.row.data.id;
      modalRef.componentInstance.titleDescription = e.row.data.name;

      modalRef.result.then(() => { });
    }
  }
}
