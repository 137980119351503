import { CompanyService } from '../../services/felixApi/company.service';
import { NotificationService } from '../../services/notification.service';
import { Component, OnInit, Input, OnDestroy, ViewChild } from '@angular/core';
import { JobService } from '../../services/felixApi/job.service';
import { Job } from '../../dtos/job';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { JobSearchTypeEnum } from '../../dtos/job-search-type.enum';
import { GlobalService } from '../../services/global.service';
import { UserTypeEnum } from '../../dtos/user-type.enum';
import { Company } from '../../dtos/company';
import { AuthService } from '../../services/auth.service';
import { Subscription } from 'rxjs';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { User } from '../../dtos/user';
import DataSource from 'devextreme/data/data_source';
import { GridService } from '../../services/grid.service';

@Component({
  selector: 'js-job-search-modal',
  templateUrl: './job-search-modal.component.html',
  styleUrls: ['./job-search-modal.component.scss']
})
export class JobSearchModalComponent implements OnInit, OnDestroy {
  @Input() searchType: number;

  @ViewChild('dataGrid', { static: false }) dataGrid: DxDataGridComponent;

  subscriptions: Subscription[] = [];
  errorMessage: any;
  formErrorMessage = '';
  jobs: Job[] = [];
  jobSearchTypeEnum = JobSearchTypeEnum;
  myJobsOnly = false;
  templatesOnly = false;
  loading = true;
  currentCompany: Company;
  userType = UserTypeEnum;
  innerWidth: number; // width of the screen.
  isRestrictedForm = false; // if Client or Trade or the screen width is too small then we restrict the form
  activeJobsOnly = true; // do we want just active jobs only - default yes
  includeInactive = false; // mat check needs another flag
  searchString: string;
  canOnlySeeMyJobs: boolean;
  jobData: DataSource;
  selectedJobNumber: any;
  gridHeight = 200;
  users: User[] = [];
  assigneeId: number;
  showAssignedUser: boolean;
  showSiteManager: boolean;
  showSalesRep: boolean;
  showJobsHandedOver = false;

  constructor(
    private _jobService: JobService,
    private _activeModal: NgbActiveModal,
    private _authService: AuthService,
    private companyService: CompanyService,
    private notiService: NotificationService,
    protected gridService: GridService,
    private _globalService: GlobalService) { }


  ngOnInit(): void {
    this.currentCompany = this._globalService.getCurrentCompany();

    this.innerWidth = window.innerWidth;
    this.calcIsRestrictedForm();
    this.subscribeToWidthChanges();
    // this.getUsers();

    this.loadJobs();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  subscribeToWidthChanges() {
    this.subscriptions = this.subscriptions.concat(
      this._globalService.innerHeightWidthChanged.subscribe(width => {
        this.innerWidth = window.innerWidth;
        this.calcIsRestrictedForm();
      })
    );
  }

  calcIsRestrictedForm() {
    this.showAssignedUser = false;
    this.showSalesRep = false;
    this.showSiteManager = false;
    if (this._authService.isClient() || this._authService.isAssociate() || this.innerWidth < 700) {
      this.isRestrictedForm = true;
    } else {
      this.isRestrictedForm = false;
      if (this.innerWidth > 1000) {
        this.showAssignedUser = true;
        if (this.innerWidth > 1150) {
          this.showSiteManager = true;
          if (this.innerWidth > 1400) {
            this.showSalesRep = true;
          }
        }
      }
    }

    this.gridHeight = window.innerHeight - 210;
  }

  loadJobs() {
    // because we entered an address search clear the other search fields
    this.jobs = [];
    this.formErrorMessage = '';
    this.myJobsOnly = false;
    this.templatesOnly = false;
    this.loading = true;
    this.assigneeId = null;

    this.subscriptions = this.subscriptions.concat(
      this._jobService.getJobsByAddressWithExtras(false, false, true)
        .subscribe({
          next: (jobs) => {
            this.jobs = jobs;
            this.createJobDataSource();
          },
          error: (error) => {
            this.notiService.showError(error);
            this.loading = false;
          }})
    );
  }

  cancel() {
    this._activeModal.close(null);
  }

  onSelectionChanged(event) {
    if (event?.selectedRowKeys?.length > 0) {
      this._activeModal.close(event.selectedRowsData[0].jobNumber);
    }
  }

  createJobDataSource() {
    this.clearSearch();

    const shownJobs: Job[] = [];

    this.jobs.filter(i => i.isActive).forEach(job => {
      job.currentActivityDesc = '';
      job.currentActivityCode = null; // default at the end

      const jobExtra = this._jobService.jobExtras?.find(i => i.jobId === job.id);
      if (jobExtra) {
        if (jobExtra.cancellationDate) {
          job.currentActivityDesc = 'Cancelled';
        } else if (jobExtra.isOnHold) {
          job.currentActivityDesc = 'On Hold';
        } else if (!jobExtra.handoverDate || this.showJobsHandedOver) {
          const activity = this.companyService.activities?.find(i => i.id === jobExtra.currentActivityId);
          if (activity) {
            job.currentActivityDesc = activity.description;
            job.currentActivityCode = activity.activityCode;
          } else {
            if (!job.isActive) {
              job.currentActivityDesc = 'Inactive Job';
            } else if (!job.salesDate) {
              job.currentActivityDesc = 'Pending Sale';
              job.currentActivityCode = '';
            }
          }
        }
      } else {
        if (!job.isActive) {
          job.currentActivityDesc = 'Inactive Job';
        } else if (!job.salesDate) {
          job.currentActivityDesc = 'Pending Sale';
          job.currentActivityCode = '';
        }
      }
      shownJobs.push(job);
    });

    this.jobData = new DataSource({
      key: 'jobNumber',
      loadMode: 'raw',
      load: () => shownJobs
    });
    this.loading = false;
  }

  runFilter(data) {
    if (!data.value || data.value === '') {
      this.dataGrid?.instance.clearFilter('search');
    } else {
      this.dataGrid?.instance.searchByText(data.value);
    }
  }

  clearSearch() {
    this.searchString = '';
    if (this.dataGrid && this.dataGrid.instance) {
      this.dataGrid.instance.clearFilter('search');
    }
  }

  calculateActivitySort(data) {
    return data.currentActivityCode;
  }
}
