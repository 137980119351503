<div class="page">
  <js-loading-spinner *ngIf="loadingData" [usePopUp]="false"></js-loading-spinner>

  <dx-data-grid *ngIf="!loadingData" #packageGrid id="poGrid" [dataSource]="dataSource" [allowColumnReordering]="true"
    [height]="gridHeight" [width]="1000" [allowColumnResizing]="true" [repaintChangesOnly]="true" [showBorders]="true"
    [rowAlternationEnabled]="true" [columnAutoWidth]="true" [wordWrapEnabled]="true" [hoverStateEnabled]="true"
    class="m-auto" (onExporting)="gridService.onExporting($event, 'Estates')">

    <dxo-search-panel [visible]="true" location="before" [width]="200"></dxo-search-panel>
    <dxo-export [enabled]="true"></dxo-export>
    <dxo-scrolling mode="standard"></dxo-scrolling>
    <dxo-paging [enabled]="false"></dxo-paging>
    <dxo-header-filter [visible]="true"></dxo-header-filter>

    <dxo-editing mode="row" [allowUpdating]="true" [allowAdding]="true" [allowDeleting]="true" [useIcons]="true"
      startEditAction="click" refreshMode="full">
    </dxo-editing>

    <dxi-column dataField="id" dataType="number" [visible]='false'></dxi-column>

    <dxi-column dataField="name" caption="Estate Name" [minWidth]="250" [hidingPriority]="1">
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>

    <dxi-column dataField="developerId" dataType="number" caption="Developer" [editorOptions]="{showClearButton: true}">
      <dxo-lookup [dataSource]="developers" valueExpr="id" displayExpr="description"> </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="latitude" dataType="number" [width]="110"></dxi-column>

    <dxi-column dataField="longitude" dataType="number" [width]="110"></dxi-column>

    <dxi-column dataField="isActive" caption="Active" dataType="boolean" [width]="90" [hidingPriority]="2"></dxi-column>

    <dxi-column type="buttons" [width]="110" [fixed]="true">
      <dxi-button name="edit"></dxi-button>
      <dxi-button name="delete"></dxi-button>
      <dxi-button icon="attach" hint='attachments' [onClick]="maintainAttachments"></dxi-button>
    </dxi-column>

  </dx-data-grid>
</div>
