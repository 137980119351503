import { NotificationService } from './../../services/notification.service';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalService } from '../../services/global.service';
import { Subscription } from 'rxjs';
import DataSource from 'devextreme/data/data_source';
import { UserService } from '../../services/felixApi/user.service';
import { GridService } from '../../services/grid.service';
import { UserTypeEnum } from '../../dtos/user-type.enum';
import { User } from '../../dtos/user';

@Component({
  selector: 'js-broker-users',
  templateUrl: './broker-users.component.html',
  styleUrls: ['./broker-users.component.scss']
})
export class BrokerUsersComponent implements OnInit, OnDestroy {
  @Input() brokerId: number;
  @Input() titleDescription: string;

  subscriptions: Subscription[] = [];
  gridHeight: number;
  dataSource: DataSource;
  userTypeEnum = UserTypeEnum;
  userTypes = [{ id: UserTypeEnum.SalesBroker, name: 'External Broker' }, { id: UserTypeEnum.Office, name: 'Office Sales Rep' }];
  officeUsers: User[];
  dropDownOptions: { width: number; minHeight: number; };

  constructor(
    private _activeModal: NgbActiveModal,
    private userService: UserService,
    private notiService: NotificationService,
    protected gridService: GridService,
    private globalService: GlobalService) {
    this.setEmailCellValue = this.setEmailCellValue.bind(this);
    this.setUserTypeCellValue = this.setUserTypeCellValue.bind(this);
  }


  ngOnInit(): void {
    this.dropDownOptions = {
      width: window.innerWidth < 500 ? window.innerWidth : 500,
      minHeight: window.innerHeight < 400 ? window.innerHeight : 400
    };

    this.setHeightWidths();
    this.subscriptions = this.subscriptions.concat(
      this.globalService.innerHeightWidthChanged.subscribe(
        () => {
          this.setHeightWidths();
        }
      )
    );

    this.setUpDataSource();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  setHeightWidths() {
    this.gridHeight = window.innerHeight - 300;
  }

  cancel() {
    this._activeModal.dismiss();
  }

  setUpDataSource() {
    this.dataSource = new DataSource({
      key: 'userId',
      load: async () => {
        return new Promise((resolve, reject) =>
          this.userService.getBrokerUsersExtras(this.brokerId).subscribe({
            next: (res) => {
              res.forEach(user => {
                user.mapViewPermission = this.userService.calculateMapViewPermission(user.userId);
                user.packagesPermission = this.userService.calculatePackagesPermission(user.userId);
                user.progressPermission = this.userService.calculateProgressPermission(user.userId);
                user.documentsPermission = this.userService.calculateDocumentsPermission(user.userId);
              });
              this.officeUsers = this.userService.officeUsers;
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      },
      insert: async (values) => {
        values.brokerId = this.brokerId;
        return new Promise((resolve, reject) =>
          this.userService.addOfficeUserToBroker(values).subscribe({
            next: (res) => {
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      },
      update: async (key, values) => {
        values.brokerId = this.brokerId;
        return new Promise((resolve, reject) =>
          this.userService.updateUserCompany(encodeURIComponent(key), values).subscribe({
            next: (res) => {
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      },
      remove: async (key) => {
        return new Promise((resolve, reject) =>
          this.userService.deleteBrokerUser(encodeURIComponent(key), this.brokerId).subscribe({
            next: () => {
              return resolve();
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      }
    });
  }

  onInitNewRow(e) {
    e.data.mapViewPermission = true;
    e.data.packagesPermission = true;
    e.data.progressPermission = true;
    e.data.documentsPermission = true;
    e.data.isActive = true;
  }

  setUserTypeCellValue(rowData, value, originalData) {
    if (!originalData.userTypeId || !originalData.id) {
      rowData.userTypeId = value;
    } else {
      this.notiService.showWarning('User type cannot be changed');
    }
  }

  setEmailCellValue(rowData, value, originalData) {
    rowData.email = value;

    if ((rowData.userTypeId && rowData.userTypeId === UserTypeEnum.Office) || (originalData.userTypeId && originalData.userTypeId === UserTypeEnum.Office)) {
      const officeUser = this.officeUsers.find(i => i.email === value);

      if (!officeUser) {
        rowData.firstName = 'Not found';
        rowData.lastName = null;
        rowData.userId = null;
        return;
      }
      rowData.firstName = officeUser?.firstName;
      rowData.lastName = officeUser?.lastName;
      rowData.userId = officeUser?.id;
    }
  }

  onUserSelectionChanged(cellInfo, dropDownBox, event) {
    if (event.selectedRowKeys.length > 0) {
      cellInfo.setValue(event.selectedRowsData[0].email);
      dropDownBox.close();
    }
  }

  onEmailValueChanged($event, cellInfo) {
    cellInfo.setValue($event.value);
  }

  onEditorPreparing(e: any) {
    if (e.parentType !== 'dataRow') {
      return;
    } else {
      if (e.dataField === 'firstName' && e.row.data.userTypeId === UserTypeEnum.Office) {
        e.editorOptions.disabled = true;
      }
      if (e.dataField === 'lastName' && e.row.data.userTypeId === UserTypeEnum.Office) {
        e.editorOptions.disabled = true;
      }
      if (e.dataField === 'isActive' && e.row.data.userTypeId === UserTypeEnum.Office) {
        e.editorOptions.disabled = true;
      }
    }
  }
}
