import { VariablesService } from './../../services/variables.service';
import { UserService } from './../../services/felixApi/user.service';
import { NotificationService } from './../../services/notification.service';
import { CompanyService } from './../../services/felixApi/company.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Company } from '../../dtos/company';
import { AuthService } from '../../services/auth.service';
import { GlobalService } from '../../services/global.service';
import { UserTypeEnum } from '../../dtos/user-type.enum';
import { CompanyConfiguration } from '../../dtos/company-configuration';
import { Subscription } from 'rxjs';
import { ConfigurationEnum } from '../../dtos/configuration-enum';

@Component({
  selector: 'js-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit, OnDestroy {

  subscriptions: Subscription[] = [];
  public isCollapsed = true;
  signedIn = false;
  currComp: Company;
  areaSelected = '';
  disabledMenu = false;
  phoneSize: boolean;
  userTypeEnum = UserTypeEnum;
  companyConfiguration: CompanyConfiguration[];
  innerWidth: number;
  invoiceAdmin: boolean;
  invoiceRead: boolean;
  canAccessOrders: boolean;
  canAccessSendPrint: boolean;
  isDeveloper = false;
  isBroker = false;
  isAdmin: boolean;
  canSeeMaintenance: boolean;
  canSeeMapsView: boolean;
  canSeePackages: boolean;
  canSeeProgress: boolean;
  canSeeDocuments: boolean;
  canSeeLand: boolean;
  canSeeLandMaintenance: boolean;
  isLandUser: boolean;
  loadingConfigs: boolean;
  isOfficeUser: boolean;

  constructor(
    private globalService: GlobalService,
    private authService: AuthService,
    private compService: CompanyService,
    private notiService: NotificationService,
    private userService: UserService,
    private variablesService: VariablesService
  ) {
  }

  ngOnInit() {
    this.innerWidth = window.innerWidth;
    this.subscribeToLoginChanges();
    this.subscribeToCompanyChanges();
    this.subscribeToAreaChanges();
    this.subscribeToMenuDisable();
    this.subscribeToInnerWidth();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  menuPress(area) {
    this.globalService.setAreaSelected(area);
  }

  subscribeToAreaChanges() {
    this.subscriptions = this.subscriptions.concat(
      this.globalService.areaChanged.subscribe({
        next: (area) => {
          this.areaSelected = area;
        }
      })
    );
  }

  subscribeToLoginChanges() {
    this.subscriptions = this.subscriptions.concat(
      this.authService.isLoggedInChanged.subscribe({
        next: (isLoggedIn: boolean) => {
          if (!isLoggedIn) {
            this.signedIn = false;
            this.disabledMenu = true;
          } else {
            this.signedIn = true;
          }
        }
      })
    );
  }

  subscribeToCompanyChanges() {
    this.subscriptions = this.subscriptions.concat(
      this.globalService.companySelected.subscribe({
        next: (selectedComp) => {
          this.currComp = selectedComp;
          if (this.currComp.userTypeId === this.userTypeEnum.Admin
            || this.currComp.userTypeId === this.userTypeEnum.SuperUser) {
            this.invoiceAdmin = true;
            this.invoiceRead = true;
            this.canAccessOrders = true;
            this.canAccessSendPrint = true;
            this.globalService.canSeeAdminReports = true;
            this.globalService.invoicePermission = 'Admin';
            this.globalService.ordersPermWrite = true;
          }
          this.getCompanyConfigurations();
        }
      })
    );
  }

  subscribeToMenuDisable() {
    this.subscriptions = this.subscriptions.concat(
      this.globalService.menuDisable.subscribe({
        next: (disable) => {
          if (disable) {
            this.disabledMenu = true;
          } else {
            this.disabledMenu = false;
          }
        }
      })
    );
  }

  subscribeToInnerWidth() {
    this.subscriptions = this.subscriptions.concat(
      this.globalService.innerHeightWidthChanged.subscribe({
        next: () => {
          this.innerWidth = window.innerWidth;
        }
      })
    );
  }

  getCompanyConfigurations() {
    this.loadingConfigs = true;
    this.subscriptions = this.subscriptions.concat(
      this.compService.getCompanyConfigurations().subscribe({
        next: () => {
          const perms = this.userService.userBrokerPermissions;
          this.setPermissions();
          this.loadingConfigs = false;
        },
        error: (err) => {
          this.notiService.notify(err);
          this.loadingConfigs = false;
        }
      })
    );
  }

  setPermissions() {
    this.isBroker = false;
    this.isDeveloper = false;
    this.canSeeMaintenance = false;
    this.globalService.packagesAdmin = false;
    this.globalService.packagesWrite = false;
    this.isOfficeUser = false;
    this.globalService.landAdmin = false;
    this.isLandUser = false;
    this.canSeeLandMaintenance = false;
    this.variablesService.clearMaintenanceSections();

    if (this.globalService.userTypeId === this.userTypeEnum.SalesBroker) {
      this.setBrokerPermissions();
    } else if (this.globalService.userTypeId === this.userTypeEnum.Developer) {
      this.isDeveloper = true;
    } else {
      // Packages Database
      if (this.compService.companyConfigs.find(i => i.configurationId === ConfigurationEnum.PackageSystemActive)?.configurationValue) {
        if (this.globalService.userTypeId === this.userTypeEnum.Admin || this.globalService.userTypeId === this.userTypeEnum.SuperUser) {
          this.canSeeMaintenance = true;
          this.globalService.packagesAdmin = true;
          this.globalService.packagesWrite = true;
          this.variablesService.setPackagesSections();
        } else {
          const maintPerm = this.authService.areaPermissions.find(i => i.applicationArea === 'Packages');
          if (maintPerm?.permissionType === 'Admin') {
            this.canSeeMaintenance = true;
            this.globalService.packagesAdmin = true;
            this.globalService.packagesWrite = true;
            this.variablesService.setPackagesSections();
          } if (maintPerm?.permissionType === 'Write') {
            // Sales reps can be set up as a broker so they have different READ permissions
            this.globalService.packagesWrite = true;
          } else {
            this.setBrokerPermissions();
          }
        }
      }
      this.isOfficeUser = this.globalService.packagesWrite;
    }


    // Land database
    if (this.compService.companyConfigs.find(i => i.configurationId === ConfigurationEnum.LandDatabase)?.configurationValue) {
      if (this.globalService.userTypeId === this.userTypeEnum.Admin || this.globalService.userTypeId === this.userTypeEnum.SuperUser) {
        this.canSeeLandMaintenance = true;
        this.globalService.landAdmin = true;
        this.variablesService.setLandSections();
        this.isLandUser = true;
      } else {
        const maintPerm = this.authService.areaPermissions.find(i => i.applicationArea === 'LandDatabase');
        if (maintPerm?.permissionType === 'Admin') {
          this.canSeeLandMaintenance = true;
          this.globalService.landAdmin = true;
          this.isLandUser = true;
          this.variablesService.setLandSections();
        } if (maintPerm?.permissionType === 'Write' || maintPerm?.permissionType === 'Read') {
          this.isLandUser = true;
          this.globalService.landRead = true;
        }
      }
    }

    if (!this.isOfficeUser && !this.isBroker && !this.isLandUser) {
      this.notiService.showError('Cannot access this system for this company');
    }
  }

  setBrokerPermissions() {
    this.isBroker = true;
    const currentUser = this.authService.getCurrentUser();
    this.canSeeMapsView = this.userService.calculateMapViewPermission(currentUser.id);
    this.canSeePackages = this.userService.calculatePackagesPermission(currentUser.id);
    this.canSeeProgress = this.userService.calculateProgressPermission(currentUser.id);
    this.canSeeDocuments = this.userService.calculateDocumentsPermission(currentUser.id);

    if (this.userService.userBrokerPermissions[0]?.brokerId) {
      this.globalService.brokerId == this.userService.userBrokerPermissions[0]?.brokerId
    }
  }
}
