<div class="modal-header">
  <h3 class="modal-title">{{titleDescription}} - logo</h3>
  <button type="button" class="close" aria-label="Close" (click)="cancel()" tabindex="-1">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <js-loading-spinner *ngIf="loading"></js-loading-spinner>

  <img *ngIf="imageFound" [src]="'data:image/jpeg;base64,'+logoDto.attachment" />

  <br> <br>

  <dx-file-uploader *ngIf="!loading" [ngClass]="wrongFileType ? 'red-faint-back' : 'b-blue-faint-back'"
    [value]="filesToUpload" uploadMode="useForm" selectButtonText="Select file" labelText="or drop file here"
    (onValueChanged)="handleFileInput($event)">
    <!-- Following seemes to be broken for dx uploader with pdf: handling manually -->
    <!-- allowedFileExtensions="['.pdf','.PDF','.jpg','.jpeg','.JPG','.JPEG','.png','.PNG']"
    invalidFileExtensionMessage="Please select PDF, JPG or PNG only" -->
  </dx-file-uploader>
</div>

<div class="modal-footer">
  <dx-button text="Save" type="default" (onClick)="saveLogo()">
  </dx-button>
</div>
