import { GlobalService } from '../../services/global.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import { Subscription } from 'rxjs';
import { LandService } from '../../services/felixApi/land.service';
import { GridService } from '../../services/grid.service';

@Component({
  selector: 'js-land-hold-categories',
  templateUrl: './land-hold-categories.component.html',
  styleUrls: ['./land-hold-categories.component.scss']
})
export class LandHoldCategoriesComponent implements OnInit, OnDestroy {

  subscriptions: Subscription[] = [];
  gridHeight: number;
  dataSource: DataSource;


  constructor(
    private landService: LandService,
    private globalService: GlobalService,
    protected gridService: GridService,) {
  }

  ngOnInit() {
    this.gridHeight = window.innerHeight - 120;
    this.setUpDataSource();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  setUpDataSource() {
    this.dataSource = new DataSource({
      key: 'id',
      load: async () => {
        return new Promise((resolve, reject) =>
          this.landService.getLandHoldCategories().subscribe({
            next: (res) => {
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      },
      insert: async (values) => {
        return new Promise((resolve, reject) =>
          this.landService.addLandHoldCategory(values).subscribe({
            next: (res) => {
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      },
      update: async (key, values) => {
        return new Promise((resolve, reject) =>
          this.landService.updateLandHoldCategory(encodeURIComponent(key), values).subscribe({
            next: (res) => {
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      },
      remove: async (key) => {
        return new Promise((resolve, reject) =>
          this.landService.deleteLandHoldCategory(encodeURIComponent(key)).subscribe({
            next: () => {
              return resolve();
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      }
    });
  }

  onInitNewRow(e) {
    e.data.isActive = true;
  }
}
