import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from '../../services/notification.service';
import { Subscription } from 'rxjs';
import { BrokerService } from '../../services/felixApi/broker.service';
import { BrokerJob } from '../../dtos/broker-job';

@Component({
  selector: 'js-request-hold',
  templateUrl: './request-hold.component.html',
  styleUrls: ['./request-hold.component.scss']
})
export class RequestHoldComponent implements OnInit, OnDestroy {
  @Input() brokerJob: BrokerJob;

  subscriptions: Subscription[] = [];
  expiryDate: Date;
  showLoadingSpinner: boolean;

  constructor(
    private _activeModal: NgbActiveModal,
    private brokerService: BrokerService,
    private notiService: NotificationService) { }


  ngOnInit(): void {
    this.expiryDate = this.brokerJob.expiryDate;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  cancel() {
    this._activeModal.dismiss();
  }

  requestHold() {
    this.showLoadingSpinner = true;
    this.subscriptions = this.subscriptions.concat(
      this.brokerService.requestHold(this.brokerJob.jobId)
        .subscribe({
          next: () => {
            this.showLoadingSpinner = false;
            this._activeModal.close();
          },
          error: (error) => {
            this.showLoadingSpinner = false;
            this.notiService.notify(error);
          }
        })
    );
  }
}
