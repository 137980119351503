<div class="page">
  <js-loading-spinner *ngIf="loadingData" [usePopUp]="false"></js-loading-spinner>

  <dx-data-grid *ngIf="!loadingData" #packageGrid id="poGrid" [dataSource]="dataSource" [allowColumnReordering]="true" [height]="gridHeight"
    [allowColumnResizing]="true" [repaintChangesOnly]="true" [showBorders]="true" [rowAlternationEnabled]="true"
    [columnAutoWidth]="true" [wordWrapEnabled]="true" [hoverStateEnabled]="true" class="m-auto"
    (onExporting)="gridService.onExporting($event, 'Log')">

    <dxo-search-panel [visible]="true" location="before" [width]="200"></dxo-search-panel>
    <dxo-export [enabled]="true"></dxo-export>
    <dxo-scrolling mode="standard"></dxo-scrolling>
    <dxo-paging [enabled]="false"></dxo-paging>
    <dxo-header-filter [visible]="true"></dxo-header-filter>

    <dxi-column dataField="job.jobNumber" caption="Job" [width]="100" sortOrder="asc"></dxi-column>

    <dxi-column dataField="brokerId" dataType="number" caption="Broker" [width]="200">
      <dxo-lookup [dataSource]="brokers" valueExpr="id" displayExpr="name"> </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="onHoldStatusTypeId" dataType="number" caption="Hold Status">
      <dxo-lookup [dataSource]="onHoldStatusTypes" valueExpr="id" displayExpr="description"> </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="previousOnHoldStatusTypeId" dataType="number" caption="Previous Status">
      <dxo-lookup [dataSource]="onHoldStatusTypes" valueExpr="id" displayExpr="description"> </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="job.address.streetName1" caption="Address" [width]="250"></dxi-column>

    <dxi-column dataField="job.address.suburbTown" caption="Suburb" [width]="150"></dxi-column>

    <dxi-column dataField="job.address.state" caption="State" [width]="90"></dxi-column>

    <dxi-column dataField="job.address.postCode" caption="Post Code" [width]="100"></dxi-column>

    <dxi-column dataField="declineReason"></dxi-column>

    <dxi-column dataField="createDate" dataType="date" caption="Date" [width]="100"></dxi-column>


  </dx-data-grid>
</div>
