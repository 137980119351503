import { Injectable } from '@angular/core';
import { Observable, forkJoin, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../global.service';
import { HttpService } from '../http.service';
import { Broker } from '../../dtos/broker';
import { environment } from '../../../environments/environment';
import { BrokerJob } from '../../dtos/broker-job';
import { Logo } from '../../dtos/logo';
import { AuthService } from '../auth.service';
import { OnHold } from '../../dtos/on-hold';
import { OnHoldStatusTypeEnum } from '../../dtos/onHoldStatusTypeEnum';
import { IJobItemAttachment } from '../../dtos/job-item-attachment';
import { SuburbService } from './suburb.service';
import { EstateService } from './estate.service';
import { Suburb } from '../../dtos/suburb';
import { JobTask } from '../../dtos/job-task';
import { TaskMaster } from '../../dtos/task-master';
import { CompanyActivity } from '../../dtos/company-activity';
import { JobTaskForecast } from '../../dtos/job-task-forecast';
import { ClaimJobLine } from '../../dtos/claim-job-line';

@Injectable({
  providedIn: 'root'
})
export class BrokerService {

  brokerURL = environment.apiUrl + '/brokers';
  brokerJobURL = environment.apiUrl + '/broker-jobs';
  onHoldJobURL = environment.apiUrl + '/on-holds';
  brokers: Broker[];
  brokersCompany: string;
  brokerJobs: BrokerJob[];
  onHolds: OnHold[];
  taskMasters: TaskMaster[];
  taskMastersCompany: number;
  activities: CompanyActivity[] = [];
  activitiesCompany: number;
  forecastTasksCompany: number;
  forecastTasks: JobTaskForecast[] = [];
  nextClaims: ClaimJobLine[] = [];

  constructor(
    private http: HttpClient,
    private httpService: HttpService,
    private authService: AuthService,
    private suburbService: SuburbService,
    private estateService: EstateService,
    private globalService: GlobalService) { }

  getBrokers(useCache: boolean) {
    if (useCache && this.brokers && this.brokers.length && this.brokersCompany === this.globalService.getCurrentCompanyId()) {
      return of(this.brokers);
    } else {
      return this.http.get<Broker[]>(this.brokerURL, this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.brokers = res;
          this.brokersCompany = this.globalService.getCurrentCompanyId();
        }),
        catchError(this.globalService.handleError));
    }
  }

  addBroker(dataRecord: any): Observable<Broker> {
    return this.http.post<Broker>(this.brokerURL, JSON.stringify(dataRecord, this.globalService.jsonReplacer), this.httpService.getHttpOptions());
  }

  updateBroker(id: string, itm: any): Observable<Broker> {
    const url = this.brokerURL + '/' + id;
    return this.http.patch<Broker>(url, JSON.stringify(itm, this.globalService.jsonReplacer), this.httpService.getHttpOptions());
  }

  deleteBroker(id: string) {
    const url = this.brokerURL + '/' + id;
    return this.http.delete(url, this.httpService.getHttpOptions());
  }



  getMapData(useCache: boolean): Observable<Broker[]> {
    return forkJoin(
      [
        this.getBrokers(useCache),
        this.getSuburbsAndEstates(useCache)
      ]
    )
      .pipe(map(
        ([dataRecords]) => {
          return dataRecords;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }

  getSuburbsAndEstates(useCache: boolean): Observable<Suburb[]> {
    return forkJoin(
      [
        this.suburbService.getSuburbs(useCache),
        this.estateService.getEstates(useCache)
      ]
    )
      .pipe(map(
        ([dataRecords]) => {
          return dataRecords;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }


  getBrokerJobs(showSold: boolean, brokerId: number) {
    if (this.authService.getCurrentUser().brokerId) {
      // logged in as a broker
      const url = this.brokerJobURL + '/' + (this.authService.getCurrentUser().brokerId ?? brokerId) + '/jobs?showSold=' + showSold;
      return this.http.get<BrokerJob[]>(url, this.httpService.getHttpOptions()).pipe(
        tap(res => {
          res.forEach(element => {
            element.job.jobAddressString = this.globalService.getJobString(element.job, false, false);
          });
          this.brokerJobs = res;
        }),
        catchError(this.globalService.handleError));
    } else {
      // office user or developer
      let url = this.brokerJobURL + '?showSold=' + showSold;

      if (brokerId) {
        url += '&brokerId=' + brokerId;
      }

      return this.http.get<BrokerJob[]>(url, this.httpService.getHttpOptions()).pipe(
        tap(res => {
          res.forEach(element => {
            element.job.jobAddressString = this.globalService.getJobString(element.job, false, false);
          });
          this.brokerJobs = res;
        }),
        catchError(this.globalService.handleError));
    }
  }


  getBrokerJobsForDeveloper(showSold: boolean) {
    const url = this.brokerJobURL + '/developer/' + this.authService.getCurrentUser().developerId + '/jobs?showSold=' + showSold;
    return this.http.get<BrokerJob[]>(url, this.httpService.getHttpOptions()).pipe(
      tap(res => {
        res.forEach(element => {
          element.job.jobAddressString = this.globalService.getJobString(element.job, false, false);
        });

        this.brokerJobs = res;
      }),
      catchError(this.globalService.handleError));
  }


  getBrokerJobsForJobId(jobId: number) {
    return this.http.get<BrokerJob[]>(this.brokerJobURL + '/' + jobId + '/brokers', this.httpService.getHttpOptions());
  }

  addBrokerJob(dataRecord: any): Observable<BrokerJob> {
    return this.http.post<BrokerJob>(this.brokerJobURL, JSON.stringify(dataRecord, this.globalService.jsonReplacer), this.httpService.getHttpOptions());
  }

  addBrokersForJob(dataRecord: any): Observable<BrokerJob> {
    return this.http.post<BrokerJob>(this.brokerJobURL + '/brokers', JSON.stringify(dataRecord, this.globalService.jsonReplacer), this.httpService.getHttpOptions());
  }

  updateBrokerJob(id: string, itm: any): Observable<BrokerJob> {
    const url = this.brokerJobURL + "/" + id;
    return this.http.patch<BrokerJob>(url, JSON.stringify(itm, this.globalService.jsonReplacer), this.httpService.getHttpOptions());
  }

  deleteBrokerJob(id: string) {
    const url = this.brokerJobURL + '/' + id;
    return this.http.delete(url, this.httpService.getHttpOptions());
  }


  // logo's
  getBrokerLogo(id: number): Observable<Logo> {
    const url = this.brokerURL + '/' + id + '/logo';
    return this.http.get<Logo>(url, this.httpService.getHttpOptions());
  }

  addLogo(id: number, image: any) {
    const url = this.brokerURL + '/' + id + '/logo';
    return this.http.post<Logo>(url, image, this.httpService.getHttpFileOptions());
  }

  // holds
  requestHold(jobId: number) {
    const url = this.onHoldJobURL + '/request';
    return this.http.post(url, JSON.stringify({ jobId: jobId, brokerId: this.authService.getCurrentUser().brokerId }), this.httpService.getHttpOptions());
  }

  approveHold(id: number, holdExpiryDate: Date, selectedBrokerUserId: number, emailNote: string, sendEmail: boolean) {
    const url = this.onHoldJobURL + '/' + id;
    return this.http.patch(url, JSON.stringify({
      onHoldStatusTypeId: OnHoldStatusTypeEnum.Approved,
      expiryDate: holdExpiryDate,
      onHoldUserId: selectedBrokerUserId,
      emailNote: emailNote,
      sendEmail: sendEmail
    }, this.globalService.jsonReplacer), this.httpService.getHttpOptions());
  }

  addHoldApproved(jobId: number, holdExpiryDate: Date, brokerId: number, selectedBrokerUserId: number, emailNote: string, sendEmail: boolean) {
    const url = this.onHoldJobURL;
    return this.http.post(url, JSON.stringify({
      jobId: jobId,
      brokerId: brokerId,
      expiryDate: holdExpiryDate,
      onHoldUserId: selectedBrokerUserId,
      onHoldStatusTypeId: OnHoldStatusTypeEnum.Approved,
      emailNote: emailNote,
      sendEmail: sendEmail
    }), this.httpService.getHttpOptions());
  }

  declineHold(id: number, reason: string, sendEmail: boolean) {
    const url = this.onHoldJobURL + '/' + id;
    return this.http.patch(url,
      JSON.stringify({
        onHoldStatusTypeId: OnHoldStatusTypeEnum.Available,
        declineReason: reason,
        sendEmail: sendEmail
      }),
      this.httpService.getHttpOptions());
  }

  // get quote PDF
  getBrokerJobPDF(brokerJobId: number, contractName: string, quoteDate: Date): Observable<IJobItemAttachment> {
    const url = this.brokerJobURL + '/' + brokerJobId + '/sales-pdf';
    return this.http.patch<IJobItemAttachment>(url, JSON.stringify({ contractName: contractName, quoteDate: quoteDate }), this.httpService.getHttpOptions());
  }

  getProgressTasks(brokerId: number): Observable<JobTask[]> {
    return this.http.get<JobTask[]>(this.globalService.getApiUrl() + '/broker-jobs/' + brokerId + '/job-progress',
      this.httpService.getHttpOptions()).pipe(
        catchError(this.globalService.handleError));
  }

  getTaskMasters(useCache: boolean): Observable<TaskMaster[]> {
    if (useCache && this.taskMastersCompany === this.globalService.getCurrentCompany().id
      && this.taskMasters && this.taskMasters.length) {
      return of(this.taskMasters);
    } else {
      const url = this.globalService.getApiUrl() + '/task-masters/';

      return this.http.get<TaskMaster[]>(url, this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.taskMasters = res;
          this.taskMastersCompany = this.globalService.getCurrentCompany().id;
        }),
        catchError(this.globalService.handleError));
    }
  }

  getCompanyActivities(useCache: boolean) {
    if (useCache && this.activitiesCompany === this.globalService.getCurrentCompany().id
      && this.activities && this.activities.length) {
      return of(this.activities);
    } else {
      const url = this.globalService.getApiUrl() + '/activities?activeOnly=' + false;
      return this.http.get<CompanyActivity[]>(url, this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.activities = res;
          this.activitiesCompany = this.globalService.getCurrentCompany().id;
        }),
        catchError(this.globalService.handleError));
    }
  }

  getProgressReport(brokerId: number, jobIds: number[]): Observable<JobTask[]> {
    return forkJoin(
      [
        this.getProgressTasks(brokerId),
        this.getTaskMasters(true),
        this.getCompanyActivities(true),
        this.recalcForecasts(false, jobIds),
        this.getNextClaims(brokerId),
      ]
    )
      .pipe(map(
        ([dataRecords]) => {
          return dataRecords;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }


  getNextClaims(brokerId: number) {
    return this.http.get<ClaimJobLine[]>(this.globalService.getApiUrl() + '/broker-jobs/next-claims?brokerId=' + brokerId, this.httpService.getHttpOptions()).pipe(
      tap(res => {
        this.nextClaims = res;
      }),
      catchError(this.globalService.handleError));
  }


  recalcForecasts(useCache: boolean, jobIds: number[]): Observable<JobTaskForecast[]> {
    // if jobids is empty, return empty array
    if (!jobIds || jobIds.length === 0) {
      return of([]);
    }

    if (useCache && this.forecastTasks && this.forecastTasksCompany === this.globalService.getCurrentCompany().id) {
      return of(this.forecastTasks);
    }
    const url = this.globalService.getApiUrl() + '/job-task-forecasts/recalc-completion/packages';

    return this.http.patch<JobTaskForecast[]>(url, JSON.stringify({ jobIds: jobIds }),
      this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.forecastTasks = res;
          this.forecastTasksCompany = this.globalService.getCurrentCompany().id;
        }),
        catchError(this.globalService.handleError));
  }
}
