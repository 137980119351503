import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalService } from '../../services/global.service';
import { NotificationService } from '../../services/notification.service';
import { Subscription } from 'rxjs';
import { JobService } from '../../services/felixApi/job.service';
import { BrokerService } from '../../services/felixApi/broker.service';
import { BrokerJob } from '../../dtos/broker-job';
import DataSource from 'devextreme/data/data_source';
import { Broker } from '../../dtos/broker';
import { GridService } from '../../services/grid.service';

@Component({
  selector: 'js-allocate-package',
  templateUrl: './allocate-package.component.html',
  styleUrls: ['./allocate-package.component.scss']
})
export class AllocatePackageComponent implements OnInit, OnDestroy {

  subscriptions: Subscription[] = [];
  jobId: number;
  brokerJobs: BrokerJob[];
  gridHeight: number;
  dataSource: DataSource;
  selectedRecords: number[] = [];
  brokers: Broker[];
  showLoadingSpinner = false;
  jobsForBroker: BrokerJob[];

  constructor(
    private _activeModal: NgbActiveModal,
    protected gridService: GridService,
    private jobService: JobService,
    private brokerService: BrokerService,
    private notiService: NotificationService,
    private globalService: GlobalService) { }


  ngOnInit(): void {
    this.setHeightWidths();
    this.subscriptions = this.subscriptions.concat(
      this.globalService.innerHeightWidthChanged.subscribe(
        () => {
          this.setHeightWidths();
        }
      ));

    this.brokers = this.brokerService.brokers;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  setHeightWidths() {
    this.gridHeight = window.innerHeight - 300;
  }


  cancel() {
    this._activeModal.dismiss();
  }

  onJobNumberChanged(jobNo: string) {
    this.jobId = this.jobService.currentJob?.id;

    if (this.jobId) {
      this.getJobsForBroker();
    }
  }

  getJobsForBroker() {
    this.showLoadingSpinner = true;

    // clear the current list
    // this.brokers.forEach(broker => {
    //   broker.isSelectedForJob = false;
    // });
    this.selectedRecords = [];
    this.subscriptions = this.subscriptions.concat(
      this.brokerService.getBrokerJobsForJobId(this.jobId)
        .subscribe({
          next: (jobsForBroker) => {
            jobsForBroker?.forEach(jobForBroker => {
              const broker = this.brokers.find(i => i.id === jobForBroker.brokerId);
              if (broker) {
                // broker.isSelectedForJob = true;
                this.selectedRecords.push(broker.id);
              }
            });
            this.showLoadingSpinner = false;
            this.setUpDataSource();
          },
          error: (err) => {
            this.showLoadingSpinner = false;
            this.notiService.notify(err);
            this.setUpDataSource(); // to clear
          }
        })
    );
  }

  setUpDataSource() {
    this.dataSource = new DataSource({
      key: 'id',
      load: () => this.brokers
    });
  }

  onToolbarPreparing(e) {
    const toolbarItems = e.toolbarOptions.items;

    toolbarItems.unshift(
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          type: 'default',
          text: 'Update',
          onClick: this.upsertBrokerJobs.bind(this)
        }
      });
  }

  upsertBrokerJobs() {
    this.showLoadingSpinner = true;
    this.subscriptions = this.subscriptions.concat(
      this.brokerService.addBrokersForJob({ jobId: this.jobId, brokerIds: this.selectedRecords })
        .subscribe({
          next: () => {
            this._activeModal.close();
          },
          error: (err) => {
            this.showLoadingSpinner = false;
            this.notiService.notify(err);
          }
        })
    );
  }
}
