<div class="modal-header">
  <h4 class="modal-title">{{titleDescription}} - Attachments</h4>
  <button type="button" class="close" aria-label="Close" (click)="cancel()" tabindex="-1">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <dx-data-grid [height]="gridHeight" [dataSource]="dataSource" [allowColumnReordering]="false"
    [allowColumnResizing]="true" [repaintChangesOnly]="true" [showBorders]="true" [rowAlternationEnabled]="true"
    [wordWrapEnabled]="true" [hoverStateEnabled]="true"
    (onExporting)="gridService.onExporting($event, 'Estate-Attachments')">

    <dxo-search-panel [visible]="true" location="after" [width]="200"></dxo-search-panel>
    <dxo-export [enabled]="true"></dxo-export>
    <dxo-scrolling mode="standard"></dxo-scrolling>
    <dxo-paging [enabled]="false"></dxo-paging>

    <dxo-editing mode="row" [allowUpdating]="true" [allowAdding]="true" [allowDeleting]="true" [useIcons]="true"
      startEditAction="click" refreshMode="full">
    </dxo-editing>

    <dxo-row-dragging [allowReordering]="true" [onReorder]="onReorder" dropFeedbackMode="push"></dxo-row-dragging>

    <dxi-column dataField="id" dataType="number" [visible]='false'></dxi-column>

    <dxi-column dataField="description"></dxi-column>

    <dxi-column dataField="attachAtTheStart" dataType="boolean" [width]="110" [hidingPriority]="3"></dxi-column>

    <dxi-column type="buttons" [width]="140" [fixed]="true">
      <dxi-button name="edit"></dxi-button>
      <dxi-button name="delete"></dxi-button>
      <dxi-button icon="upload" [onClick]="uploadAttachment"></dxi-button>
      <dxi-button icon="download" [onClick]="downloadAttachment"></dxi-button>
    </dxi-column>
  </dx-data-grid>
</div>


<!-- attachment upload -->
<dx-popup class="popup" [width]="500" [height]="265" [showTitle]="true" [title]="estateAttachmentDescription"
  [dragEnabled]="false" [hideOnOutsideClick]="true" [(visible)]="uploadFilePopupVisible">
  <div *dxTemplate="let data of 'content'">

    <div *ngIf="!loading">
      <dx-file-uploader [ngClass]="wrongFileType ? 'red-faint-back' : 'b-blue-faint-back'" [value]="filesToUpload"
        uploadMode="useForm" selectButtonText="Select file" labelText="or drop file here"
        (onValueChanged)="handleFileInput($event)">
      </dx-file-uploader>

      <div *ngIf="wrongFileType" class="red">Please select {{acceptedTypes}}</div>

      <dx-button *ngIf="filesToUpload.length && !wrongFileType" text="Upload" type="default"
        (onClick)="uploadFile()"></dx-button>
    </div>
  </div>

  <js-loading-spinner *ngIf="loading" [usePopUp]="false"></js-loading-spinner>
</dx-popup>
