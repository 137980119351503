<div class="page">
  <!-- select job -->
  <js-job-number (refreshJobNumber)='onJobNumberChanged($event)'></js-job-number>

  <js-loading-spinner *ngIf="loadingDocs" [usePopUp]="false"></js-loading-spinner>

  <dx-tree-list id="jobDocumentsTree" *ngIf="!loadingDocs" [dataSource]="jobDocuments" keyExpr="keyId"
    parentIdExpr="parentKeyId" [showRowLines]="true" [showBorders]="true" [columnAutoWidth]="true" [height]="gridHeight"
    [width]="gridWidth">

    <dxo-scrolling mode="standard"></dxo-scrolling>
    <dxo-search-panel [visible]="true" [width]="200"></dxo-search-panel>

    <dxi-column dataField="description" caption="Attachments" [allowSorting]="false"
      cellTemplate="attachmentTemplate"></dxi-column>

    <dxi-column dataField="orderNo" [visible]="false" sortOrder="asc"></dxi-column>

    <dxi-column dataField="fileModifiedDate" caption="Modified" dataType="date" [width]="90" alignment="center"
      cellTemplate="dateTemplate"></dxi-column>

    <dxi-column type="buttons" [hidingPriority]="2" [width]="40">
      <dxi-button [visible]="isDownloadVisible" icon="download" [onClick]="download"></dxi-button>
    </dxi-column>

    <div *dxTemplate="let options of 'attachmentTemplate'">
      <div>{{ options.data.description }}</div>
      <js-show-image
        *ngIf="(options.data.attachmentTypeId === attachmentTypeEnum.JPEG || options.data.attachmentTypeId === attachmentTypeEnum.PNG || options.data.attachmentTypeId === attachmentTypeEnum.HEIC)"
        [imageId]="options.data.id" [attachmentTypeId]="options.data.attachmentTypeId"
        [isSharePoint]="options.data.isSharePoint" [sharePointId]="options.data.sharePointId"
        (emitDate)="updateFileDate(options.data.id, $event)"> </js-show-image>
    </div>

    <div *dxTemplate="let cell of 'dateTemplate'">
      <div *ngIf="cell && cell.data?.attachmentTypeId && cell.value">
        {{cell.value | date : "d-MMM-yy"}}
      </div>
    </div>
  </dx-tree-list>
</div>
