<div class="modal-header">
  <h3 class="modal-title">Approve Hold</h3>
  <button type="button" class="close" aria-label="Close" (click)="cancel()" tabindex="-1">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div>Job: {{brokerJob.job.jobNumber}} - {{brokerJob.job.jobAddressString}} {{brokerJob.job.jobAddress.suburbTown}}
  </div>
  <div>Broker: {{brokerJob.broker.name}}</div>
  <br>

  <div class="dx-field">
    <div class="dx-field-label">Broker User:</div>
    <div class="dx-field-value">
      <dx-select-box [dataSource]="brokerUsers" displayExpr="fullName" valueExpr="id"
        placeholder="Select Broker User..." [(value)]='selectedBrokerUserId'>
      </dx-select-box>
    </div>
  </div>

  <div class="dx-field">
    <div class="dx-field-label">Expiry Date:</div>
    <div class="dx-field-value">
      <dx-date-box [(ngModel)]="expiryDate"></dx-date-box>
    </div>
  </div>

  <div class="dx-field">
    <div class="dx-field-label">Email Note:</div>
    <div class="dx-field-value">
      <dx-text-area [(ngModel)]="emailNote"></dx-text-area>
    </div>
  </div>

  <div class="dx-field">
    <div class="dx-field-label">Send Email:</div>
    <dx-check-box [(ngModel)]="sendEmail"></dx-check-box>
  </div>
</div>

<div class="modal-footer">
  <dx-button text="Approve" type="default" (onClick)="approveHold()"
    [disabled]="!expiryDate || !selectedBrokerUserId"></dx-button>
</div>

<!-- loading spinner - DevExtreme -->
<dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [(visible)]="showLoadingSpinner" [showIndicator]="true"
  [showPane]="true" [shading]="true" [hideOnOutsideClick]="false">
</dx-load-panel>
