import { Injectable } from '@angular/core';
import { AttachmentTypeEnum } from '../dtos/attachment-type.enum';
import { formatDate } from 'devextreme/localization';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor(
  ) { }

  moveArrayItemToNewIndex(arr, old_index, new_index) {
    if (new_index >= arr.length) {
      let k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
  }

  getErrorString(err): string {
    if (err && err.error && err.error.message) {
      return JSON.stringify(err.error.message);
    } else {
      return JSON.stringify(err);
    }
  }

  editCache(object, cache: any[]) {
    const match = cache.filter(t => t.id === object.id)[0];
    if (match) {
      const index = cache.indexOf(match);
      cache[index] = object;
    } else {
      console.log('couldnt edit cache!');
    }
  }

  // assumes INPUT id is string and object ids are numbers
  deleteFromCache(id: string, cache: any[]) {
    const match = cache.filter(t => t.id.toString() === id)[0];
    if (match) {
      const index = cache.indexOf(match);
      cache.splice(index, 1);
    } else {
      console.log('couldnt delete from cache!');
    }
  }

  getFileType(imgName: string): number {
    const nameExtn = imgName.toUpperCase().split('.').pop();

    return AttachmentTypeEnum[nameExtn];
  }

  fileToFormData(file) {
    const fd = new FormData();
    fd.append(file.name ? file.name : 'TruthEngine', file, file.name ? file.name : 'TruthEngine.png');
    return fd;
  }

  convertDateToString(date: any): string {
    if (!date) {
      return '';
    }

    if (date instanceof Date) {
      let dateString = formatDate(date, 'yyyy-MM-dd');
      if (dateString.substr(0, 4) === '0000') {
        const dateNowString = formatDate(new Date(), 'yyyy-MM-dd');
        dateString = dateNowString.substr(0, 4) + dateString.substr(4, 10);
      } else if (dateString.substr(0, 2) === '00') {
        const dateNowString = formatDate(new Date(), 'yyyy-MM-dd');
        dateString = dateNowString.substr(0, 2) + dateString.substr(2, 10);
      }

      return dateString;
    } else {
      return date;
    }
  }
}
