import { Injectable } from '@angular/core';
import { Suburb } from '../../dtos/suburb';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../global.service';
import { HttpService } from '../http.service';
import { AuthService } from '../auth.service';
import { environment } from '../../../environments/environment';
import { Job } from '../../dtos/job';

@Injectable({
  providedIn: 'root'
})
export class SuburbService {

  suburbURL = environment.apiUrl + '/suburbs';
  suburbs: Suburb[] = [];
  suburbsCompany: string;

  constructor(
    private http: HttpClient,
    private httpService: HttpService,
    private authService: AuthService,
    private globalService: GlobalService) { }

  getSuburbs(useCache: boolean) {
    if (useCache && this.suburbs && this.suburbs.length && this.suburbsCompany === this.globalService.getCurrentCompanyId()) {
      return of(this.suburbs);
    } else {
      return this.http.get<Suburb[]>(this.suburbURL, this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.suburbs = res;
          this.suburbsCompany = this.globalService.getCurrentCompanyId();
        }),
        catchError(this.globalService.handleError));
    }
  }

  addSuburb(dataRecord: any): Observable<Suburb> {
    return this.http.post<Suburb>(this.suburbURL, JSON.stringify(dataRecord, this.globalService.jsonReplacer), this.httpService.getHttpOptions());
  }

  updateSuburb(id: string, itm: any): Observable<Suburb> {
    const url = this.suburbURL + '/' + id;
    return this.http.patch<Suburb>(url, JSON.stringify(itm, this.globalService.jsonReplacer), this.httpService.getHttpOptions());
  }

  deleteSuburb(id: string) {
    const url = this.suburbURL + '/' + id;
    return this.http.delete(url, this.httpService.getHttpOptions());
  }


  // does the suburb have a locatation
  suburbHasLocation(job: Job): boolean {
    // find suburb location
    if (!this.suburbs || !this.suburbs.length || !job.jobAddress) {
      return false;
    }

    const suburb = this.suburbs
      .find(i => i.suburbTown.toLowerCase().trim() === job.jobAddress.suburbTown?.toLowerCase().trim()
        && i.state?.toLowerCase().trim() === job.jobAddress.state?.toLowerCase().trim()
        && i.postCode?.toLowerCase().trim() === job.jobAddress.postCode?.toLowerCase().trim());

    if (suburb) {
      return true;
    }

    return false;
  }

}
