import { OnHoldLogService } from './../../services/felixApi/on-hold-log.service';
import { NotificationService } from './../../services/notification.service';
import { GlobalService } from '../../services/global.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import { Subscription } from 'rxjs';
import { BrokerService } from '../../services/felixApi/broker.service';
import { Broker } from '../../dtos/broker';
import { GridService } from '../../services/grid.service';

@Component({
  selector: 'js-on-hold-log',
  templateUrl: './on-hold-log.component.html',
  styleUrls: ['./on-hold-log.component.scss']
})
export class OnHoldLogComponent implements OnInit, OnDestroy {

  subscriptions: Subscription[] = [];
  loadingData = true;
  gridHeight: number;
  brokers: Broker[];
  dataSource: DataSource;
  onHoldStatusTypes: { id: number; description: string; }[];

  constructor(
    private brokerService: BrokerService,
    private globalService: GlobalService,
    private notiService: NotificationService,
    protected gridService: GridService,
    private onHoldLogService: OnHoldLogService) {
  }

  ngOnInit() {
    this.gridHeight = window.innerHeight - 120;
    this.onHoldStatusTypes = [
      { id: 1, description: 'Available' },
      { id: 2, description: 'OnHold' },
      { id: 3, description: 'Approved' },
      { id: 4, description: 'WaitingApproval' }
    ];
    this.getbrokers();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  getbrokers() {
    this.subscriptions = this.subscriptions.concat(
      this.brokerService.getBrokers(true)
        .subscribe({
          next: (brokers) => {
            this.brokers = brokers;
            this.loadingData = false;
            this.setUpDataSource();
          },
          error: (error) => {
            this.notiService.showError(error);
            this.loadingData = false;
          }
        })
    );
  }

  setUpDataSource() {
    this.dataSource = new DataSource({
      key: 'id',
      load: async () => {
        return new Promise((resolve, reject) =>
          this.onHoldLogService.getOnHoldLogs().subscribe({
            next: (res) => {
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      }
    });
  }
}
