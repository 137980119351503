export class UserBrokerPermission {
  public id: number;
  public userId: number;
  public brokerId: number;
  public brokerPermissionId: number;
  public noneReadWrite: number;
  public modifiedDate: Date;
  public modifiedUserId: number;
}

export enum UserBrokerPermissionEnum {
  MapView = 1,
  Packages = 2,
  Progress = 3,
  Documents = 4
}
