import { Injectable } from '@angular/core';
import { PackageControl } from '../../dtos/package-control';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../global.service';
import { HttpService } from '../http.service';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PackageControlService {

  packageControlURL = environment.apiUrl + '/package-controls';

  constructor(
    private http: HttpClient,
    private httpService: HttpService,
    private globalService: GlobalService) { }

  getPackageControl() {
      return this.http.get<PackageControl>(this.packageControlURL, this.httpService.getHttpOptions()).pipe(
        catchError(this.globalService.handleError));
    }

  addPackageControl(dataRecord: any): Observable<PackageControl> {
    return this.http.post<PackageControl>(this.packageControlURL, JSON.stringify(dataRecord, this.globalService.jsonReplacer), this.httpService.getHttpOptions());
  }

  updatePackageControl(itm: any): Observable<PackageControl> {
    return this.http.patch<PackageControl>(this.packageControlURL, JSON.stringify(itm, this.globalService.jsonReplacer), this.httpService.getHttpOptions());
  }

}
